import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    TextField,
    Button,
    Grid,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    DialogContent,
    Tooltip,
    IconButton,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MaterialTable from "material-table";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { BootstrapInput, GetAddButton } from "../../Utilities/Utility";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../../Components/Loader/Loader";
import { blue } from "@material-ui/core/colors";
import Customer_Template from "../../Common/CustomerMaster Template.xlsx";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../Themes/LightTheme";
import { useSelector, useDispatch } from "react-redux";
import "../CreateQuotation/Create.scss";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { CustomerDataUpload, GetAllCustomerList, getUpdateDetails, getSaveDetails } from "../../APIs/api_Customer";
import { deleteCustomerDetails } from "../../APIs/api_Insert"
import { DeleteOutlined } from "@material-ui/icons";
//import { CheckIcon } from "@material-ui/icons";
//import CheckIcon from "@mui/icons-material/Check";
//import CheckIcon from "icons-material/Check";

import CheckIcon from '@material-ui/icons/Check';
import * as XLSX from "xlsx";

export default function Customer() {
    const [fileValue, setfileValue] = useState("");
    let [errorData, setErrorData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const token = useSelector((state) => state.saveTokenReducer.token);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);

    const UID = useSelector((state) => state.saveUserIdReducer);
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const [data, setData] = useState([]);
    const [uploadRes, setUploadRes] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState("");
    const [isLoader, setIsLoader] = useState(false);

    //for snackBar
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [formDialogTitle, setFormDialogTitle] = useState("Save");
    const [custDialogTitle, setCustDialogTitle] = useState("Add Customer Testimonial");
    //End - snackbar

    const api = axios.create({
        baseURL: "api/Customer",
    });

    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    useEffect(() => {
        GridData();
    }, []);

    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const updateExcelHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleDialogMessage = (text) => {
        setDialogContent(text);
        setDialogOpen(true);
    };
    const handleClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        if (
            typeof dialogContent === "string" &&
            dialogContent.toLowerCase().includes("success")
        ) {
            setInputFileValue(Date.now);
            setSelectedFile(null);
        }
        setDialogOpen(false);
        setInputFileValue(Date.now);
        setErrorData([]);
        setOpen(false);
    };
    const exportErrorData = () => {
        ExportCustomerErrorDataToExcel();
    };
    function ExportCustomerErrorDataToExcel() {
        const dataToDownload = errorData.map((row) => {
            return {

                epCustomerId: row.epCustomerId,
                customerName1: row.customerName1,
                customerCode: row.customerCode,



                remarks: row.remarks?.replace(".", ".\n "),

            };
        });
        let headers = ["sl_no", "customerName", "customerCode",
            "Remarks",

        ];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
            origin: "A2",
            skipHeader: true,
            cellStyles: true,
        });
        ws["!cols"] = cellAdjustToContentsCustomer(dataToDownload);
        ws['!cols'] = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 150 }];
        XLSX.utils.book_append_sheet(wb, ws, "ErrorData");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(wb, "ExcelUploadErrors.xlsx");
    }
    function cellAdjustToContentsCustomer(data) {
        return [
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.epCustomerId ? c.epCustomerId?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.customerName1 ? c.customerName1?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.customerCode ? c.customerCode?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.remarks ? c.remarks?.toString()?.length : 0))
                ),
            },
        ];
    }



    //const columns = [
    //    { title: "Name", field: "customerName" },
    //    {
    //        title: "Code", field: "customerCode"
    //    },
    //    { title: "Location", field: "customerLocation" },
    //    { title: "Address", field: "addess" },
    //    { title: "City", field: "city" },
    //    { title: "Pincode", field: "pincode" },
    //    { title: "Contact Person", field: "contactPerson" },
    //    { title: "Mobile", field: "mobile" },
    //    { title: "EmailId", field: "emailId" },
    //];

    function ApproveDocumentButton(rowData) {

        function handleDeleteDetails(e) {
            console.log("data");
        }
        //function viewToolCaseData() {
        //    let id = rowData.requestId;
        //    dispatch(saveReqNoAction(rowData.requestNumber));
        //    let idEncrypted = btoaSecure(id);
        //    window.location.href =
        //        baseAddressURL + "/CompleteDetails?id=" + idEncrypted;
        //}

        return (
            <div>
               

                <IconButton
                    className="activationButton"
                    aria-label="Approve"
                    //onClick={handleClickOpenFormApprove}
                    style={{ left: `-12px` }}>
                    <Tooltip title="Approve">
                        <CheckIcon style={{ color: `green` }} />
                    </Tooltip>
                </IconButton>

            </div>)
    }

    const columns = [



        //{
        //    title: "action.",
        //    field: "customerCode",
        //    render: (rowData) => rowData && ApproveDocumentButton(rowData),
        //    export: true,
        //},
        { title: "Customer Code", field: "customerCode" },
        { title: "Customer Name", field: "customer" },



        { title: "Street", field: "street" },
        { title: "City", field: "city" },
        { title: "Postal Code", field: "pincode" },

        { title: "Country", field: "country" },
        {
            title: "Tax Code 3", field: "taxCode3"
        },
        { title: "Contact Person", field: "contactPerson" },
        { title: "Mobile", field: "mobile" },
        { title: "EmailId", field: "emailId" },

    ];

    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const handleAlertClose = () => {
        setSelectedFile(null);
        setOpenAlertDialog(false);
        setIsLoading(false);
    };
    const [alertMessage, setAlertMessage] = useState("");
    const handleAlertOpen = (message) => {
        setOpenAlertDialog(true);
        setAlertMessage(message);
    };
    const handleErrorClose = () => {
        setOpenErrorDialog(false);
    };
    const [cust, setCust] = useState("");
    const handleChangeCust = (e) => {
        setCust(e.target.value);
    }
    const [name1, setName1] = useState("");
    const handleChangeName1 = (e) => {
        setName1(e.target.value);
    }
    const [name2, setName2] = useState("");
    const handleChangeName2 = (e) => {
        setName2(e.target.value);
    }
    const [street, setStreet] = useState("");
    const handleChangeStreet = (e) => {
        setStreet(e.target.value);
    }
    const [city, setCity] = useState("");
    const handleChangeCity = (e) => {
        setCity(e.target.value);
    }
    const [country, setCountry] = useState("");
    const handleChangeCountry = (e) => {
        setCountry(e.target.value);
    }
    const [code, setCode] = useState("");
    const handleChangeCode = (e) => {
        setCode(e.target.value);
    }
    const [postal, setPostal] = useState("");
    const handleChangePostal = (e) => {
        setPostal(e.target.value);
    }
    const [person, setPerson] = useState("");
    const handleChangePerson = (e) => {
        setPerson(e.target.value);
    }
    const [mobile, setMobile] = useState(null);
    const handleChangeMobile = (e) => {
        setMobile(e.target.value);
    }
    const [email, setEmail] = useState("");
    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    }
    const [openDia, setOpenDia] = useState(false);
    const handleCloseDia = () => {
        setOpenDia(false);
        clearVal()
    }
    const [custId, setCustId] = useState(null);
    function submitValueDetails() {
        setIsLoader(true);
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("UserId", userDetails.userId);

        CustomerDataUpload(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                console.log(response);
                if (response.response) {
                    setIsLoader(false);
                    GridData();
                    handleDialogMessage(response.responseMsg);

                } else {
                    setIsLoader(false);
                    setSelectedFile(null);
                    setInputFileValue(Date.now);
                    setErrorData(response.objResult);
                    handleDialogMessage(
                        response.responseMsgs && response.responseMsgs.length > 0
                            ? response.responseMsgs
                            : response.responseMsg
                    );
                }
            })
            .catch((error) => {
                setIsLoader(false);
                setSelectedFile(null);
                setInputFileValue(Date.now);
                handleDialogMessage("Exception in upload");
            });
    }
    const[flag,setFlag]=useState(false)
    const dialogDraftValuesFn = (rowData) => {
        console.log("Row update Data", rowData);
        if (rowData.customerCode === "" || rowData.customerCode === null)
        { setFlag(false) }
        else { setFlag(true) }
        //setFlag(true)
        setCustId(rowData.epCustomerId);
        setCust(rowData.customerCode);
        setName1(rowData.customer);
        //setName2(rowData.customerName2);
        setStreet(rowData.street);
        setCity(rowData.city);
        setPostal(rowData.pincode);
        setCountry(rowData.country);
        setCode(rowData.taxCode3);
        setPerson(rowData.contactPerson);
        setEmail(rowData.emailId);
        setMobile(rowData.mobile);
    }

    function GetDeleteButton(e, rowData) {
        return (

            <Tooltip title="Delete" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <DeleteOutlined fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }

    function clearVal() {
        setCustId(null)
        setCust("")
        setName1("")
        setStreet("")
        setCity("")
        setPostal("")
        setCountry("")
        setCode("")
        setPerson("")
        setEmail("")
        setMobile(null)
        setFlag(false)
    }

    //const deleteWithoutCustCode = (e, rowData) => {
    //    console.log("rowData", rowData);
    //}
    function submit() {
        

        if (name1 === "" || name1 === null || name1 === undefined) {
            handleSnackOpen("customername is Empty", "error");
            return;
        }
        if (city === "" || city === null || city === undefined) {
            handleSnackOpen("city is Empty", "error");
            return;
        }

        if (country === "" || country === null || country === undefined) {
            handleSnackOpen("country is Empty", "error");
            return;
        }

        setIsLoader(true);
        let slipData = {
            EpCustomerId: custId,
            customerCode: cust,
            CustomerName1: name1,
            // CustomerName2: name2,
            Street: street,
            City: city,
            Country: country,
            Pincode: postal,
            TaxCode3: code,
            ContactPerson: person,
            EmailId: email,
            Mobile: mobile,
            isActive: true,


        }
        console.log("slipData", slipData);
        //let errorList = [];
        //await api.post("/PostQuotationData/", slipData) 
        getUpdateDetails(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, slipData)
            .then(response => {
                if (response.response) {

                    handleSnackOpen(response.responseMsg, "success");
                    GridData();
                    //clearVal()
                    handleCloseDia();

                    setIsLoader(false);
                }
                else {
                    setIsLoader(false);
                    handleSnackOpen(response.responseMsg, "error");
                    handleCloseDia();
                }
            })
            .catch(error => {
                handleSnackOpen("Error in saving.", "error");
                setIsLoader(false);
            })

    }
    function submitSave() {
       

        if (name1 === "" || name1 === null || name1 === undefined) {
            handleSnackOpen("customername is Empty", "error");
            return;
        }
        if (city === "" || city === null || city === undefined) {
            handleSnackOpen("city is Empty", "error");
            return;
        }

        if (country === "" || country === null || country === undefined) {
            handleSnackOpen("country is Empty", "error");
            return;
        }
        setIsLoader(true);
        let slipData = {
            // EpCustomerId: custId,
            customerCode: cust,
            CustomerName1: name1,
            // CustomerName2: name2,
            Street: street,
            City: city,
            Country: country,
            Pincode: postal,
            TaxCode3: code,
            ContactPerson: person,
            EmailId: email,
            Mobile: mobile,
            isActive: true,


        }
        console.log("slipData", slipData);
        //let errorList = [];
        //await api.post("/PostQuotationData/", slipData) 
        getSaveDetails(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, slipData)
            .then(response => {
                if (response.response) {

                    handleSnackOpen(response.responseMsg, "success");
                    GridData();
                    //clearVal()
                    handleCloseDia();

                    setIsLoader(false);
                }
                else {
                    setIsLoader(false);
                    handleSnackOpen(response.responseMsg, "error");
                    handleCloseDia();
                }
            })
            .catch(error => {
                handleSnackOpen("Error in saving.", "error");
                setIsLoader(false);
            })

    }

    const [custCode, setCustCode] = useState([]);
    const [custcId, setCustcId] = useState(null);
    function GridData() {
        setIsLoading(true);

        GetAllCustomerList(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                setIsLoading(false);

                console.log("hicustomer", response);
               // let custepId = response.map((x) => x.epCustomerId);
                let custCodeCheck = response.filter((x) => (x.customerCode === ""));
                setCustCode(custCodeCheck);
                console.log("custCodeCheck", custCodeCheck);
                console.log(response);
                setData(response);
            })
            .catch((error) => {
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
                setIsLoading(false);
            });
    }

    //deleteWithoutCustCode
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [custIdRow, setCustIdRow] = useState(null);
    const [custCodeD, setCustCodeD] = useState("");
    //console.log("custIdRow", custIdRow);
    //deleteWithoutCustCode

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    }
   
    
    const deletePoEntryRow = (rowData) => {
        console.log("rowData", rowData);
        setCustIdRow(rowData);
        setCustCodeD(rowData.customerCode);
        setDeleteDialogOpen(true);
       // setCustIdRow(rowData.epCustomerId);
    }

    console.log("custCodeD", custCodeD);

    function deleteWithoutCustCode() {
        console.log("custIdRow", custIdRow);
        let formData = {
            EpCustomerId: custIdRow.epCustomerId,//rowData.epCustomerId==>rowData.epCustomerId
           
            LoggedInUserId: userDetails.userId,
          

    }
 



        
        deleteCustomerDetails(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                console.log("deleteapihitted",response);
                handleSnackOpen(response.responseMsg, "success");
                setDeleteDialogOpen(false);
                window.location.reload();
              
            })
            .catch((error) => {
                handleSnackOpen(error, "error");
            });
    }
    function GetEditButton() {
        return (
            <Tooltip title="Edit" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    return (
        <>
            <div>
                <Grid container spacing={2}>
                    {userDetails.roleId == 1 ? (
                        <Grid
                            container
                            spacing={2}
                            item
                            xs={12}
                            className="d-flex jc-space-bt"
                        >
                            <Grid item xs={12} className="text-left ml-1">
                                {/*<h2>Customer Master</h2>*/}
                            </Grid>
                            <Grid container spacing={2} xs={12}>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        id="EPQUploadFile"
                                        variant="filled"
                                        className="bulk-upload"
                                        type="file"
                                        name="bulkUpload"
                                        key={inputFileValue}
                                        onChange={updateExcelHandler}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button
                                        className="pt-button--primary bulk-upload-submit-btn"
                                        type="submit"
                                        disabled={selectedFile ? false : true}
                                        onClick={submitValueDetails}
                                    >
                                        Upload
                                    </Button>
                                </Grid>


                                <Grid item xs={12} md={3}>
                                    <a
                                        href={Customer_Template}
                                        download="Customer_Template"
                                        target="_blank"
                                    >
                                        <Button className="pt-button--primary bulk-upload-submit-btn">
                                            Download Template
                                        </Button>
                                    </a>
                                </Grid>



                            </Grid>
                        </Grid>

                    ) : (
                        <></>
                    )}

                    <div className="marginTop">
                        <Grid>
                            <MaterialTable
                                title="Customer Master"
                                columns={columns}
                                data={data}
                                icons={tableIconsInputScreen}
                                options={{
                                    headerStyle: { background: '#d3d3d3' }, tableOptionsUser, maxBodyHeight: "370px", searchFieldAlignment: "left",
                                    toolbarButtonAlignment: "left", exportButton: { csv: true }, exportAllData: true,
                                }}
                                actions={[
                                     {
                                         icon: GetAddButton,
                                         isFreeAction: true,
                                         onClick: () => {
                                             setFormDialogTitle("Save");
                                             setCustDialogTitle("Add Customer");
                                             setOpenDia(true);


                                         },
                                     },

                                     {
                                         icon: GetEditButton,
                                         tisFreeAction: true,
                                         onClick: (e, rowData) => {
                                             setFormDialogTitle("Update");
                                             setCustDialogTitle("Update Customer");
                                             setOpenDia(true)
                                             dialogDraftValuesFn(rowData);
                                         },
                                     },

                                    {
                                         icon: GetDeleteButton,
                                         tisFreeAction: false,
                                         onClick: (e, rowData) => {
                                             deletePoEntryRow(rowData)
                                             //deleteWithoutCustCode(rowData);
                                            // handleDeleteIconClick(rowData);
                                         },


                                     },




                                ]}
                                

                            />
                        </Grid>
                    </div>
                </Grid>
                <div>
                    <Dialog
                        open={dialogOpen}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {" Data Upload Alert"}
                        </DialogTitle>
                        <DialogContent>
                            {dialogContent && typeof dialogContent === "object" ? (
                                <DialogContentText id="alert-dialog-description">
                                    <h5>
                                        {dialogContent
                                            ?.filter((x, index) => index === 0)
                                            ?.map((c) => (
                                                <>&emsp;&emsp;{c.join(" ")}</>
                                            ))}
                                    </h5>
                                    <ol className="ol-bulkUpload-Error">
                                        {dialogContent
                                            ?.filter((x, index) => index !== 0)
                                            ?.map((c) => (
                                                <li>
                                                    {c.map((x) =>
                                                        x.includes("--") && x.includes(":") ? (
                                                            x
                                                        ) : (
                                                            <ul className="ul-bulkUpload-Error">
                                                                <li>{x}</li>
                                                            </ul>
                                                        )
                                                    )}
                                                </li>
                                            ))}
                                    </ol>
                                </DialogContentText>
                            ) : (
                                <DialogContentText
                                    id="alert-dialog-description "
                                    className="dialog-center"
                                >
                                    {dialogContent?.includes("successfully.") ? (
                                        <>
                                            {dialogContent?.split("successfully.")[0] +
                                                " successfully."}
                                            {dialogContent?.split("successfully.")?.length > 0 ? (
                                                <>
                                                    <br />
                                                    {dialogContent?.split("successfully.")[1]}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    ) : (
                                        dialogContent
                                    )}
                                </DialogContentText>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                className="pt-button--secondary"
                                title="Close"
                            >
                                Close
                            </Button>
                            {errorData && errorData.length > 0 ? (
                                <Button
                                    onClick={exportErrorData}
                                    className="pt-button--secondary"
                                    title={
                                        "Export   upload error data to excel"
                                    }
                                >
                                    Export Error Data
                                </Button>
                            ) : (
                                <></>
                            )}
                        </DialogActions>
                    </Dialog>
                    <Dialog fullWidth
                        onClose={(event, reason) => {
                            if (reason !== "backdropClick") {
                                handleCloseDia();
                            }
                        }}
                        aria-labelledby="litigation-form--registration"
                        open={openDia}
                        maxWidth={"lg"}
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="responsive-dialog-title">{custDialogTitle}</DialogTitle>
                        <div style={{ position: "absolute", right: "1%" }}>
                            <Tooltip title="Close">
                                <IconButton onClick={handleCloseDia}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                <Grid container spacing={2}>









                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                Customer Code
                                            </InputLabel>
                                            <BootstrapInput id="cutomer_po"
                                                name="cutomerPo"
                                                value={cust}
                                                type="number"
                                                disabled={flag }
                                                onChange={handleChangeCust}

                                            />
                                        </FormControl>
                                    </Grid>



                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                Customer Name
                                            </InputLabel>
                                            <BootstrapInput id="cutomer_po"
                                                name="cutomerPo"
                                                value={name1}
                                                onChange={handleChangeName1}

                                            />
                                        </FormControl>
                                    </Grid>
                                    {/*<Grid item xs={12} sm={3}>*/}
                                    {/*    <FormControl fullWidth>*/}
                                    {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                    {/*            Name2*/}
                                    {/*        </InputLabel>*/}
                                    {/*        <BootstrapInput id="cutomer_po"*/}
                                    {/*            name="cutomerPo"*/}
                                    {/*            value={name2}*/}
                                    {/*            onChange={handleChangeName2}*/}

                                    {/*        />*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}

                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                Street
                                            </InputLabel>
                                            <BootstrapInput id="rollnumber"
                                                name="cutomerPo"


                                                onChange={handleChangeStreet}
                                                value={street}

                                            />

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                City
                                            </InputLabel>
                                            <BootstrapInput id="cutomer_po"
                                                name="cutomerPo"
                                                value={city}
                                                onChange={handleChangeCity}

                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                Postal Code
                                            </InputLabel>
                                            <BootstrapInput id="cutomer_po"
                                                name="cutomerPo"
                                                value={postal}
                                                onChange={handleChangePostal}

                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                Country
                                            </InputLabel>
                                            <BootstrapInput id="cutomer_po"
                                                name="cutomerPo"
                                                value={country}
                                                onChange={handleChangeCountry}

                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                Tax Code 3
                                            </InputLabel>
                                            <BootstrapInput id="cutomer_po"
                                                name="cutomerPo"
                                                value={code}
                                                onChange={handleChangeCode}

                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                Contact Person
                                            </InputLabel>
                                            <BootstrapInput id="cutomer_po"
                                                name="cutomerPo"
                                                value={person}
                                                onChange={handleChangePerson}

                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                Mobile
                                            </InputLabel>
                                            <BootstrapInput id="cutomer_po"
                                                name="cutomerPo"
                                                value={mobile}
                                                onChange={handleChangeMobile}

                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                Email Id
                                            </InputLabel>
                                            <BootstrapInput id="cutomer_po"
                                                name="cutomerPo"
                                                value={email}
                                                onChange={handleChangeEmail}

                                            />
                                        </FormControl>
                                    </Grid>

                                </Grid>

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type="submit"

                                className="pt-button--secondary"
                                id="addUserSave"
                                onClick={
                                    formDialogTitle === "Save" ? submitSave : submit
                                }
                            >
                                {formDialogTitle}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={deleteDialogOpen}
                        onClose={handleCloseDeleteDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="dialogTitleTxt">Delete Customer</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure to Delete this Customer Entry ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={deleteWithoutCustCode}
                                className="pt-button--primary"
                                disabled={custIdRow && custCodeD}
                            >
                                Ok
                            </Button>
                            <Button
                                onClick={handleCloseDeleteDialog}
                                className="pt-button--secondary"
                                autoFocus
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                {isLoader ? <Loader loaderText="Loading...!" /> : <></>}
                {isLoading ? <Loader loaderText="Loading...!" /> : <></>}

            </div>

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
        </>
    );
}