
import { downloadACBReports, getDynamic } from "../../APIs/api_PoEntry";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
    TextField,
    Button,
    Grid,
    IconButton,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";
//import image1 from "../../assets/image1.png";
//import image2 from "../../assets/image2.png";
//import image4 from "../../assets/image4.png";
import siemenslogopetrol from "../../assets/images/siemens-logo-petrol.svg"
import MaterialTable from "material-table";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import PrintIcon from '@material-ui/icons/Print';
import * as FileSaver from "file-saver";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { geAllTypeReports } from "../../APIs/api_Insert"
export default function PreviewPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [dynamic, setDynamic] = useState([]);
    const [customerName, setCustomerName] = useState("");
    const [customerAddress, setCustomerAddress] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [siemensSalesName, setsiemensSalesName] = useState("")
    const [siemensSalesAddress, setSiemensSalesAddress] = useState("");
    const [siemensSalesPhone, setSiemensSalesPhone] = useState("");
    const [siemensSalesEmail, setSiemensSalesEmail] = useState("");
    //groupDetailsObject
    const [commercialName, setCommercialName] = useState("");
    const [commercialPhone, setCommercialPhone] = useState("");
    const [commercialEmail, setCommercialEmail] = useState("");
    const [technicalL1Name, setTechnicalL1Name] = useState("");
    const [technicalL1Phone, setTechnicalL1Phone] = useState("");
    const [technicalL1Email, setTechnicalL1Email] = useState("");


    const [technicalL2Name, setTechnicalL2Name] = useState("");
    const [technicalL2Phone, setTechnicalL2Phone] = useState("");
    const [technicalL2Email, setTechnicalL2Email] = useState("");

    const [technicalL3Name, setTechnicalL3Name] = useState("");
    const [technicalL3Phone, setTechnicalL3Phone] = useState("");
    const [technicalL3Email, setTechnicalL3Email] = useState("");

    const [techSign, setTechSign] = useState("");
    const [comSign, setComSign] = useState("");

    const [itemObj, setItemObj] = useState([]);
    let tableHeaders = [];
    const token = useSelector((state) => state.saveTokenReducer.token);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);

    const UID = useSelector((state) => state.saveUserIdReducer);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const refNameData = useSelector((state) => state.referenceName);
    const [termsncondition, setTermsNconditions] = useState([]);
    const [notes, setNotes] = useState([]);


    var col = [
        { title: "id", field: "id", hidden: true },
        {
            title: 'Description',
            field: 'Description',
            //validate: (rowData) => validateAlphabet(rowData.userFirstName),

        },
        {
            title: 'MLFB',
            field: 'mlfb',
            //validate: (rowData) => validateAlphabet(rowData.userLastName),
        },
        {
            title: 'Qty',
            field: 'Qty',

        },
        {
            title: 'UnitLP',
            field: 'Rate',

        },
        {
            title: 'Tax Rate',
            field: 'TaxRate',

        },
        {
            title: 'Discount(%)',
            field: 'DiscountPercentage',

        },
        {
            title: 'Discount Per Qty',
            field: 'DiscountPerQty',

        },
        {
            title: 'Discount(In Amount)',
            field: 'DiscountInAmount',

        },
        {
            title: 'Total Discount',
            field: 'TotalDiscount',

        },
        {
            title: 'Unit PriceKp',
            field: 'UnitPriceKp',

        },
        {
            title: 'Total',
            field: 'OriginalTotal',

        },
        {
            title: 'Total(Incl Tax)',
            field: 'NetTaxValue',

        }




    ]
    //for snackBar
    const [open, setOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    function dynamicReport() {
        setIsLoading(true);
        getDynamic(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, refNameData.id)
            .then((response) => {
                console.log("getDatares", response);
                setDynamic(response.objResult[0]);
                setTermsNconditions(response.objResult[0].termsNconditionList);
                setNotes(response.objResult[0].notesList);
                setCustomerName(response.objResult[0].customerContactName);
                setCustomerAddress(response.objResult[0].customerContactAddress);
                setCustomerPhone(response.objResult[0].customerContactPhone);
                setCustomerEmail(response.objResult[0].customerContactEmail);
                setComSign(response.objResult[0].commercialSignature);
                setTechSign(response.objResult[0].technicalSignature);
                setsiemensSalesName(response.objResult[0].siemensSalesContactObject?.userFirstName);
                setSiemensSalesAddress(response.objResult[0].siemensSalesContactObject?.region);
                setSiemensSalesPhone(response.objResult[0].siemensSalesContactObject?.contactNumber);
                setSiemensSalesEmail(response.objResult[0].siemensSalesContactObject?.userEmail);

                setTechnicalL1Name(response.objResult[0].groupDetailsObject?.l1[0]?.userFirstName);
                setTechnicalL1Phone(response.objResult[0].groupDetailsObject?.l1[0]?.contactNumber);
                setTechnicalL1Email(response.objResult[0].groupDetailsObject?.l1[0]?.userEmail);


                setTechnicalL2Name(response.objResult[0].groupDetailsObject?.l2[0]?.userFirstName);
                setTechnicalL2Phone(response.objResult[0].groupDetailsObject?.l2[0]?.contactNumber);
                setTechnicalL2Email(response.objResult[0].groupDetailsObject?.l2[0]?.userEmail);

                setTechnicalL3Name(response.objResult[0].groupDetailsObject?.l3[0]?.userFirstName);
                setTechnicalL3Phone(response.objResult[0].groupDetailsObject?.l3[0]?.contactNumber);
                setTechnicalL3Email(response.objResult[0].groupDetailsObject?.l3[0]?.userEmail);

                setCommercialName(response.objResult[0].groupDetailsObject?.commercial[0]?.userFirstName);
                setCommercialPhone(response.objResult[0].groupDetailsObject?.commercial[0]?.contactNumber);
                setCommercialEmail(response.objResult[0].groupDetailsObject?.commercial[0]?.userEmail);





                setItemObj(response.objResult[0]?.itemObject);



                tableHeaders = response.objResult[0]?.itemObject;

                setIsLoading(false);

            })
            .catch((error) => {
                // setIsLoading(false);
                // handleSnackOpen(error, "error");
            })
    }



    function DynamicTable({ data }) {
        const renderTableHeaders = () => {
            if (data.length === 0) return null;
            const headers = Object.keys(data[0]);
            return headers.map(header => <th key={header}>{header}</th>);
        };

        const renderTableRows = () => {
            return data.map((row, index) => (
                <tbody style={{ paddingLeft: '15%', background: '#dae0f6' }}>
                    <tr key={index} style={{ background: "#DEEDF6" }}>
                        {Object.values(row).map((value, index) => (
                            <td style={{ textAlign: 'center' }} key={index}>{value}</td>
                        ))}
                    </tr>

                </tbody>
            ));
            //<tr>
            //    <td colspan="4">{dynamic.totalRupees}</td>
            //    <td colspan="2">{dynamic.total}</td>
            //</tr>
            //<tbody style={{ paddingLeft: '15%', background: '#DAE9F7' }}>
            //        <tr>
            //            <td colspan="4">{dynamic.totalRupees}</td>
            //            <td colspan="2">{dynamic.total}</td>
            //        </tr>
            //</tbody>

        };


        return (
            <table className="dynamic-table" style={{ width: '100%', height: '50%', marginTop: '1rem' }}>
                <thead style={{ background: '#5B9BD5' }}>
                    <tr>{renderTableHeaders()}</tr>
                </thead>

                {renderTableRows()}
                {/*        <tbody style={{ paddingLeft: '15%', background: '#DAE9F7' }}>*/}
                {/*        <tr>*/}
                {/*           <td colspan="4">{dynamic.totalRupees}</td>*/}
                {/*           <td colspan="2">{dynamic.total}</td>*/}
                {/*       </tr>*/}
                {/*</tbody>*/}







                {/*<tfoot style={{ background: '#DAE9F7' }}>*/}
                {/*    <tr>*/}
                {/*        <td  style={{ display: 'flex', justifyContent: 'space-between' }}><h5 style={{ margin: '0' }}>{dynamic.totalRupees}</h5> <h5 style={{ margin: '0' }}>{dynamic.total}</h5></td>*/}
                {/*        <td  style={{ display: 'flex', justifyContent: 'space-between' }}> <h5 style={{ margin: '0' }}>{dynamic.total}</h5></td>*/}


                {/*    </tr>*/}

                {/*</tfoot>*/}
            </table>

        );

    }


    const ptable = () => {
        return notes.map((item) => (
            //<div key={index}>
            //{
            //    Object.values(row).map((value, index) => (
            //        <p key={index}>{value}</p>
            //    ))
            //}
            //</div>
            <p>
                {item}
            </p>

        ));

    };
    const termtable = () => {
        return termsncondition.map((item) => (
            //<div key={index}>
            //{
            //    Object.values(row).map((value, index) => (
            //        <p key={index}>{value}</p>
            //    ))
            //}
            //</div>
            <p>
                {item}
            </p>

        ));
        //{
        //    statussId.map((item) => (
        //        <MenuItem key={item.statusId} value={item.statusId}>
        //            {item.statusName}
        //        </MenuItem>
        //    ))
        //}
    };
    const tableHeaderStyle = {
        backgroundColor: "var(--light-sand)",
        color: "var(--dark-blue)",
    };
    const tableOptions = {
        headerStyle: tableHeaderStyle,
        showTitle: false,
        draggable: false,
        pageSize: 5,
        pageSizeOptions: [5, 10, 25, 50],
        paginationPosition: "bottom",
        //paging: false,
        maxBodyHeight: '600px',
        // exportButton: true,
        // exportAllData: true
    };
    function printPreview() {
        setIsLoading(true);
        download();
    };

    function download() {
       
        geAllTypeReports(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, dynamic.offerReferenceNumberName)
            .then((response) => {
                console.log(response)

                //setfileurl(response.fileUrl)
                //files(rowData)
                setIsLoading(false);

                response.fileUrl != null || response.fileUrl != "" ? window.open(response.fileUrl, "_blank") : handleSnackOpen("Error while downloading Report.", "error");

            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen("Error while downloading Report.", "error");
            })
    }
    function downloadACBReport() {
        //setIsLoading(true);
        downloadACBReports(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, dynamic.offerReferenceNumberName)

            .then((response) => {
                console.log("download", response);
                //setIsLoading(true);
                if (response.status === 200) {
                    if (response.data.byteLength <= 200) {
                        setIsLoading(false);
                        handleSnackOpen("No Records are Found", "success");
                    } else {

                        const fileName = dynamic.offerReferenceNumberName + "_ACBSpares";
                        //const fileName = customer + "_SilverReport";
                        var blob = new Blob([response.data], {
                            type: "application/pdf",
                        });
                        FileSaver.saveAs(blob, fileName + ".pdf");
                        setIsLoading(false);
                        handleSnackOpen("Report downloaded.", "success");
                    }
                } else {
                    setIsLoading(false);
                    handleSnackOpen("Report not Available to Download.", "error");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen("Error while downloading Report.", "error");
            })
    }


    useEffect(() => {
        dynamicReport();
    }, [])
    return (

        <div style={{ fontFamily: 'siemens-sans', fontSize: '14px' }} >
            {/* <Grid style={{border:'1px solid black', width:'20px'} }></Grid>*/}
            <Grid className="print-menu hidden-print">
                <IconButton color="secondary" onClick={printPreview} aria-label="add an alarm"><PrintIcon /></IconButton>
            </Grid>
            {/*FIRST PAGE */}
            <Grid container spacing={4} style={{ justifyContent: "space-between", display: "flex" }} className="newpage">
                <Grid container spacing={2} item xs={2} md={3} style={{ backgroundColor: 'grey' }}></Grid>
                <Grid container spacing={2} item xs={10} md={9} style={{ backgroundColor: '#cccccc' }}>



                    <Grid item xs={12} className='d-flex jc-center'><h2 style={{ textDecoration: 'underline', marginTop: '18px' }}>Proposal for Supply of ACB Spares</h2></Grid>
                    <Grid item xs={4} md={2}><p style={{ fontSize: '1rem', marginTop: '-10px' }}>Proposal number</p></Grid>
                    <Grid item xs={8} md={10}><p style={{ fontSize: '1rem', marginTop: '-10px' }}>{dynamic.offerReferenceNumberName}</p></Grid>

                    <Grid item xs={4} md={2}><p style={{ fontSize: '1rem', marginTop: '-15px' }}>Customer</p></Grid>
                    <Grid item xs={8} md={10}><p style={{ fontSize: '1rem', marginTop: '-15px' }}>{dynamic.customerName}</p> </Grid>
                    {/*<Grid item xs={4} md={4} className='d-flex jc-center'><p style={{ fontSize: '1rem', marginTop: '-10px' }}>Proposal number</p></Grid>*/}
                    {/*<Grid item xs={8} md={6} className='d-flex jc-center'><p style={{ fontSize: '1rem', marginTop: '-10px'}}>{dynamic.offerReferenceNumberName}</p></Grid>*/}
                   
                    {/*<Grid item xs={4} md={4}><p style={{ fontSize: '1rem', marginTop: '-15px' }} className='d-flex jc-center'>Customer</p></Grid>*/}
                    {/*<Grid item xs={8} md={4}><p style={{ fontSize: '1rem', marginTop: '-15px' }} className='d-flex jc-center'>{dynamic.customerName}</p> </Grid>*/}


                    <Grid item xs={12} style={{ marginTop: '2rem', marginLeft: "-2rem" }} >
                        <img src="../../assets/imagezoom.png" alt="siemens_logo" width="100%" height="100%" />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '7rem', minHeight: '2rem', background: '#487182', alignSelf: "center", marginLeft: '-16px' }}>
                        {/*<img src="../../assets/image2.png" alt="siemens_logo" width="75%" height="100%" style={{ marginLeft: "-26px" }} />*/}
                        <h2 style={{ color: '#FFFFFF' }}> SIEMENS make Air Circuit Breaker Genuine Spares</h2>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '2rem', paddingTop: '5.2rem' }} >
                        <div>
                            <img src="../../assets/image8.png" alt="siemens_logo" width="125%" height="100%" style={{ marginLeft: "-150px" }} />
                        </div>
                    </Grid>

                </Grid>







            </Grid>
            {/*SECOND PAGE */}
            <Grid>

                <Grid item xs={12} style={{ marginTop: '3rem' }}>
                    <img src={siemenslogopetrol} alt="siemens_logo" width="20%" height="20%" />
                </Grid>

                <Grid item xs={12} style={{ marginTop: '1rem', }} className="media-print media-screen">
                    {/*  <img src="../../assets/image5.png" alt="siemens_logo" width="75%" height="75%" />*/}

                    <table style={{ fontSize: '18px', fontWeight: 'bold', width: '100%', lineHeight: "48px", border: "0px" }}>

                        <tr style={{ border: "0px" }}>

                            <td style={{ border: "0px" }}>General Description</td>

                            <td style={{ background: '#ccc', border: "0px", textAlign: 'center' }}>1</td>

                        </tr>

                        <tr style={{ border: '0px' }}  >
                            <td style={{ border: '0px' }} ><h3></h3></td>
                            <td style={{ border: '0px' }}  ><h4 > </h4></td>

                        </tr>

                        <tr style={{ border: "0px", marginBottom: '10px' }}>

                            <td style={{ border: "0px" }}>Executive Summary</td>

                            <td style={{ background: '#ccc', border: "0px", textAlign: 'center' }}>2</td>

                        </tr>


                        <tr style={{ border: '0px' }}  >
                            <td style={{ border: '0px' }} ><h3></h3></td>
                            <td style={{ border: '0px' }}  ><h4 > </h4></td>

                        </tr>



                        <tr style={{ border: "0px" }}>

                            <td style={{ border: "0px" }}>Project Summary</td>

                            <td style={{ background: '#ccc', border: "0px", textAlign: 'center' }}>3</td>

                        </tr>

                        <tr style={{ border: '0px' }}  >
                            <td style={{ border: '0px' }} ><h3></h3></td>
                            <td style={{ border: '0px' }}  ><h4 > </h4></td>

                        </tr>

                        <tr style={{ border: "0px" }}>

                            <td style={{ border: "0px" }}>Scope of supply</td>

                            <td style={{ background: '#ccc', border: "0px", textAlign: 'center' }}>4</td>

                        </tr>

                        <tr style={{ border: '0px' }}  >
                            <td style={{ border: '0px' }} ><h3></h3></td>
                            <td style={{ border: '0px' }}  ><h4 > </h4></td>

                        </tr>

                        <tr style={{ border: "0px" }}>

                            <td style={{ border: "0px" }}>Terms and Conditions</td>

                            <td style={{ background: '#ccc', border: "0px", textAlign: 'center' }}>5</td>

                        </tr>

                        <tr style={{ border: '0px' }}  >
                            <td style={{ border: '0px' }} ><h3></h3></td>
                            <td style={{ border: '0px' }}  ><h4 > </h4></td>

                        </tr>

                        <tr style={{ border: "0px" }}>

                            <td style={{ border: "0px" }}>GTC</td>

                            <td style={{ background: '#ccc', border: "0px", textAlign: 'center' }}>7</td>

                        </tr>

                        <tr style={{ border: '0px' }}  >
                            <td style={{ border: '0px' }} ><h3></h3></td>
                            <td style={{ border: '0px' }}  ><h4 > </h4></td>

                        </tr>

                        <tr style={{ border: "0px" }}>

                            <td style={{ border: "0px" }}>Contact Matrix</td>

                            <td style={{ background: '#ccc', border: "0px", textAlign: 'center' }}>8</td>

                        </tr>

                    </table>

                </Grid>

            </Grid >


            <Grid className="newpage"> <Grid style={{
                width: '100%', borderBottom: '2px solid black', marginTop: '485px'
            }} ></Grid>
                <p style={{ fontSize: '12px' }}>Confidential</p></Grid>
            {/*THIRD PAGE */}
            <div style={{ marginTop: '1rem' }}>
                <table style={{ marginTop: '2.5rem', width: '100%' }}>
                    <tr>
                        <td rowspan="2" style={{ width: '18%', paddingLeft: "12px" }}>
                            <img src={siemenslogopetrol} alt="siemens_logo" height="10%" style={{ width: '97%' }} />
                        </td>
                        <td style={{ width: '35%', paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Proposal number
                            <p style={{ fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.offerReferenceNumberName}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Ver
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.version}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Date
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.offerCreatedDate}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Status
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.status}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Page
                            <p style={{ fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>3 of 9</p>
                        </td>
                    </tr>
                    <tr> <td colspan="5" style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontSize: '16px' }}>Title: Supply of ACB Spares
                        {/*   <p> Supply of LV Panels</p>*/}
                    </td></tr>
                </table>
            </div>


            <h4 style={{ marginTop: '4rem' }}>Document History</h4>
            <table style={{ width: '100%', marginTop: '-17px' }}>
                <tr>

                    <td style={{ width: '15%', paddingLeft: "12px", backgroundColor: '#cccccc' }}>
                        <h4> Rev</h4>
                    </td>
                    <td style={{ width: '35%', paddingLeft: "12px", backgroundColor: '#cccccc' }}>
                        <h4 >Date</h4>
                    </td>
                    <td style={{ width: '35%', paddingLeft: "12px", backgroundColor: '#cccccc' }}>
                        <h4 >Description</h4>
                    </td>
                    <td style={{ width: '15%', paddingLeft: "12px", backgroundColor: '#cccccc' }}>
                        <h4 >Author</h4>
                    </td>

                </tr>
                <tr>

                    <td style={{ width: '15%', paddingLeft: "12px" }}>
                        <p>{dynamic.version}</p>
                    </td>
                    <td style={{ width: '35%', paddingLeft: "12px" }}>
                        <p >{dynamic.offerCreatedDate}</p>
                    </td>
                    <td style={{ width: '35%', paddingLeft: "12px" }}>
                        <p >{dynamic.version === "R0" ? "Commercial Proposal" : "Revised Commercial Proposal"}</p>
                    </td>
                    <td style={{ width: '15%', paddingLeft: "12px" }}>
                        <p >{dynamic.createdByName}</p>
                    </td>

                </tr>
            </table>

            <Grid>
                <Grid item xs={12} style={{ marginTop: '5rem' }} >
                    <img src="../../assets/image3.png" alt="siemens_logo" width="100%" height="10%" />
                </Grid>
            </Grid>

            {/*<Grid className="newpage"> <Grid style={{*/}
            {/*    width: '100%', borderBottom: '2px solid black', marginTop: '150px'*/}
            {/*}} ></Grid>*/}
            {/*    <p style={{ fontSize: '12px' }}>Confidential</p>*/}
            {/*    <Grid container style={{ marginTop: '-22px' }}>*/}

            {/*        <Grid item xs={4} md={6}><p style={{ fontSize: '1rem' }}>  Customer:</p></Grid>*/}
            {/*        <Grid item xs={8} md={6}><p style={{ fontSize: '1rem' }}>{dynamic.customerName}</p></Grid>*/}
            {/*    </Grid>*/}

            {/*</Grid>*/}
            <Grid container spacing={2} className="newpage" style={{ width: '100%', borderTop: '2px solid black', marginTop: '180px' }}>

                <Grid container item xs={6} md={9}>
                    <Grid item xs={6} className="d-flex jc-flex-start">
                        <p style={{ fontSize: '12px' }}>Customer:</p>
                    </Grid>
                    <Grid item xs={6} className="d-flex jc-center">
                        <p style={{ fontSize: '12px' }}>{dynamic.customerName}</p>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={3} className="d-flex jc-flex-end">
                    <p style={{ fontSize: '12px' }}>Confidential</p>
                </Grid>
            </Grid>

            {/*FOURTH PAGE */}
            <div style={{ marginTop: '0.5rem' }}>
                <table style={{ marginTop: '2.5rem', width: '100%' }}>
                    <tr>
                        <td rowspan="2" style={{ width: '18%', paddingLeft: "12px" }}>
                            <img src={siemenslogopetrol} alt="siemens_logo" height="10%" style={{ width: '97%' }} />
                        </td>
                        <td style={{ width: '35%', paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Proposal number
                            <p style={{ fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.offerReferenceNumberName}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Ver
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.version}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Date
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.offerCreatedDate}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Status
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.status}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Page
                            <p style={{ fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>4 of 9</p>
                        </td>
                    </tr>
                    <tr> <td colspan="5" style={{ paddingLeft: "12px", fontWeight: 'bold', fontFamily: 'SiemensSans-Bold' }}>Title: Supply of ACB Spares
                        {/*  <p> Supply of ACB Spares</p>*/}
                    </td></tr>
                </table>
            </div>

            <div style={{ paddingLeft: '28px', fontFamily: 'siemens-sans' }}>
                <h4>1. GENERAL DESCRIPTION</h4>
                <p style={{ fontSize: '13px', fontWeight: '500' }}>Siemens Ltd. appreciates the opportunity to provide 40194326-Tata Central Hosp. -Dhanbad, with a technical concept document for <span- style={{ textDecoration: 'underline' }}>Supply of ACB Spares</span-> <br /><br />
                    The information contained in this quotation and all related documents is proprietary, confidential, information that belongs to SIEMENS. This information
                    is being disclosed to you for the specific purpose of your evaluation of SIEMENS as a supplier for implementation of your electrical and installation
                    strategy. By reviewing the information contained in these documents, you agree to be bound to a confidentiality obligation with respect to this
                    information. Specifically, you hereby agree that (1) you will treat this information with the same level of care as you treat your own proprietary
                    information; (2) you will not disclose this information to third parties without the prior written consent of SIEMENS; and (3) you will not use this
                    information for any purpose beyond evaluating SIEMENS as a contractor and/or contracting with SIEMENS for the particular purpose quoted herein.
                    &quot;This is a price quote based on preliminary information, thus is purely indicative and does not constitute an offer or commitment nor does it contain any
                    representation or warranty on Siemens part. Should there be a requirement to progress this transaction please revert back with details and specifications
                    and we shall address the request appropriately at that time and subject to all internal approvals as may be required by us.&quot;
                </p>
                <h4 style={{ margin: '0' }}>Siemens shall not be obligated to fulfill this Agreement if such fulfillment is prevented by any impediments arising out of national or international
                    foreign trade or customs requirements or any embargos or other sanctions</h4> <br /><br />
                <p style={{ margin: '0' }}> SIEMENS believes that the information contained within this tender is accurate as of its publication date; such information contained herein is also based
                    upon information that was provided to us by our suppliers.</p>


                <h4>2. EXECUTIVE SUMMARY</h4>

                <h4 style={{ textDecoration: 'underline' }}>Siemens in India:</h4>

                <p style={{ fontSize: '13px', fontWeight: '500' }}>Siemens Limited is a technology company focused on industry, infrastructure, digital transformation, transport as well as transmission and generation of electrical power. It is the flagship listed company of Siemens AG in India. The company&apos;s business structure is well oriented in meeting the needs of the industry in improving efficiency, quality, flexibility and speed. With its wide-ranging portfolio, market-oriented organization structure, global technology leadership and strong local competence, the company is in a good position to partner with the country in sustainable growth.</p>
            </div>
            <ul style={{ background: 'transparent', fontFamily: 'SiemensSans-Bold', fontSize: '14px', fontWeight: '500' }}>
                <li>
                    Digital Industries
                </li>
                <li>
                    Mobility
                </li>
                <li>
                    Energy
                </li>
                <li>
                    Financial Services
                </li>
                <li>
                    Siemens Healthineers
                </li>
                <li>
                    Smart Infrastructure
                </li>
            </ul>
            <div style={{ minHeight: '130px' }} className="d-flex jc-center">
                <a style={{ color: '#0000ff', textDecoration: 'underline', fontSize: '25px' }} href="https://www.siemens.com/in/en.html">www.siemens.com/in/en.html</a>
            </div>
          
            <Grid container spacing={2} className="newpage" style={{ width: '100%', borderTop: '2px solid black', marginTop: '0px' }}>

                <Grid container item xs={6} md={9}>
                    <Grid item xs={6} className="d-flex jc-flex-start">
                        <p style={{ fontSize: '12px' }}>Customer:</p>
                    </Grid>
                    <Grid item xs={6} className="d-flex jc-center">
                        <p style={{ fontSize: '12px' }}>{dynamic.customerName}</p>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={3} className="d-flex jc-flex-end">
                    <p style={{ fontSize: '12px' }}>Confidential</p>
                </Grid>
            </Grid>
            {/*FIFTH PAGE */}
            <div style={{ marginTop: '3rem' }}>
                <table style={{ marginTop: '1rem', width: '100%' }}>
                    <tr>
                        <td rowspan="2" style={{ width: '18%', paddingLeft: "12px" }}>
                            <img src={siemenslogopetrol} alt="siemens_logo" height="10%" style={{ width: '97%' }} />
                        </td>
                        <td style={{ width: '35%', paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Proposal number
                            <p style={{ fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.offerReferenceNumberName}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Ver
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.version}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Date
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.offerCreatedDate}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Status
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.status}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Page
                            <p style={{ fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>5 of 9</p>
                        </td>
                    </tr>
                    <tr> <td colspan="5" style={{ paddingLeft: "12px", fontWeight: 'bold', fontFamily: 'SiemensSans-Bold' }}>Title: Supply of ACB Spares
                        {/*<p> Supply of ACB Spares</p>*/}
                    </td></tr>
                </table>
            </div>

            <Grid>
                <Grid item xs={12} style={{ marginTop: '1.5rem' }} >
                    <img src="../../assets/SIEMENS4.webp" alt="siemens_logo" width="100%" height="370" />
                </Grid>
            </Grid>




            <ul style={{ background: 'transparent' }}>
                <li><h4>3. PROJECT SUMMARY</h4>
                </li>
            </ul>


            <ul style={{ background: 'transparent' }}>
                <li><h4 >3.1. PURPOSE OF THE DOCUMENT</h4>
                    <p style={{ fontSize: '13px', fontWeight: '500' }}>This document is prepared in response to the requirements given by {customerName} (hereinafter referred to as &quot;the CUSTOMER&quot;) to Siemens
                        India Ltd (hereinafter referred to as &quot;SIEMENS&quot;) for Supply of Air Circuit Breaker Spares (hereinafter referred to as &quot;the project&quot;). the proposal is based on the
                        documents given by the CUSTOMER and discussions between the CUSTOMER and SIEMENS/SIEMENS License partner.<br /><br />
                        The document describes the assessment & evaluation of the technical requirement & implementation methodology by SIEMENS. It explains the
                        SIEMENS understanding of the project in terms of scope, tasks and the assumptions under which this proposal is made.<br />
                    </p></li>
            </ul>

            <ul style={{ background: 'transparent' }}>
                <li><h4 >3.2. CUSTOMER CONTACT</h4>
                    <table style={{ marginTop: '1rem', width: '100%' }}>
                        <tr >
                            <td style={{ width: '10%', paddingLeft: "12px", backgroundColor: 'rgb(204, 204, 204)' }}>Name</td>
                            <td style={{ width: '35%', paddingLeft: "12px" }}>{customerName}</td>
                            <td colspan="2"></td>


                        </tr>
                        <tr><td style={{ paddingLeft: "12px", backgroundColor: 'rgb(204, 204, 204)' }}>Address</td><td colspan="3" style={{ paddingLeft: "12px" }}>{customerAddress}</td>
                        </tr>
                        <tr><td style={{ paddingLeft: "12px", backgroundColor: 'rgb(204, 204, 204)' }}>Phone</td><td style={{ paddingLeft: "12px" }}>{customerPhone}</td>
                            <td style={{ paddingLeft: "12px", backgroundColor: 'rgb(204, 204, 204)' }}>Email</td><td style={{ paddingLeft: "12px", color: '#0000ff' }}>{customerEmail}</td>
                        </tr>

                    </table>
                </li>
            </ul>


            <ul style={{ background: 'transparent' }}>
                <li><h4 >3.3. SIEMENS SALES CONTACT</h4>
                    <table style={{ marginTop: '1rem', width: '100%' }}>
                        <tr >
                            <td style={{ width: '10%', paddingLeft: "12px", backgroundColor: 'rgb(204, 204, 204)' }}>Name</td>
                            <td style={{ width: '25%', paddingLeft: "12px" }}>{siemensSalesName}</td>
                            <td colspan="2"></td>

                        </tr>
                        <tr><td style={{ paddingLeft: "12px", backgroundColor: 'rgb(204, 204, 204)' }}>Address</td><td style={{ paddingLeft: "12px" }} colspan="3">{siemensSalesAddress}</td>
                        </tr>
                        <tr><td style={{ paddingLeft: "12px", backgroundColor: 'rgb(204, 204, 204)' }}>Phone</td><td style={{ paddingLeft: "12px" }}>{siemensSalesPhone}</td>
                            <td style={{ paddingLeft: "12px", backgroundColor: 'rgb(204, 204, 204)' }}>Email</td><td style={{ paddingLeft: "12px", color: '#0000ff' }}>{siemensSalesEmail}</td>
                        </tr>

                    </table>
                </li>
            </ul>


          
            <Grid container spacing={2} className="newpage" style={{ width: '100%', borderTop: '2px solid black', marginTop: '2rem' }}>

                <Grid container item xs={6} md={9}>
                    <Grid item xs={6} className="d-flex jc-flex-start">
                        <p style={{ fontSize: '12px' }}>Customer:</p>
                    </Grid>
                    <Grid item xs={6} className="d-flex jc-center">
                        <p style={{ fontSize: '12px' }}>{dynamic.customerName}</p>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={3} className="d-flex jc-flex-end">
                    <p style={{ fontSize: '12px' }}>Confidential</p>
                </Grid>
            </Grid>
            {/*SIXTH PAGE */}
            <div style={{ marginTop: '1rem' }}>
                <table style={{ marginTop: '2.5rem', width: '100%' }}>
                    <tr>
                        <td rowspan="2" style={{ width: '18%', paddingLeft: "12px" }}>
                            <img src={siemenslogopetrol} alt="siemens_logo" height="10%" style={{ width: '97%' }} />
                        </td>
                        <td style={{ width: '35%', paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Proposal number
                            <p style={{ fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.offerReferenceNumberName}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Ver
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.version}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Date
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.offerCreatedDate}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Status
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.status}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Page
                            <p style={{ fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>6 of 9</p>
                        </td>
                    </tr>
                    <tr> <td colspan="5" style={{ paddingLeft: "12px", fontWeight: 'bold', fontFamily: 'SiemensSans-Bold' }}>Title: Supply of ACB Spares
                        {/*<p> Supply of LV Panels</p>*/}
                    </td></tr>
                </table>
            </div>
            <div style={{ paddingLeft: '28px', fontFamily: 'siemens-sans' }}>
                <h4>4. SCOPE OF SUPPLY</h4>
                <p>Siemens Ltd scope is limited to supply of ACB Spares as per below list.</p>

                <h4 style={{ marginBottom: '-13px' }}>Scope Of Main Supply</h4>


                <div style={{ minHeight: '830px', maxHeight: '830px' }}>
                    <Grid item xs={12}>
                        {itemObj ? <DynamicTable data={itemObj} style={{ width: '100%' }} /> : <></>}
                    </Grid>


                    <Grid container spacing={2} style={{ padding: "12px 8px" }}>
                        <Grid container item md={12} style={{ background: '#DEEDF6' }} className="d-flex jc-space-bt">
                            <Grid item md={6} className="d-flex jc-flex-start">
                                <h4 style={{ margin: "0" }}>Total Price (w/o GST)</h4>
                            </Grid>
                            <Grid item md={6} className="d-flex jc-flex-end">
                                <p style={{ margin: "0", fontSize: '15px' }}>{dynamic.total}</p>
                            </Grid>
                        </Grid>
                        <Grid item md={12} style={{ background: '#DEEDF6', width: '100%' }} >
                            <h4 style={{ margin: "0", width: '100%', fontSize: '15px' }} className="d-flex jc-center">{dynamic.totalRupees}</h4>

                        </Grid>
                    </Grid>
                </div>

            </div>

            <Grid container spacing={2} className="newpage" style={{ width: '100%', borderTop: '2px solid black', marginTop: '0px' }}>

                <Grid container item xs={6} md={9}>
                    <Grid item xs={6} className="d-flex jc-flex-start">
                        <p style={{ fontSize: '12px' }}>Customer:</p>
                    </Grid>
                    <Grid item xs={6} className="d-flex jc-center">
                        <p style={{ fontSize: '12px' }}>{dynamic.customerName}</p>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={3} className="d-flex jc-flex-end">
                    <p style={{ fontSize: '12px' }}>Confidential</p>
                </Grid>
            </Grid>


            {/*SEVENTH PAGE */}
            <div>
                <table style={{ marginTop: '2rem', width: '100%' }}>
                    <tr>
                        <td rowspan="2" style={{ width: '18%', paddingLeft: "12px" }}>
                            <img src={siemenslogopetrol} alt="siemens_logo" height="10%" style={{ width: '97%' }} />
                        </td>
                        <td style={{ width: '35%', paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Proposal number
                            <p style={{ fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.offerReferenceNumberName}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Ver
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.version}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Date
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.offerCreatedDate}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Status
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.status}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Page
                            <p style={{ fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>7 of 9</p>
                        </td>
                    </tr>
                    <tr> <td colspan="5" style={{ paddingLeft: "12px", fontWeight: 'bold', fontFamily: 'SiemensSans-Bold' }}>Title: Supply of ACB Spares
                        {/* <p> Supply of ACB Spares</p>*/}
                    </td></tr>
                </table>
            </div>
            <div style={{ paddingLeft: '28px', fontFamily: 'siemens-sans', minHeight: '930px', maxHeight: '930px' }}>
                <h4 style={{ fontFamily: 'siemens-sans', fontWeight: 'bold', fontSize: '15px', marginTop: "1rem" }}>5. TERMS AND CONDITIONS</h4>

                {/*<p>{termsncondition}</p>*/}
                {termtable()}
                <p style={{ fontWeight: 'bold' }}>We request you to send your valued Purchase Order with Quote ID to the address below:</p>
                <div>
                    <p style={{ fontFamily: "SiemensSans-Bold", lineHeight: '1.8', width: '45%', border: '1px solid #ff5454', paddingLeft: '8px' }} >
                        Siemens Limited.<br />
                        Digital Enterprise Services<br />
                        R&D Technology Centre, Kalwa Works, Thane Belapur Road, Airoli,<br />
                        Navi Mumbai-400 708,<br />
                        Maharashtra, India,<br />
                        GSTIN - 27AAACS0764L1Z6<br />
                    </p>
                </div>
               
            </div>

            <Grid container spacing={2} className="newpage" style={{ width: '100%', borderTop: '2px solid black', marginTop: '0px' }}>

                <Grid container item xs={6} md={9}>
                    <Grid item xs={6} className="d-flex jc-flex-start">
                        <p style={{ fontSize: '12px' }}>Customer:</p>
                    </Grid>
                    <Grid item xs={6} className="d-flex jc-center">
                        <p style={{ fontSize: '12px' }}>{dynamic.customerName}</p>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={3} className="d-flex jc-flex-end">
                    <p style={{ fontSize: '12px' }}>Confidential</p>
                </Grid>
            </Grid>
            {/*EIGHTH PAGE */}
            <div>
                <table style={{ marginTop: '2rem', width: '100%' }}>
                    <tr>
                        <td rowspan="2" style={{ width: '18%', paddingLeft: "12px" }}>
                            <img src={siemenslogopetrol} alt="siemens_logo" height="10%" style={{ width: '97%' }} />
                        </td>
                        <td style={{ width: '35%', paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Proposal number
                            <p style={{ fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.offerReferenceNumberName}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Ver
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.version}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Date
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.offerCreatedDate}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Status
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.status}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Page
                            <p style={{ fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>8 of 9</p>
                        </td>
                    </tr>
                    <tr> <td colspan="5" style={{ paddingLeft: "12px", fontWeight: 'bold', fontFamily: 'SiemensSans-Bold' }}>Title: Supply of ACB Spares
                        {/* <p> Supply of ACB Spares</p>*/}
                    </td></tr>
                </table>
            </div>
            <div style={{ paddingLeft: '28px', fontFamily: 'siemens-sans', minHeight: '930px', maxHeight: '930px' }}>
             
                <h4 style={{ fontFamily: 'siemens-sans', fontWeight: 'bold', fontSize: '15px' }}>6. NOTES</h4>

                {ptable()}


                <h4 style={{ fontFamily: 'siemens-sans', fontWeight: 'bold', fontSize: '15px' }}>7. GENERAL TERMS AND CONDITIONS</h4>
                <div style={{ minHeight: '190px' }}>
                    <p>GTC-As a Separate document is attached</p>
                    <h4 style={{ backgroundColor: 'greenyellow' }}>For Product Manuals,FAQ,Test Certificates,Catalogues etc,please use below link</h4>

                    <a style={{ color: '#0000ff', textDecoration: 'underline', fontSize: '25px' }} className='d-flex jc-center' href="https://support.industry.siemens.com/cs/start?lc=en-US">https://support.industry.siemens.com/cs/start?lc=en-US</a>


                </div>
            </div>

            <Grid container spacing={2} className="newpage" style={{ width: '100%', borderTop: '2px solid black', marginTop: '0px' }}>

                <Grid container item xs={6} md={9}>
                    <Grid item xs={6} className="d-flex jc-flex-start">
                        <p style={{ fontSize: '12px' }}>Customer:</p>
                    </Grid>
                    <Grid item xs={6} className="d-flex jc-center">
                        <p style={{ fontSize: '12px' }}>{dynamic.customerName}</p>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={3} className="d-flex jc-flex-end">
                    <p style={{ fontSize: '12px' }}>Confidential</p>
                </Grid>
            </Grid>
            {/*NINETH PAGE */}
            <div style={{ marginTop: '1rem' }}>
                <table style={{ marginTop: '3rem', width: '100%' }}>
                    <tr>
                        <td rowspan="2" style={{ width: '18%', paddingLeft: "12px" }}>
                            <img src={siemenslogopetrol} alt="siemens_logo" height="10%" style={{ width: '97%' }} />
                        </td>
                        <td style={{ width: '35%', paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Proposal number
                            <p style={{ fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.offerReferenceNumberName}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Ver
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.version}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Date
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.offerCreatedDate}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Status
                            <p style={{ color: '#0000ff', fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>{dynamic.status}</p>
                        </td>
                        <td style={{ paddingLeft: "12px", fontFamily: 'SiemensSans-Bold', fontWeight: 'bold', }}>Page
                            <p style={{ fontWeight: 'bold', fontFamily: 'SiemensSans-Bold', fontSize: '14px' }}>9 of 9</p>
                        </td>
                    </tr>
                    <tr> <td colspan="5" style={{ paddingLeft: "12px", fontWeight: 'bold', fontFamily: 'SiemensSans-Bold' }}>Title: Supply of ACB Spares
                        {/*<p> Supply of ACB Spares</p>*/}
                    </td></tr>
                </table>
            </div>


            <div style={{ paddingLeft: '28px', fontFamily: 'siemens-sans' }}>
            <h4>8. Contact Matrix</h4>
            <p >For details about the SI EP CS offerings,please reach out to the following</p>


                <table style={{ marginTop: '1rem', width: '100%', borderBottom: '1px solid black' }}>
                    <tr style={{ backgroundColor: 'rgb(204, 204, 204)' }} >

                    <td style={{ width: '15%', paddingLeft: "12px" }}>
                        <h4> Role/Portfolio</h4>
                    </td>
                    <td style={{ width: '15%', paddingLeft: "12px" }}>
                        <h4> Point of Contact</h4>


                    </td>


                </tr>

                <tr>

                        <td style={{ width: '15%', paddingLeft: "12px", backgroundColor: 'rgb(204, 204, 204)' }}>
                        <h4> Technical Level1</h4>
                    </td>
                    <td style={{ width: '15%', paddingLeft: "12px", background: '#CAEDFB' }}>
                        <p> {technicalL1Name}</p>
                       
                            <p> {technicalL1Email},{technicalL1Phone}</p>

                    </td>


                </tr>
                <tr>

                        <td style={{ width: '15%', paddingLeft: "12px", backgroundColor: 'rgb(204, 204, 204)' }}>
                        <h4> Technical Level2</h4>
                    </td>
                    <td style={{ width: '15%', paddingLeft: "12px" }}>
                        <p> {technicalL2Name}</p>
                   
                            <p>{technicalL2Email},{technicalL2Phone}</p>

                    </td>


                </tr>
                <tr>

                        <td style={{ width: '15%', paddingLeft: "12px", backgroundColor: 'rgb(204, 204, 204)' }}>
                        <h4> Technical Level3</h4>
                    </td>
                    <td style={{ width: '15%', paddingLeft: "12px", background: '#CAEDFB' }}>
                        <p> {technicalL3Name}</p>
                      
                            <p> {technicalL3Email},{technicalL3Phone}</p>

                    </td>


                </tr>

                <tr>

                        <td style={{ width: '15%', paddingLeft: "12px", backgroundColor: 'rgb(204, 204, 204)' }}>
                        <h4> Commercial</h4>
                    </td>
                    <td style={{ width: '15%', paddingLeft: "12px" }}>
                        <p>{commercialName}</p>
                   
                            <p> {commercialEmail},{commercialPhone}</p>

                    </td>


                </tr>


            </table>
            </div>
            <div style={{ minHeight: '380px', marginTop: '2.7rem' ,paddingTop:'2.5rem'}}>
                <Grid style={{ paddingLeft: '3rem', paddingRight: '3rem' }} >
                    <p style={{ fontSize: '15px', fontWeight: '500' }}>Yours Sincerely</p>
                    <p style={{ fontSize: '15px', fontWeight: '500', marginTop: '-10px' }}> For Siemens Ltd</p>
                    <Grid style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: '50px' }}>
                        <Grid>
                            <div style={{ width: '100%', border: '1px solid black' }}></div>
                            <p style={{ fontSize: '16px', fontWeight: '500' }}>{comSign}</p>

                        </Grid>
                        <Grid>

                            <div style={{ textDecoration: 'underline', width: '100%', border: '1px solid black' }}></div>
                            <p style={{ fontSize: '16px', fontWeight: '500' }}>{techSign}</p>
                        </Grid>

                    </Grid>


                </Grid>
            </div>

            <Grid container spacing={2} className="newpage" style={{ width: '100%', borderTop: '2px solid black', marginTop: '0px' }}>

                <Grid container item xs={6} md={9}>
                    <Grid item xs={6} className="d-flex jc-flex-start">
                        <p style={{ fontSize: '12px' }}>Customer:</p>
                    </Grid>
                    <Grid item xs={6} className="d-flex jc-center">
                        <p style={{ fontSize: '12px' }}>{dynamic.customerName}</p>
                    </Grid>
                </Grid>
                <Grid item xs={6} md={3} className="d-flex jc-flex-end">
                    <p style={{ fontSize: '12px' }}>Confidential</p>
                </Grid>
            </Grid>

            {isLoading ? <Loader loaderText="Loading...!" /> : <></>}

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />

        </div >);

}
