import React, { useEffect, useState } from "react";
import { downloadACBReports } from "../../APIs/api_PoEntry";
import * as FileSaver from "file-saver";
import axios from "axios";
import DownloadIconOutlined from "@material-ui/icons/GetApp";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { refNameAction } from "../../Redux/Actions/actions";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Popper from '@material-ui/core/Popper';
import {
    TextField,
    Button,
    Grid,
    IconButton,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";
import {
    GetAddButton, tagsToBeReplaced

} from "../../Utilities/Utility";
import { DeleteOutlined } from "@material-ui/icons";

import TableCell from "@material-ui/core/TableCell";
//import "./customerPage.scss"
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'; import CloseIcon from "@material-ui/icons/Close";
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import {

    makeStyles,
    TableBody,
    ThemeProvider,
} from "@material-ui/core";
import {
    MTableBody,
    MTableGroupRow,
    MTableHeader,
    MTablePagination,
} from "material-table";
//import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useHistory } from "react-router-dom"
//import CloseIcon from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import MaterialTable from "material-table";
import Loader from "../../Components/Loader/Loader";
import { blue } from "@material-ui/core/colors";
import Customer_Template from "../../Common/CustomerMaster Template.xlsx";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../Themes/LightTheme";
import { useSelector, useDispatch } from "react-redux";
//import "../CreateQuotation/Create.scss";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { GetAllCustomerList, GetCustomerList, GetCustomerYear, getSave, getUpdate, CustomerAttachmentsBasedonId, deletePOEntryAttachments, deleteCTEntry, getCountryMaster, getState, getEngineerMaster, getOfficeMaster, getRegionMaster } from "../../APIs/api_Customer";
import { getCountry, getCustomerDetailsMaster, getReference, getPrepOfferTable, updateItemGrid, DelItemGrid, getItem, SaveItemGrid, SaveOfferGrid, getPlace, getAllStatus, getAllState, getAllTax, updateGrid, updateStatus, downloadCertificate, getContent, getDynamic, AddOfferAttachmentDetails, getUploadedFiles, deleteOfferAttachments } from "../../APIs/api_PoEntry";
import { getProduct, getOrder, getSector, getYear, getTermsConditions, getNotes, termsandConditionsPost, notesPost, uploadItemChoosen, getDownloadReport, geAllTypeReports } from "../../APIs/api_Insert";
import FormControl from "@material-ui/core/FormControl";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { BootstrapInput } from "../../Utilities/Utility";
import * as XLSX from "xlsx";
import Select from '@material-ui/core/Select';
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { MenuItem, Tooltip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    getRoundUptoXDecimals,
    tableCellCurrentFY,
    tableCellPreviousFY,
    tableIcons,
    renderValueWithThousandSeparator,
    tdInputCurrentFY,
    tdTotalInPut,
} from "../../Utilities/Utility";
import reviewLetters from "./PreviewLetter";
import Items_Upload_Template from "../../Common/Items Upload Template.xlsx";
import ItemsUploadTemplateED from "../../Common/Items Upload Template - ED.xlsx"


//import "./Offer.scss";



export default function PrepareOffer(props) {
    const dispatch = useDispatch();
    //console.log("props", props);
    //console.log("offerdata", props.editData.offerId);
    //const [offerDataTypeId, setofferDataTypeId] = useState(props.editData.offerId);
    const useStyles = makeStyles(() => ({
        paperSty: {
            padding: "40px",
            marginTop: "30px",
        },
        childGrid: {
            marginTop: "20px",
        },
        btnGrid: {
            justifyContent: "center",
            marginTop: "2rem",
        },
        tableCellPreviousFY: tableCellPreviousFY,
        tableCellCurrentFY: tableCellCurrentFY,
        tableCellTotal: tdTotalInPut,
    }));
    const classes = useStyles();
    const [fileValue, setfileValue] = useState("");
    let [errorData, setErrorData] = useState([]);
    const [rollHelper, setRollHelper] = useState("");
    const [poHelper, setPoHelper] = useState("");
    const token = useSelector((state) => state.saveTokenReducer.token);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const refNameData = useSelector((state) => state.referenceName);
    console.log("refNameData", refNameData);

    const UID = useSelector((state) => state.saveUserIdReducer);
    let history = useHistory();

    const [fileAttachments, setFileAttachments] = useState([]);
    const [epPoentryId, setEPPoentryId] = useState(null);
    const [data, setData] = useState([]);

    const [uploadRes, setUploadRes] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState("");
    const [isLoader, setIsLoader] = useState(false);
    let [totals, setTotals] = useState([
        {
            poValue_CustomerTestinomial: 0,

        },
    ]);
    //for snackBar
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [product, setProduct] = useState([]);
    const [productValue, setProductValue] = useState("");
    const [order, setOrder] = useState([]);
    const [orderValue, setOrderValue] = useState("");
    const [sector, setSector] = useState([]);
    const [sectorValue, setSectorValue] = useState("");
    const [year, setYear] = useState([]);
    const [yearValue, setYearValue] = useState("");
    const [yearId, setYearId] = useState(null);

    //End - snackbar
    const [openDia, setOpenDia] = useState(false);

    //const handleClickOpen = () => {
    //    setOpenDia(true);
    //};
    const [customer, setCustomer] = useState("");
    const [customerObj, setCustomerObj] = useState("");
    const [customerText, setCustomerText] = useState("");
    const [itemsCustomer, setItemsCustomer] = useState([]);
    const [errorCustomer, setErrorCustomer] = useState(false);
    const [errorTextCustomer, setErrorTextCustomer] = useState("");

    const handleChangeCustomer = (event, newValue) => {
        setCustomerObj(newValue);
        setCustomer(newValue ? newValue.epCustomerId : 0);
        console.log("Customer", newValue.epCustomerId);
        setCodeObj(newValue);
        setCode(newValue ? newValue.epCustomerId : 0);
    };
    const handleInputChange = (event, newInputValue) => {
        setCustomerText(newInputValue);
        //setCodeText(newInputValue);
        if (newInputValue.length >= 3) {
            //const token = localStorage.getItem("access_token");
            cust(newInputValue);
        }
    };
    const [code, setCode] = useState("");
    const [codeObj, setCodeObj] = useState("");
    const [codeText, setCodeText] = useState("");
    const [itemsCode, setItemsCode] = useState([]);
    const [errorCode, setErrorCode] = useState(false);
    const [errorTextCode, setErrorTextCode] = useState("");

    const handleChangeCode = (event, newValue) => {
        setCodeObj(newValue);
        setCode(newValue ? newValue.epCustomerId : 0);
        setCustomerObj(newValue);
        setCustomer(newValue ? newValue.epCustomerId : 0);
        console.log("Code", newValue.epCustomerId);
    };
    const handleChangeCodeInput = (event, newInputValue) => {
        setCodeText(newInputValue);
        // setCustomerText(newInputValue);
        if (newInputValue.length >= 3) {
            //const token = localStorage.getItem("access_token");
            cust(newInputValue);
        }
    };
    const handleCloseDia = () => {
        clear();
        setOpenDia(false);
    };
    //const [fileValue, setfileValue] = useState("");
    //const [selectedFile, setSelectedFile] = useState(null);
    //const [inputFileValue, setInputFileValue] = useState(Date.now);
    //string hooks
    //const [customer, setCustomer] = useState([]);
    const [customerValue, setCustomerValue] = useState("");
    const [customerId, setCustomerId] = useState(null);
    //const [code, setCode] = useState("");
    //const [country, setCountry] = useState("");
    const [countryName, setCountryName] = useState([]);
    const [countryVal, setCountryVal] = useState("");


    function countryValChange(e) {
        setCountryVal(e.target.value);
        //state(e.target.value);

    }
    function engineerValChange(e) {
        setEng(e.target.value);
        getOffice(e.target.value);
        getRegion(e.target.value);

    }

    const [stateName, setStateName] = useState([]);

    function stateValChange(e) {
        setStateVal(e.target.value);

    }
    const [stateVal, setStateVal] = useState("");
    const [regionName, setRegionName] = useState([]);
    const [region, setRegion] = useState("");
    const [officeName, setOfficeName] = useState([]);
    const [office, setOffice] = useState("");
    const [poNo, setPoNo] = useState("");
    const [engineerName, setEngineerName] = useState([]);
    const [eng, setEng] = useState("");
    const [poVal, setPoVal] = useState(0);
    const [soNo, setSoNo] = useState(null);
    const [fromPeriod, setFromPeriod] = useState(null);
    const api = axios.create({
        baseURL: "api/Customer",
    });
    const [formDialogTitle, setFormDialogTitle] = useState("Save");
    //ONCHANGES
    //const handleChangeCustomer = e => {
    //    setCustomerValue(e.target.value)
    //    setCustomerId(e.target.value)
    //}
    //const handleChangeCode = e => {
    //    setCode(e.target.value)
    //}
    const offerId = useSelector(
        (state) => state.authOffer.id

    );
    const yearIdSelected = useSelector(
        (state) => state.yearOffer.id

    );
    console.log("yearIdSelected", yearIdSelected);
    const [statesName, setStatesName] = useState("");

    const [type, setType] = useState("")

    const offerType = useSelector(
        (state) => state.offerType.types
    );
    const handleChangeState = e => {
        setStates(e.target.value)
        console.log(states)
        setStatesName(e.target.name)
    }
    const handleChangeRegion = e => {
        setRegion(e.target.value)

    }
    const handleChangeOffice = e => {
        setOffice(e.target.value)
    }
    const handleChangePoNo = e => {
        setPoNo(e.target.value)
    }
    //const handleChangeEng = e => {
    //    set(e.target.value)
    //}
    const handleChangePoVal = e => {
        setPoVal(e.target.value)
    }

    const handleChangeSoNo = e => {
        let valid;


        switch (e.target.id) {
            case "rollnumber":
                setSoNo(e.target.value)
                valid = /^[0-9]*$/.test(e.target.value);
                if (!valid) {
                    setRollHelper("please enter numbers only");
                    /*console.log("so",rollHelper)*/
                }
                else {
                    setRollHelper("");

                }
                break;
            case "po":
                setPoVal(e.target.value)
                valid = /^[0-9]*$/.test(e.target.value);
                if (!valid) {
                    setPoHelper("please enter numbers only");
                    /*console.log("so",rollHelper)*/
                }
                else {
                    setPoHelper("");

                }
                break;
            default:
                break;
        }

    }
    const handleToPeriod = (date) => {
        setFromPeriod(date);
    };
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const [offer, setOffer] = useState(null)
    const [approvalPending, setApprovalPending] = useState(null)
    const [approvalPendingL2, setApprovalPendingL2] = useState(null)
    const [approvalPendingL3, setApprovalPendingL3] = useState(null)
    const [approvalStatus, setApprovalStatus] = useState(null)
    const [approvalStatusL2, setApprovalStatusL2] = useState(null)
    const [approvalStatusL3, setApprovalStatusL3] = useState(null)
    const [comApprovalStatus, setComApprovalStatus] = useState(null)
    const [comApproval, setComApproval] = useState(null)
    const [prepId, setPrepId] = useState(null)
    const [statusprop, setStatusProp] = useState(null)
    const [flag, setFlag] = useState(null)
    const [refName, setRefName] = useState("")
    const [acceptedRemarks, setAcceptedRemarks] = useState();
    const [rejectedRemarks, setRejectedRemarks] = useState();
    const [commercialAcceptedRemarks, setCommercialAcceptedRemarks] = useState();
    const [commercialRejectedRemarks, setCommercialRejectedRemarks] = useState();
    const [ddNOtesEdit, setDdNOtesEdit] = useState();
    const [ddEdit, setDdEdit] = useState();
    const [techApproval, setTechApproval] = useState("");
    const [commercialApproval, setCommercialApproval] = useState("");
    const [notesStringData, setNotesStringData] = useState("");
    const [termsnConditionStringData, setTermsnConditionStringData] = useState("");
    useEffect(() => {
        //setYearValue(43);
        //setYearId(43);
        //GridData();
        //Setproduct();
        //Setsector();
        //Setorder();
        //Setyear();
        //country();
        ////handleClickOpen();
        ////state();
        ////cust();
        //getEngineer();
        console.log("props", props);
        if (props.Mode == "edit") {

            dialogDraftValuesFn(props.editData);
            //getRef(offer)
            getUploadedFilesContentOnLoad()
            getItemGrid(props.editData.offerReferenceNumberId)
            setRefName(props.editData.offerReferenceNumberName)
            dispatch(refNameAction(props.editData.offerReferenceNumberName));
            setCustName(props.editData.customerContactName)
            setPtitle(props.editData.projectTitle)
            setCustAdd(props.editData.customerContactAddress)
            setCustEmail(props.editData.customerContactEmail)
            setCustPhone(props.editData.customerContactPhone)
            setApprovalPending(props.editData.approvalPendingWithL1)
            setApprovalPendingL2(props.editData.approvalPendingWithL2)
            setApprovalPendingL3(props.editData.approvalPendingWithL3)
            setTechApproval(props.editData.approvalPendingWith)
            setApprovalStatus(props.editData.approvalStatusL1)
            setApprovalStatusL2(props.editData.approvalStatusL2)
            setApprovalStatusL3(props.editData.approvalStatusL3)
            setStatusProp(props.editData.statusId)
            setPrepId(props.editData.prepareOrderId)
            setAcceptedRemarks(props.editData.acceptedRemarks);
            setRejectedRemarks(props.editData.rejectedRemarks);
            setCommercialAcceptedRemarks(props.editData.commercialAcceptedRemarks);
            setCommercialRejectedRemarks(props.editData.commercialRejectedRemarks);
            setComApproval(props.editData.commercialApproval)
            setComApprovalStatus(props.editData.commercialApprovalStatus)
            setCommercialApproval(props.editData.commercialApprovalPendingWith)
            //setDdEdit(props.editData.termsNconditions);
            // setDdNOtesEdit(props.editData.notes);
            // setDataProp(props.editData.acceptedRemarks);

            //setDd(props.editData.termsNconditions);
            //setDdNOtes(props.editData.notes);
            setTermsnConditionStringData(props.editData.termsNconditions);
            setNotesStringData(props.editData.notes);
            setDialogSubmit("Update")
            setFlag(1);
            //setRef(props.editData.offerReferenceNumberName)
            dynamicReport(props.editData.offerReferenceNumberName)
            setPrevFlag(true)

        }
        else {
            console.log("offerelse", offerId);
            getRef(offerId)
            setFlag(0);
            setAcceptedRemarks(null);
            setRejectedRemarks(null);
            setCommercialAcceptedRemarks(null);
            setCommercialRejectedRemarks(null);
            termsConditionData();
            getNotesData();



        }
        Supply()
        State()
        Tax()
        Status()
        getcontents()



        if (offerId === 1) {
            setType("ACB Service")

        }
        else if (offerId === 2) {
            setType("ACB Spares")

        }
        else if (offerId === 3) {
            setType("Switchgear")

        }
        else if (offerId === 4) {
            setType("ACB Retrofit")

        }
        else if (offerId === 5) {
            setType("Panel Retrofit")

        }
        else if (offerId === 6) {
            setType("Panel Service")

        }
        else if (offerId === 7) {
            setType("EMS")

        }
        else if (offerId === 8) {
            setType("Temperature Monitoring")

        }
        else if (offerId === 9) {
            setType("i-upgrade")

        }


        console.log("offer", offerId)
        console.log("type", type);




    }, []);

    //useEffect(() => {
    //    termsConditionData();
    //    getNotesData();
    //}, [termsnConditionStringData]);
    const [prepID, setPrepID] = useState(null)
    const [isRevise, setIsRevise] = useState(false)
    const dialogDraftValuesFn = (rowData) => {
        console.log("Row Data", rowData);
        //(rowData.offerId)
        setOffer(rowData.offerId)
        setPrepID(rowData.prepareOrderId)
        //setDialogSubmitBtn("Update");
        //setOpenDialog(true);
        setRef(rowData.offerReferenceNumberName)
        setRefId(rowData.offerReferenceNumberId)
        //setCust(rowData.customerName)
        setCustomerObj(rowData.customerObject);
        setCustomer(rowData.customerObject ? rowData.customerObject.epCustomerId : 0);
        // setCustomerText(rowData.customerName1);
        setCustomerText(rowData.customerName);
        //setCustomerText(rowData.customerObject[0].customerCodeWithName)
        //  amount(rowData.amount)
        setStates(rowData.stateId)
        setStatus(rowData.statusId)
        setSelectedDate(rowData.estimateDate)
        setValDate(rowData.validityDate)
        setCal(rowData.amount)
        setCheckDesc(rowData.selectedItemsVM.description)
        setCheckItem(rowData.selectedItemsVM.mlfb)
        setCheckTaxRate(rowData.selectedItemsVM.taxRate)
        setCheckUnitLP(rowData.selectedItemsVM.rate)
        setCheckDiscount(rowData.selectedItemsVM.discountPercentage)
        setCheckDiscA(rowData.selectedItemsVM.discountInAmount)
        setCheckTotalTax(rowData.selectedItemsVM.netTaxValue)
        setCheckTotalDisc(rowData.selectedItemsVM.totalDiscount)
        setCheckUnitPrice(rowData.selectedItemsVM.unitPriceKp)
        setCheckDiscQty(rowData.selectedItemsVM.discountPerQty)
        // igst(rowData.igst)
        //sgst(rowData.sgst)
        //cgst(rowData.cgst)
        setDisc(rowData.discountPercentage)
        setDiscA(rowData.discount)
        // adjust(rowData.adjustment)
        //  tott(rowData.total)
        setOthers(rowData.otherCharges)
        setAdj(rowData.adjustment)
        setSupply(rowData.placeOfSupplyId)
        setIsRevise(rowData.isRevised)
        setPtitle(rowData.projectTitle);

    };
    const [checkDesc, setCheckDesc] = useState(false)
    const [checkItem, setCheckItem] = useState(false)
    const [checkQty, setCheckQty] = useState(true)
    const [checkTaxRate, setCheckTaxRate] = useState(false)
    const [checkUnitLP, setCheckUnitLP] = useState(false)
    const [checkDiscount, setCheckDiscount] = useState(false)
    const [checkTotal, setCheckTotal] = useState(true)
    const [checkDiscA, setCheckDiscA] = useState(false)
    const [checkTotalTax, setCheckTotalTax] = useState(false)
    const [checkTotalDisc, setCheckTotalDisc] = useState(false)
    const [checkUnitPrice, setCheckUnitPrice] = useState(false)
    const [checkDiscQty, setCheckDiscQty] = useState(false)




    const handleChangeCheckDesc = (event) => {
        setCheckDesc(event.target.checked);
    };
    const handleChangeCheckItem = (event) => {
        setCheckItem(event.target.checked);
    };
    const handleChangeCheckQty = (event) => {
        setCheckQty(event.target.checked);
    };
    const handleChangeCheckTaxRate = (event) => {
        setCheckTaxRate(event.target.checked);
    };
    const handleChangeCheckUnit = (event) => {
        setCheckUnitLP(event.target.checked);
    };
    const handleChangeCheckDiscount = (event) => {
        setCheckDiscount(event.target.checked);
    };
    const handleChangeCheckTotal = (event) => {
        setCheckTotal(event.target.checked);
    };
    const handleChangeCheckTotalTax = (event) => {
        setCheckTotalTax(event.target.checked);
    };
    const handleChangeCheckDiscA = (event) => {
        setCheckDiscA(event.target.checked);
    };
    const handleChangeCheckTotalDisc = (event) => {
        setCheckTotalDisc(event.target.checked);
    };
    const handleChangeCheckUnitPrice = (event) => {
        setCheckUnitPrice(event.target.checked);
    };
    const handleChangeCheckDiscQty = (event) => {
        setCheckDiscQty(event.target.checked);
    };

    const [ref, setRef] = useState("");
    const [refId, setRefId] = useState(null)
    const [content, setContent] = useState([])
    const [allLetterTypeData, setAllLetterTypeData] = useState([]);
    const [dynamic, setDynamic] = useState([]);
    function getcontents() {
        setIsLoading(true);
        getContent(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,)
            .then((response) => {
                console.log("content", response);
                setAllLetterTypeData(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function dynamicReport(ref) {
        // setIsLoading(true);
        //if (props!=null) {
        //    setRef(props.editData.offerReferenceNumberName)
        //}
        //let form = {
        //    Description: checkDesc,
        //    Qty: checkQty,
        //    Rate: checkUnitLP,
        //    Mlfb: checkItem,
        //    DiscountPercentage: checkDiscount,
        //    TaxRate: checkTaxRate,
        //    NetValue: checkTotal,
        //}
        getDynamic(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, ref)
            .then((response) => {
                console.log("content1", response);
                setDynamic(response.objResult[0]);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }

    const handlePrev = () => {

        setIsLoading(true);
        let formData = {

            // PrepareOrderId: offerId,
            Content: allLetterTypeData,
            Type: "ACB Spares",
            IsActive: true,
            CreatedBy: userDetails.userId,


        }

        downloadCertificate(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                if (response.response) {


                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoading(false);


                } else {


                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                }
            })
            .catch((error) => {


                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });

    }
    //preview
    // let [allLetterTypeData, setAllLetterTypeData] = useState([]);

    function downloadACBReport() {
        //setIsLoading(true);
        downloadACBReports(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, dynamic.offerReferenceNumberName)

            .then((response) => {
                console.log("download", response);
                //setIsLoading(true);
                if (response.status === 200) {
                    if (response.data.byteLength <= 200) {
                        setIsLoading(false);
                        handleSnackOpen("No Records are Found", "success");
                    } else {

                        const fileName = dynamic.offerReferenceNumberName + "_ACBSpares";
                        //const fileName = customer + "_SilverReport";
                        var blob = new Blob([response.data], {
                            type: "application/pdf",
                        });
                        FileSaver.saveAs(blob, fileName + ".pdf");
                        setIsLoading(false);
                        handleSnackOpen("Report downloaded.", "success");
                    }
                } else {
                    setIsLoading(false);
                    handleSnackOpen("Report not Available to Download.", "error");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen("Error while downloading Report.", "error");
            })
    }

    function handlePreview(e, type) {
        console.log("data", allLetterTypeData)
        //    if (flag === 1) { 
        //        dynamicReport(refName)
        //}
        //else {
        //    dynamicReport()
        //}
        switch (type) {

            case "ACB Spares":
                localStorage.setItem(
                    "LetterHtml",
                    getHTML(allLetterTypeData, type)?.__html
                );
                localStorage.setItem(
                    "LetterPdf",
                    getHTML(allLetterTypeData, type)?.__pdf
                );
                //localStorage.setItem(
                //    "Type",
                //    "Offer"
                //);
                //localStorage.setItem("UserId", storedInputDataDHL.user);
                ////localStorage.setItem("UserId", props?.userIdencrypted);
                //localStorage.setItem("ApplicantId", storedInputDataDHL?.applicantId);
                /* localStorage.setItem("routePath", path);*/
                window.open("/PreviewPage", "preview");
                /* window.open("/PreviewLetters", "page");*/
                // window.open("/PreviewLetters", "_self");
                break;
            default:
                break;
        }
    }
    function getHTML(data, type) {

        let html =
            type === "ACB Spares"
                ? data?.find(
                    (x) => x.reportTypeName === "ACB Spares"
                )?.offerContent
                : data?.find(
                    (x) => x.reportTypeName === "ACB Spares"
                )?.offerPdfContent

        //let pdf = type === "ACB Spares"
        //    ? data?.find(
        //        (x) => x.reportTypeName === "ACB Spares"
        //    )?.offerContent
        //    : data?.find(
        //        (x) => x.reportTypeName === "ACB Spares"
        //    )?.offerPdfContent

        return {
            __html: replaceTags(html),
            //    __pdf: replaceTags(pdf),
        };
    }
    function replaceTags(value) {
        console.log("dynamic", dynamic)
        console.log("itemsobj", dynamic.itemObject)
        tagsToBeReplaced.forEach((c) => {
            let count = value.match(new RegExp(c, "gi") || [])?.length;
            if (count > 0) {
                for (let i = 0; i < count; i++) {
                    switch (c) {
                        case "{ProposalNo}":
                            value = value?.replace(c, dynamic.offerReferenceNumberName);
                            break;
                        case "{Customer}":
                            value = value?.replace(c, dynamic.customerName);
                            break;
                        case "{Rev}":
                            value = value?.replace(c, dynamic.version);
                            break;
                        case "{tech}":
                            value = value?.replace(c, (dynamic.version == "R0") ? "Commercial Proposal" : "Revised Commercial Proposal"
                            );
                            break;
                        case "{Date}":
                            value = value?.replace(c, dynamic.offerCreatedDate);
                            break;
                        case "{Status}":
                            value = value?.replace(c, dynamic.status);
                            break;
                        case "{Name}":
                            value = value?.replace(c, dynamic.createdByName);
                            break;
                        //case "{item}":
                        //    value = value?.concat(c,dynamic.itemObject);
                        //    break;

                        case "{CustomerName}":
                            value = value?.replace(c, (dynamic.customerObject != null) ?
                                ((dynamic.customerObject.length > 0) ?
                                    dynamic.customerObject[0].customerName1 : "NA")
                                : "");
                            break;
                        case "{CustomerAddress}":
                            value = value?.replace(c, (dynamic.customerObject != null) ?
                                ((dynamic.customerObject.length > 0) ?
                                    dynamic.customerObject[0].street : "NA")
                                : "");
                            break;
                        case "{CustomerEmail}":
                            value = value?.replace(c, (dynamic.customerObject != null) ?
                                ((dynamic.customerObject.length > 0) ?
                                    dynamic.customerObject[0].emailId : "NA")
                                : "");
                            break;
                        case "{CustomerPhone}":
                            value = value?.replace(c, (dynamic.customerObject != null) ?
                                ((dynamic.customerObject.length > 0) ?
                                    dynamic.customerObject[0].mobile : "NA")
                                : "");
                            break;
                        case "{SiemensName}":
                            value = value?.replace(c, (dynamic.siemensSalesContactObject != null) ? ((dynamic.siemensSalesContactObject.userFirstName != null) ? dynamic.siemensSalesContactObject.userFirstName : "NA")
                                : "");
                            break;
                        case "{SiemensAddress}":
                            value = value?.replace(c, (dynamic.siemensSalesContactObject != null) ? ((dynamic.siemensSalesContactObject.region != null) ?
                                dynamic.siemensSalesContactObject.region : "NA")
                                : "");
                            break;
                        case "{SiemensMail}":
                            value = value?.replace(c, (dynamic.siemensSalesContactObject != null) ? ((dynamic.siemensSalesContactObject.userEmail != null) ?
                                dynamic.siemensSalesContactObject.userEmail : "NA")
                                : "");
                            break;
                        case "{SiemensPhone}":
                            value = value?.replace(c, (dynamic.siemensSalesContactObject != null) ? ((dynamic.siemensSalesContactObject.contactNumber != null) ?
                                dynamic.siemensSalesContactObject.contactNumber : "NA")
                                : "");
                            break;
                        case "{Qty}":
                            value = value?.replace(c, (dynamic.itemObject != null) ?
                                ((dynamic.itemObject.length > 0) ?
                                    dynamic.itemObject[0].qty : "NA")
                                : "");
                            break;
                        case "{Unit}":
                            value = value?.replace(c, (dynamic.itemObject != null) ?
                                ((dynamic.itemObject.length > 0) ?
                                    dynamic.itemObject[0].rate : "NA")
                                : "");
                            break;
                        case "{Final}":
                            value = value?.replace(c, (dynamic.itemObject != null) ?
                                ((dynamic.itemObject.length > 0) ?
                                    dynamic.itemObject[0].netValue : "NA")
                                : "");
                            break;
                        case "{Words}":
                            value = value?.replace(c, dynamic.totalRupees);
                            break;
                        case "{TechnicalName1}":
                            value = value?.replace(c, (dynamic.groupDetailsObject != null) ?
                                ((dynamic.groupDetailsObject.l1.length > 0) ?
                                    dynamic.groupDetailsObject.l1[0].userFirstName : "NA")
                                : "");
                            break;
                        case "{TechnicalPhone1}":
                            value = value?.replace(c, (dynamic.groupDetailsObject != null) ?
                                ((dynamic.groupDetailsObject.l1.length > 0) ?
                                    dynamic.groupDetailsObject.l1[0].contactNumber : "NA")
                                : "");
                            break;
                        case "{TechnicalEmail1}":
                            value = value?.replace(c, (dynamic.groupDetailsObject != null) ?
                                ((dynamic.groupDetailsObject.l1.length > 0) ?
                                    dynamic.groupDetailsObject.l1[0].userEmail : "NA")
                                : "");
                            break;
                        //case "{TechnicalName2l1}":
                        //    value = value?.replace(c, dynamic.groupDetailsObject.l1[1].userFirstName);
                        //    break;
                        //case "{TechnicalPhone2l1}":
                        //    value = value?.replace(c, dynamic.groupDetailsObject.l1[1].contactNumber);
                        //    break;
                        //case "{TechnicalEmail2l1}":
                        //    value = value?.replace(c, dynamic.groupDetailsObject.l1[1].userEmail);
                        //    break;
                        //case "{TechnicalName3l1}":
                        //    value = value?.replace(c, dynamic.groupDetailsObject.l1[2].userFirstName);
                        //    break;
                        //case "{TechnicalPhone3l1}":
                        //    value = value?.replace(c, dynamic.groupDetailsObject.l1[2].contactNumber);
                        //    break;
                        //case "{TechnicalEmail3l1}":
                        //    value = value?.replace(c, dynamic.groupDetailsObject.l1[2].userEmail);
                        //    break;
                        case "{TechnicalName2}":
                            value = value?.replace(c, (dynamic.groupDetailsObject != null) ?
                                ((dynamic.groupDetailsObject.l2.length > 0) ?
                                    dynamic.groupDetailsObject.l2[0].userFirstName : "NA")
                                : "");
                            break;
                        case "{TechnicalPhone2}":
                            value = value?.replace(c, (dynamic.groupDetailsObject != null) ?
                                ((dynamic.groupDetailsObject.l2.length > 0) ?
                                    dynamic.groupDetailsObject.l2[0].contactNumber : "NA")
                                : "");
                            break;
                        case "{TechnicalEmail2}":
                            value = value?.replace(c, (dynamic.groupDetailsObject != null) ?
                                ((dynamic.groupDetailsObject.l2.length > 0) ?
                                    dynamic.groupDetailsObject.l2[0].userEmail : "NA")
                                : "");
                            break;
                        case "{TechnicalName3}":
                            value = value?.replace(c, (dynamic.groupDetailsObject != null) ?
                                ((dynamic.groupDetailsObject.l3.length > 0) ?
                                    dynamic.groupDetailsObject.l3[0].userFirstName : "NA")
                                : "");
                            break;
                        case "{TechnicalPhone3}":
                            value = value?.replace(c, (dynamic.groupDetailsObject != null) ?
                                ((dynamic.groupDetailsObject.l3.length > 0) ?
                                    dynamic.groupDetailsObject.l3[0].contactNumber : "NA")
                                : "");
                            break;
                        case "{TechnicalEmail3}":
                            value = value?.replace(c, (dynamic.groupDetailsObject != null) ?
                                ((dynamic.groupDetailsObject.l3.length > 0) ?
                                    dynamic.groupDetailsObject.l3[0].userEmail : "NA")
                                : "");
                            break;
                        case "{CommercialName}":
                            value = value?.replace(c, (dynamic.groupDetailsObject != null) ?
                                ((dynamic.groupDetailsObject.commercial.length > 0) ?
                                    dynamic.groupDetailsObject.commercial[0].userFirstName : "NA")
                                : "");
                            break;
                        case "{CommercialPhone}":
                            value = value?.replace(c, (dynamic.groupDetailsObject != null) ?
                                ((dynamic.groupDetailsObject.commercial.length > 0) ?
                                    dynamic.groupDetailsObject.commercial[0].contactNumber : "NA")
                                : "");
                            break;
                        case "{CommercialEMail}":
                            value = value?.replace(c, (dynamic.groupDetailsObject != null) ?
                                ((dynamic.groupDetailsObject.commercial.length > 0) ?
                                    dynamic.groupDetailsObject.commercial[0].userEmail : "NA")
                                : "");
                            break;



                        default:
                            value = value;
                            break;
                    }
                }
            }
        });
        return value;
    }
    function getRef(offerId) {
        setIsLoading(true);
        getReference(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, offerId)
            .then((response) => {
                console.log("ResponseRef", response)
                setRef(response.objResult.offerReferenceNumberName);

                dispatch(refNameAction(response.objResult.offerReferenceNumberName));
                setRefId(response.objResult.offerReferenceNumberId)
                getItemGrid(response.objResult.offerReferenceNumberId)
                getUploadedFilesContentOnLoad(response.objResult.offerReferenceNumberName)
                dynamicReport(response.objResult.offerReferenceNumberName)
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })


    }
    const [gridData, setGridData] = useState([])
    const [cal, setCal] = useState(0)
    //getDownloadReport


    function download() {
        setIsLoader(true);

        geAllTypeReports(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, ref)
            .then((response) => {
                console.log(response)

                //setfileurl(response.fileUrl)
                //files(rowData)
                setIsLoader(false);

                response.fileUrl != null || response.fileUrl != "" ? window.open(response.fileUrl, "_blank") : handleSnackOpen("Error while downloading Report.", "error");

            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen("Error while downloading Report.", "error");
            })
    }
    function reportDownload() {
        setIsLoader(true);
        getDownloadReport(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, ref)
            .then((response) => {
                console.log("downloadReport", response);
                //setGridData(response.objResult.itemList)
                //setCal(response.objResult.gridTotal)
                //setDisc(response.objResult.gridTotalDiscountPercentage)
                //setDiscA(response.objResult.gridTotalDiscountAmount)
                //console.log("cal", cal)
                setIsLoader(false);
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen(error, "error");
            })


    }
    function getItemGrid(refId) {
        setIsLoader(true);
        getPrepOfferTable(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, refId)
            .then((response) => {
                console.log("item table", response)
                setGridData(response.objResult.itemList)
                setCal(response.objResult.gridTotal)
                setDisc(response.objResult.gridTotalDiscountPercentage)
                setDiscA(response.objResult.gridTotalDiscountAmount)
                // cgst = parseFloat(cgst.toFixed(2));
                //Math.round(l * 100 / 100)
                console.log("cal", cal)
                setIsLoader(false);
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen(error, "error");
            })


    }
    const handleAccept = () => {
        //setFormDialogTitle("Save");
        setOpenRemarks(true)

        //handleAcceptRemarks()
    }
    const handleReject = () => {
        //setFormDialogTitle("Save");
        setOpenRejectRemarks(true)

        //handleAcceptRemarks()
    }


    const handleAcceptRemarks = () => {
        //setFormDialogTitle("Save");
        let formData = {}
        if (comApproval === userDetails.userId) {
            formData = {
                PrepareOrderId: prepId,
                CommercialApproval: comApproval,
                commercialApprovalStatus: 2,
                StatusId: statusprop,
                // CreatedBy: userDetails.userId,
                ModifiedBy: userDetails.userId,
                commercialAcceptedRemarks: textauto,
            }
        }
        else {
            formData = {

                PrepareOrderId: prepId,
                ApprovalPendingWithL1: approvalPending,
                ApprovalStatusL1: 2,
                ApprovalStatusL2: 2,
                ApprovalStatusL3: 2,
                StatusId: statusprop,
                // CreatedBy: userDetails.userId,
                ModifiedBy: userDetails.userId,
                AcceptedRemarks: textauto,
                //TermsNconditions: dd,//dd-->termsnConditionStringData
                //Notes: ddnotes,//ddnotes--->notesStringData

            }
        }
        updateStatus(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                console.log("accept", response)
                if (response.response) {

                    // handleCloseDia()
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoading(false);
                    handleCloseRemarks()
                    window.location.href = "./offer"
                    //setOpenRemarks(true)
                    //getItemGrid(refId)
                    //getUsers()

                } else {

                    // handleCloseDia()

                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                    handleCloseRemarks()
                }
            })
            .catch((error) => {
                // handleCloseDia()

                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });

    }
    const handleRejectRemarks = () => {
        let formData = {}
        //setFormDialogTitle("Save");

        if (comApproval === userDetails.userId) {
            formData = {
                PrepareOrderId: prepId,
                CommercialApproval: comApproval,
                commercialApprovalStatus: 3,
                StatusId: statusprop,
                // CreatedBy: userDetails.userId,
                ModifiedBy: userDetails.userId,
                CommercialRejectedRemarks: textautoReject,
            }
        }
        else {
            formData = {

                PrepareOrderId: prepId,
                ApprovalPendingWithL1: approvalPending,
                ApprovalStatusL1: 3,
                ApprovalStatusL2: 3,
                ApprovalStatusL3: 3,
                StatusId: statusprop,
                // CreatedBy: userDetails.userId,
                ModifiedBy: userDetails.userId,
                RejectedRemarks: textautoReject,
            }
        }

        updateStatus(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                console.log("reject", response)
                if (response.response) {

                    // handleCloseDia()
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoading(false);
                    handleCloseRejectRemarks()
                    window.location.href = "./offer"
                    //getItemGrid(refId)
                    //getUsers()

                } else {

                    // handleCloseDia()
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                    handleCloseRejectRemarks()
                }
            })
            .catch((error) => {
                // handleCloseDia()

                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });

    }
    //desc
    const [description, setDescription] = useState("");
    const [descEdit, setDescEdit] = useState("");
    const [descObj, setDescObj] = useState("");
    const [descText, setDescText] = useState("");
    //const [itemsCustomer, setItemsCustomer] = useState([]);
    //const [errorCustomer, setErrorCustomer] = useState(false);
    // const [errorTextCustomer, setErrorTextCustomer] = useState("");
    const handleChangeDescEdit = (e) => {
        setDescEdit(e.target.value)
    }
    const handleChangeDesc = (event, newValue) => {
        setDescObj(newValue);
        setDescription(newValue ? newValue.priceMasterId : 0);
        //console.log("Customer", newValue.epCustomerId);
        setItemObj(newValue);
        setRate(newValue.unitLp)

        setItem(newValue ? newValue.priceMasterId : 0);

    };
    const handleChangeDescInput = (event, newInputValue) => {
        setDescText(newInputValue);
        setDescEdit(newInputValue);
        //setCodeText(newInputValue);
        if (newInputValue.length >= 3) {
            //const token = localStorage.getItem("access_token");
            getItemQty(newInputValue);
        }

    };
    const [item, setItem] = useState("");
    const [itemObj, setItemObj] = useState("");
    const [itemText, setItemText] = useState("");
    const [items, setItems] = useState([]);
    const [Desc, setDesc] = useState([]);
    //const [errorCode, setErrorCode] = useState(false);
    // const [errorTextCode, setErrorTextCode] = useState("");
    const handleChangeItem = (event, newValue) => {
        setItemObj(newValue);
        setItem(newValue ? newValue.priceMasterId : 0);
        setRate(newValue.unitLp)
        setDescObj(newValue);
        setDescription(newValue ? newValue.priceMasterId : 0);
        //console.log("Code", newValue.epCustomerId);
    };
    const handleChangeItemInput = (event, newInputValue) => {
        setItemText(newInputValue);
        // setCustomerText(newInputValue);
        if (newInputValue.length >= 3) {
            //const token = localStorage.getItem("access_token");
            getItemQty(newInputValue);
        }
        //getItemQty(newInputValue);
    };
    const [rate, setRate] = useState(0);
    const [qty, setQty] = useState(0);
    const [account, setAccount] = useState("");
    const [taxRate, setTaxRate] = useState(null);
    const [tot, setTot] = useState(null);
    const handleChangeQty = (e) => {
        setQty(e.target.value)
    }
    const handleChangeAccount = (e) => {
        setAccount(e.target.value)
    }
    //const handleChangeTax = (e) => {
    //    setTaxRate(e.target.value)
    //}
    const handleChangeTot = (e) => {
        setTot(e.target.value)
    }
    const handleChangeSubtotal = (e) => {
        setCal(e.target.value)
    }

    function getItemQty(value) {
        //setIsLoading(true);
        getItem(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, offerId, value)
            .then((response) => {
                console.log("auto", response)
                setDesc(response);
                setItems(response);

                //setOptions(response);
                console.log("cst code", response);
                // setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };


    const handleDialogMessage = (text) => {
        setDialogContent(text);
        setDialogOpen(true);
    };

    const handleCloseData = () => {
        setDialogOpen(false);
        setErrorData([]);
        getItemGrid(refId)
    }
    //const handleClose = (e, reason) => {
    //    if (reason === "backdropClick") {
    //        return;
    //    }
    //    if (
    //        typeof dialogContent === "string" &&
    //        dialogContent.toLowerCase().includes("success")
    //    ) {
    //        setInputFileValue(Date.now);
    //        setSelectedFile(null);
    //    }
    //    setDialogOpen(false);
    //    setInputFileValue(Date.now);
    //    setErrorData([]);
    //};
    const handleClose = (e) => {
        setOpen(false);
    };
    const exportErrorData = () => {
        ExportCustomerErrorDataToExcel();
    };
    //function ExportCustomerErrorDataToExcel() {
    //    console.log("test");
    //}
    function ExportCustomerErrorDataToExcel() {
        console.log("test");
        const dataToDownload = errorData.map((row) => {
            return {

                //quotationId: row.quotationId,
                //cutomerPo: row.customerPo,
                //ourRef: row.ourRef,

                //idNo: row.idNo,

                //remarks: row.remarks?.replace(".", ".\n "),
                itemId: row.itemId,
                mlfb: row.mlfb,
                materialCode: row.materialCode,
                qty: row.qty,
                remarks: row.remarks?.replace(".", ".\n "),


            };
        });
        let headers = ["Item Number", "MLFB No", "Material Number", "Quantity",
            "Remarks",

        ];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
            origin: "A2",
            skipHeader: true,
            cellStyles: true,
        });
        ws["!cols"] = cellAdjustToContentsCustomer(dataToDownload);
        ws['!cols'] = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 150 }];
        XLSX.utils.book_append_sheet(wb, ws, "ErrorData");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(wb, "ExcelUploadErrors.xlsx");
    }

    function cellAdjustToContentsCustomer(data) {
        return [
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.quotationId ? c.quotationId?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.customerPo ? c.customerPo?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.idNo ? c.idNo?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.remarks ? c.remarks?.toString()?.length : 0))
                ),
            },
        ];
    }


    const [selectedFile, setSelectedFile] = useState(null);
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const fileUploadHandler = (event) => {
        const chosenFiles = Array.prototype.slice.call(event.target.files);
        setSelectedFile(chosenFiles);
        console.log("SelectedFile", event.target.files);
    };
    var attachColumns = [
        {
            title: "File Name",
            field: "filename"
        },
        {
            title: "Download",
            render: rowData => renderFileDownload(rowData)
        },
        {
            title: "Delete",
            render: rowData => renderFileDelete(rowData)
        },
    ];
    function renderFileDownload(rowData) {
        return (
            <Button
                href={rowData.fileUrlWithSasToken}
                color="transparent"
                target="_blank"
                download
            >
                <Tooltip title="Download Attachment">
                    <DownloadIconOutlined />
                </Tooltip>
            </Button>);
    }
    function deleteAttachment(rowData) {
        deletePOEntryAttachments(token, rowData)
            .then((response) => {
                handleSnackOpen(response.responseMsg, "success");
                getPOAttachments(token, rowData.id, userDetails.userId);
            })
            .catch((error) => {
                handleSnackOpen(error, "error");
            });
    }
    function renderFileDelete(rowData) {
        const rowD = rowData;
        return (
            <Button
                color="transparent"
                onClick={() => deleteAttachment(rowD)}
            >
                <Tooltip title="Delete Attachment">
                    <DeleteOutlineOutlinedIcon />
                </Tooltip>
            </Button>);
    }
    const columns = [
        {

            title: "File Status",

            field: "filestat",

            //hidden: selectedModule === 1 ? true : false,

            render: (rowData) =>

                rowData.ctattachmentlist.length === 0 ? "" : <InsertDriveFileIcon />,

        },
        { title: "Customer", field: "customerName" },
        {
            title: "Code", field: "code"
        },
        { title: "Country", field: "country" },
        { title: "State", field: "state" },
        { title: "Sector", field: "sectorName" },
        { title: "Region", field: "region" },
        { title: "Office", field: "office" },
        { title: "PO Number", field: "ponumber" },
        { title: "Engineer", field: "engineer" },
        {
            title: "PO Dated", field: "podated", type: "date",

            dateSetting: { locale: "en-GB" },
        },
        { title: "Product", field: "productName" },
        { title: "PO Value", field: "povalue" },
        { title: "Order Type", field: "orderName" },
        { title: "SO Number", field: "sonumber" },
        //        {
        //            title: "Documents Uploads", field: "doc", editable: false,
        //            render: (rowData) => (
        //                <Button>
        //                    <CloudUploadIcon
        //                        className="checkIcon"
        //                        //onClick={() => handleCertFileDownload(rowData)}
        //                    />
        //                </Button>
        //            ),
        //},
    ];
    const [custDialogTitle, setCustDialogTitle] = useState("Add Row");
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const handleAlertClose = () => {
        setSelectedFile(null);
        setOpenAlertDialog(false);
        setIsLoading(false);
    };
    const [alertMessage, setAlertMessage] = useState("");
    const handleAlertOpen = (message) => {
        setOpenAlertDialog(true);
        setAlertMessage(message);
    };
    const handleErrorClose = () => {
        setOpenErrorDialog(false);
    };
    const handleYear = (e) => {
        setYearValue(e.target.value);
        setYearId(e.target.value);


    }
    const PopperEdit = function (props) {
        return (<Popper {...props} style={{ paddingTop: "30px", marginLeft: "50px", width: 290 }} placement='bottom' />)
    }
    const PopperCST = function (props) {
        return (<Popper {...props} style={{ paddingTop: "14px", marginLeft: "20px", width: 440 }} placement='bottom' />)
    }

    const PopperAdd = function (props) {
        return (<Popper {...props} style={{ paddingTop: "80px", marginLeft: "100px", width: 290 }} placement='bottom' />)
    }
    const PopperNewAdd = function (props) {
        return (<Popper {...props} style={{ paddingTop: "112px", marginLeft: "90px", width: 290 }} placement='bottom' />)
    }
    //dropdowns
    const [id, setId] = useState(null);
    const [sectorId, setSectorId] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [productId, setProductId] = useState(null);
    const handleSector = (event) => {
        setSectorValue(event.target.value);
        setSectorId(event.target.value);



    };
    const handleProduct = (event) => {
        console.log("productvalue", event.target.value);
        setProductValue(event.target.value);
        setProductId(event.target.value);


    };
    const handleOrder = (event) => {
        setOrderValue(event.target.value);
        setOrderId(event.target.value);


    };
    //function submitValueDetails() {
    //    const formData = new FormData();
    //    formData.append("file", selectedFile);
    //    formData.append("UserId", userDetails.userId);
    //    CustomerDataUpload(formData, token)
    //        .then((response) => {
    //            console.log(response);
    //            if (response.response) {
    //                setIsLoader(false);
    //                GridData();
    //                handleDialogMessage(response.responseMsg);
    //            } else {
    //                setIsLoader(false);
    //                setSelectedFile(null);
    //                setInputFileValue(Date.now);
    //                setErrorData(response.objResult);
    //                handleDialogMessage(
    //                    response.responseMsgs && response.responseMsgs.length > 0
    //                        ? response.responseMsgs
    //                        : response.responseMsg
    //                );
    //            }
    //        })
    //        .catch((error) => {
    //            setIsLoader(false);
    //            setSelectedFile(null);
    //            setInputFileValue(Date.now);
    //            handleDialogMessage("Exception in upload");
    //        });
    //}
    function country() {
        setIsLoading(true);
        getCountryMaster(token, userDetails.userId)
            .then((response) => {
                console.log("country", response);
                setCountryName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    //function state(countryid) {
    //    setIsLoading(true);
    //    getState(token, userDetails.userId, countryid)
    //        .then((response) => {
    //            console.log("state", response);
    //            setStateName(response);
    //            //setCode(response);
    //            setIsLoading(false);
    //        })
    //        .catch((error) => {
    //            setIsLoading(false);
    //            handleSnackOpen(error, "error");
    //        })
    //}
    function getEngineer() {
        setIsLoading(true);
        getEngineerMaster(token, userDetails.userId)
            .then((response) => {
                console.log("eng", response);
                setEngineerName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function getOffice(EngineerId) {
        setIsLoading(true);
        getOfficeMaster(token, userDetails.userId, EngineerId)
            .then((response) => {
                console.log("office", response);
                setOfficeName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function getRegion(EngineerId) {
        setIsLoading(true);
        getRegionMaster(token, userDetails.userId, EngineerId)
            .then((response) => {
                console.log("region", response);
                setRegionName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function Setproduct() {
        console.log("hi");
        setIsLoading(true);

        getProduct(token, userDetails.userId,)
            .then((response) => {
                console.log(response);

                setProduct(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    function Setorder() {
        console.log("hi");
        setIsLoading(true);

        getOrder(token, userDetails.userId,)
            .then((response) => {
                console.log(response);

                setOrder(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    function Setsector() {
        console.log("hi");
        setIsLoading(true);

        getSector(token, userDetails.userId,)
            .then((response) => {
                console.log(response);

                setSector(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    function Setyear() {
        console.log("hi");
        setIsLoading(true);

        getYear(token, userDetails.userId,)
            .then((response) => {
                console.log(response);

                setYear(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    function SetCustyear() {
        console.log("hi");
        setIsLoading(true);

        GetCustomerYear(token, userDetails.userId, yearId)
            .then((response) => {
                console.log(response);

                setData(response.listCustomerTestimonial);
                setTotals([{ ...response.totals }]);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    function cust(value) {
        getCustomerDetailsMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, value)
            .then((response) => {
                console.log("customercode", response);
                setItemsCustomer(response);
                setItemsCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            });
    }
    function GridData() {
        setIsLoader(true);

        GetCustomerList(token, userDetails.userId)
            .then((response) => {
                console.log("hi");
                console.log("grid data", response);

                setData(response.listCustomerTestimonial);
                setTotals([{ ...response.totals }]);
                console.log("totals", totals);
                setIsLoader(false)
            })

            .catch((error) => {
                setIsLoader(false)
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
            });
    }

    const tncEdit = (e) => {
        console.log("tnc", e.target.value);
        setDd(e.target.value);
        setTermsnConditionStringData(e.target.value);
    }

    const notesEdit = (e) => {
        console.log("note", e.target.value);
        setDdNOtes(e.target.value);
        setNotesStringData(e.target.value);
    }
    //getTermsConditions
    const [tncData, settncData] = useState([]);
    const [dd, setDd] = useState();
    console.log("dd", dd);
    //

    function termsConditionData1(offerId) {
        setIsLoader(true);

        getTermsConditions(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, offerId)
            .then((response) => {

                console.log("gettermandcondition", response);
                console.log("obj", response.objResult);
                //if (response && response.objResult) {
                //    settncData(response.objResult);
                //}
                if (response.objResult.length > 0) {

                    let ddData = response.objResult.map((x) => x.termsAndConditions);
                    console.log("ddData", ddData);
                    let x = ddData.toString();
                    console.log("x", x);
                    setTermsnConditionStringData(x);
                    //if (newText) {
                    //    setTermsnConditionStringData(x + newText);
                    //}

                    // let ddData = response.objResult.map((x, index) => (<li key={index}>{x.termsAndConditions}</li>));
                    // setDd(ddData);

                }
                else (
                    ///setDd(props.editData.termNconditions);
                    setTermsnConditionStringData(props.editData.termsNconditions)

                )



                setIsLoader(false)
            })

            .catch((error) => {
                setIsLoader(false)
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
            });
    }

    function termsConditionData() {
        setIsLoader(true);

        getTermsConditions(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, offerId)
            .then((response) => {

                console.log("gettermandcondition", response);
                console.log("obj", response.objResult);
                //if (response && response.objResult) {
                //    settncData(response.objResult);
                //}
                if (response.objResult.length > 0) {

                    let ddData = response.objResult.map((x) => x.termsAndConditions);
                    console.log("ddData", ddData);
                    let x = ddData.toString();
                    console.log("x", x);
                    setTermsnConditionStringData(x);
                    //if (newText) {
                    //    setTermsnConditionStringData(x + newText);
                    //}

                    // let ddData = response.objResult.map((x, index) => (<li key={index}>{x.termsAndConditions}</li>));
                    // setDd(ddData);

                }
                else (
                    ///setDd(props.editData.termNconditions);
                    setTermsnConditionStringData(props.editData.termsNconditions)

                )



                setIsLoader(false)
            })

            .catch((error) => {
                setIsLoader(false)
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
            });
    }
    //getNotes
    const [notesData, setNotesData] = useState([]);
    const [ddnotes, setDdNOtes] = useState();
    function getNotesData() {
        setIsLoader(true);

        getNotes(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, offerId)
            .then((response) => {

                console.log("getnoteData", response);
                if (response.objResult.length > 0) {
                    setNotesData(response.objResult);

                    let ddDataNote = response.objResult.map((x) => x.notes);
                    // let ddData = response.objResult.map((x, index) => (<li key={index}>{x.termsAndConditions}</li>));
                    // setDdNOtes(ddDataNote);

                    let y = ddDataNote.toString();
                    console.log("y", y);
                    setNotesStringData(y);
                }
                else {
                    setNotesStringData(props.editData.notes)
                }
                setIsLoader(false)
            })

            .catch((error) => {
                setIsLoader(false)
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
            });
    }

    function getNotesData1(offerId) {
        setIsLoader(true);

        getNotes(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, offerId)
            .then((response) => {

                console.log("getnoteData", response);
                if (response.objResult.length > 0) {
                    setNotesData(response.objResult);

                    let ddDataNote = response.objResult.map((x) => x.notes);
                    // let ddData = response.objResult.map((x, index) => (<li key={index}>{x.termsAndConditions}</li>));
                    // setDdNOtes(ddDataNote);

                    let y = ddDataNote.toString();
                    console.log("y", y);
                    setNotesStringData(y);
                    //if (newText) {
                    //    setNotesStringData(y + newText);
                    //}
                }
                else {
                    setNotesStringData(props.editData.notes)
                }
                setIsLoader(false)
            })

            .catch((error) => {
                setIsLoader(false)
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
            });
    }



    const termNconditions = () => {

        let formData = {
            TermsAndConditionsId: 0,
            OfferTypeId: offerId || props.editData.offerId,//
            TermsAndConditions: tncComment,
            IsActive: true,
            CreatedBy: userDetails.userId,
            CreatedDate: null,
            ModifiedBy: userDetails.userId,
            ModifiedDate: null,
        }

        termsandConditionsPost(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                if (response.response) {
                    console.log("termNconditions", response);
                    //handleCloseDia()
                    // setTermsnConditionStringData(response.objResult.termsAndConditions);
                    //termsConditionData();
                    //handleSnackOpen(response.responseMsg, "success");
                    //setIsLoading(false);
                    // if (props.editData.termsAndConditions) {
                    //    setTermsnConditionStringData(props.editData.termsAndConditions);
                    // }
                    //termsConditionData1(response.objResult.termsAndConditions, response.objResult.offerTypeId);
                    termsConditionData1(response.objResult.offerTypeId);
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoading(false);

                    setTermsConditions(false);
                    //getItemGrid(refId)


                } else {

                    handleCloseDia()
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                handleCloseDia()

                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });

    }
    console.log("offerId", offerId);
    //notes-post
    const notesPostSubmit = () => {
        let formData = {

            NotesId: 0,
            OfferTypeId: offerId || props.editData.offerId,//offerId
            Notes: notesComment,
            IsActive: true,
            CreatedBy: userDetails.userId,
            CreatedDate: null,
            ModifiedBy: userDetails.userId,
            ModifiedDate: null,
        }


        notesPost(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                if (response.response) {
                    console.log("notesPost", response);
                    handleCloseDia()

                    getNotesData1(response.objResult.offerTypeId);
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoading(false);
                    //getItemGrid(refId)
                    setNotes(false);


                } else {

                    handleCloseDia()
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                handleCloseDia()

                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });

    }
    //function handleCopyIconClick(rowData) {
    //    setIsLoading(true);
    //    copyPOEntryDetails(siteToken, rowData.yearId, rowData.epPoentryPageId, userDetails.userId).
    //        then((response) => {
    //            setIsLoading(false);
    //            handleSnackOpen(response.responseMsg, "success");
    //            getAllPoEntryDetails();
    //        })
    //        .catch((error) => {
    //            setIsLoading(false);
    //            handleSnackOpen(error, "error")
    //        })
    //}
    function GetEditButton(rowData) {
        return (
            <Tooltip title="Modify Quotation" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function GetCopyButton() {
        return (
            <Tooltip title="Copy" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <FileCopyOutlinedIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function getCustomTBodyForTotal(data) {
        return (
            <TableBody style={{ position: "sticky", top: 60 }}>
                {data.map((row) => (
                    <TableRow className={classes.tableCellTotal}>
                        <TableCell
                            align="center"
                            colSpan={12}
                            style={{ fontWeight: "bold" }}
                        >
                            TOTAL
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.poValue_CustomerTestinomial, 0)
                            )}

                        </TableCell>


                    </TableRow>
                ))}
            </TableBody>
        );
    }
    function convertDateFormat(selData) {
        var dateStr =
            selData.getFullYear() + "-" +
            ("00" + (selData.getMonth() + 1)).slice(-2) + "-" +
            ("00" + selData.getDate()).slice(-2) + " " +
            ("00" + selData.getHours()).slice(-2) + ":" +
            ("00" + selData.getMinutes()).slice(-2) + ":" +
            ("00" + selData.getSeconds()).slice(-2);

        return dateStr;
    }
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteRowData, setDeleteRowData] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [valDate, setValDate] = useState(null);
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setDeleteRowData(null);
    };
    const handleDeleteIconClick = (rowData) => {
        setDeleteRowData(rowData);
        setDeleteDialogOpen(true);
    };
    function deletePoEntryRow() {
        deleteCTEntry(token, deleteRowData.id)
            .then((response) => {
                handleSnackOpen(response.responseMsg, "success");
                handleCloseDeleteDialog();
                //grid(deleteRowData.productId, deleteRowData.yearId);
                GridData();

            })
            .catch((error) => {
                handleSnackOpen(error, "error");
            });
    }
    const handleDateChange = (date) => {
        //const selData = convertDateFormat(date);
        // console.log(selData);
        date.toISOString();
        setSelectedDate(date);
    };
    const handleValChange = (date) => {
        // const selData = convertDateFormat(date);
        //console.log(selData);
        date.toISOString();
        setValDate(date);
    };
    function GetDeleteButton() {
        return (
            <Tooltip title="Delete" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <DeleteOutlined fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function getPOAttachments(siteToken, id, userId) {
        setIsLoading(true);
        CustomerAttachmentsBasedonId(siteToken, id, userId)
            .then((response) => {
                setFileAttachments(response);
                console.log("Attch Response", response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function clear() {
        //setYearValue(null);
        //setCustomerObj("");
        //setCustomer("");
        //setCustomerText("");
        //setCodeObj("");
        //setCode("");
        //setCodeText("");
        setItemObj("")
        setItem("")
        setItemText("")
        setDescObj("")
        setDescription("")
        setDescText("")
        setDescEdit("")
        setRate(0)
        setTax(null)
        //setTaxRate(null)
        setQty(null)
        setTot(null)
        setAccount("")
        setDiscTotal(null)
        setDiscTotalItemAmt(null)


    }
    //drop
    const [supply, setSupply] = useState(1)
    const [supplyId, setSupplyId] = useState([])
    function Supply() {
        console.log("hi");
        setIsLoading(true);

        getPlace(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log(response);

                setSupplyId(response.objResult);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    const [states, setStates] = useState("")
    const [statesId, setStatesId] = useState([])
    const [tax, setTax] = useState(4)
    const [taxId, setTaxId] = useState([])
    const [def, setDef] = useState(18)
    const [textauto, setTextAuto] = useState("")
    function handleChangeTextAuto(e) {
        setTextAuto(e.target.value);

    }
    const [textautoReject, setTextAutoReject] = useState("")
    function handleChangeTextAutoReject(e) {
        setTextAutoReject(e.target.value);

    }
    function handleChangeTax(e) {
        setTax(e.target.value);
        if (e.target.value === 1) {
            setDef(0)
        }
        else if (e.target.value === 2) {
            setDef(10)
        }
        else if (e.target.value === 3) {
            setDef(12)
        }
        else if (e.target.value === 4) {
            setDef(18)
        }
        else {
            setDef(28)
        }

    }
    function handleChangeStatus(e) {
        setStatus(e.target.value);

    }
    function handleChangeSupply(e) {
        setSupply(e.target.value);

    }
    function State() {
        console.log("hi");
        setIsLoading(true);

        getAllState(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log(response);

                setStatesId(response.objResult);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    function Tax() {
        console.log("hi");
        setIsLoading(true);

        getAllTax(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("hii", response);

                setTaxId(response.objResult);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    const [status, setStatus] = useState("")
    const [statussId, setStatussId] = useState([])
    function Status() {
        console.log("hi");
        setIsLoading(true);

        getAllStatus(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log(response.objResult);

                setStatussId(response.objResult);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }

    const [inputMultipleItem, setInputMultipleItem] = useState(Date.now);
    const [selectedMultipleItem, setSelectedMultipleItem] = useState(null);
    const UploadMultipleFiles = (event) => {
        console.log("event", event.target.files);
        setSelectedMultipleItem(event.target.files);
    };
    function UploadFilesOffer() {
        setIsLoading(true);
        if (!selectedMultipleItem) {
            handleSnackOpen("Please Select a File", "info");
            setIsLoading(false);

        }
        else {
            setIsLoading(true);
            const formData = new FormData();
            for (let i = 0; i < selectedMultipleItem.length; i++) {
                formData.append("file", selectedMultipleItem[i]);
            }

            formData.append("userId", userDetails.userId);
            formData.append("AutomatedReferenceNumber", ref);
            //setIsLoading(false);
            AddOfferAttachmentDetails(siteToken,
                azureToken.token,
                UID.id,
                userDetails.userEmail,
                userDetails.gid,
                userDetails.roleName,
                userDetails.roleId, formData)
                .then((response) => {
                    if (response.response) {
                        console.log("Uploaded file", response);
                        handleSnackOpen(response.responseMsg, "success");
                        setInputMultipleItem(Date.now);
                        setSelectedMultipleItem(null);
                        getUploadedFilesContent();
                        setIsLoading(false);

                    } else {
                        handleSnackOpen(response.responseMsg, "error");

                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setIsLoading(false);
                    setSelectedMultipleItem(null);
                    setInputMultipleItem(Date.now);
                    handleSnackOpen("Exception : " + error, "error");

                });
        }
    }
    const [fileMultipleAttachments, setFileMultipleAttachments] = useState([]);
    function getUploadedFilesContent() {
        console.log("ref", ref);
        setIsLoading(true);

        getUploadedFiles(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, ref)
            .then((response) => {
                console.log("attach", response.objResult);

                setFileMultipleAttachments(response);
                console.log("attach", response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    function getUploadedFilesContentOnLoad(refName) {
        console.log("ref", refNameData);
        const reff = refName != undefined || refName != null ? refName : refNameData?.id;
        setIsLoading(true);

        getUploadedFiles(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, reff)
            .then((response) => {
                console.log("attach", response.objResult);

                setFileMultipleAttachments(response);
                console.log("attach", response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    function renderFileMultipleDownload(rowData) {
        return (
            <Button
                href={rowData.fileUrl}
                color="transparent"
                target="_blank"
                download
            >
                <Tooltip title="Download Attachment">
                    <DownloadIconOutlined />
                </Tooltip>
            </Button>);
    }
    function deleteMultipleAttachment(rowData) {
        console.log("delete data", rowData);
        deleteOfferAttachments(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, rowData.attachmentId)
            .then((response) => {
                handleSnackOpen(response.responseMsg, "success");
                getUploadedFilesContent();
            })
            .catch((error) => {
                handleSnackOpen(error, "error");
            });
    }

    function renderFileMultipleDelete(rowData) {
        const rowD = rowData;
        return (
            <Button
                color="transparent"
                onClick={() => deleteMultipleAttachment(rowD)}
            >
                <Tooltip title="Delete Attachment">
                    <DeleteOutlineOutlinedIcon />
                </Tooltip>
            </Button>);
    }

    var attachMultipleColumns = [
        {
            title: "File Name",
            field: "filename"
        },
        {
            title: "Download",
            render: rowData => renderFileMultipleDownload(rowData)
        },
        {
            title: "Delete",
            render: rowData => renderFileMultipleDelete(rowData)
        },
    ];

    //new changes
    const [custName, setCustName] = useState("")
    function handleChangeCustName(e) {
        setCustName(e.target.value);

    }
    const [custPhone, setCustPhone] = useState("")
    function handleChangeCustPhone(e) {
        const enteredPhNor = e.target.value;
        setCustPhone(enteredPhNor);
        if (!validatePhNor(enteredPhNor))
            setPhNorHelperText("Invalid Phone Number");
        else
            setPhNorHelperText("");
    }

    const [ptitle, setPtitle] = useState("")
    function handleChangePtitle(e) {
        setPtitle(e.target.value);

    }

    function handleChangeSearchDescEDEP(e) {
        setDescText(e.target.value);
        setDescEdit(e.target.value);
    }

    function handleChangeItemTextEDEP(e) {
        setItemText(e.target.value);
        setItem(0);
    }

    function validateEmail(email) {
        const expEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const res = expEmail.test(email);
        return res;
    }
    function validatePhNor(phNor) {
        const expPhNor = /^\d{10}$/;
        const res = expPhNor.test(phNor);
        return res;
    }
    const [custEmail, setCustEmail] = useState("");
    const [emailHelperText, setEmailHelperText] = useState("");
    const [phNorHelperText, setPhNorHelperText] = useState("");
    function handleChangeCustEmail(e) {
        const enteredEmail = e.target.value;
        setCustEmail(enteredEmail);
        if (!validateEmail(enteredEmail))
            setEmailHelperText("Invalid Email");
        else
            setEmailHelperText("");
    }
    const [custAdd, setCustAdd] = useState("")
    function handleChangeCustAdd(e) {
        setCustAdd(e.target.value);

    }
    const [notes, setNotes] = useState(false);
    const [termsConditions, setTermsConditions] = useState(false);
    const handleNOTES = () => {
        setNotes(true);
    }
    const handleTNC = () => {
        setTermsConditions(true);
    }
    const handleCloseTNC = () => {
        console.log("tncclose");
        setTermsConditions(false);
    }
    const handleCloseNotes = () => {
        setNotes(false);
    }

    const [notesComment, setNotesComment] = useState("");
    const [tncComment, setTncComment] = useState("");
    const handleTNCcomment = (e) => {
        setTncComment(e.target.value);
    }
    const [prevFlag, setPrevFlag] = useState(false)
    const handleNotesComments = (e) => {
        setNotesComment(e.target.value);
    }
    function clearVal() {
        setStates("")
        setStatus("")
        setSupply("")
        setSelectedDate(null)
        setCustAdd("")
        setCustEmail("")
        setCustName("")
        setCustPhone(0)
        setPtitle("")
        setCustomerObj("");
        setCustomer("");
        setCustomerText("");
        setValDate(null)
        setDisc(0)
        setAdj(0)
        setOthers(0)
        setCal(0)
    }
    const handleSAVE = () => {

        if (offerId != 0) {
            handleSaveOffer()
        }
        else {
            handleEditOffer()
        }
    }


    const handleSaveOffer = () => {
        if (customer === "" || customer === null || customer === undefined) {
            handleSnackOpen("customer is Empty", "error");
            return;
        }
        if (supply === "" || supply === null || supply === undefined) {
            handleSnackOpen("Place Of supply is Empty", "error");
            return;
        }
        if (status === "" || status === null || status === undefined) {
            handleSnackOpen("Status is Empty", "error");
            return;
        }
        if (selectedDate === "" || selectedDate === null || selectedDate === undefined) {
            handleSnackOpen("Estimated date is Empty", "error");
            return;
        }
        if (valDate === "" || valDate === null || valDate === undefined) {
            handleSnackOpen("Validity Date is Empty", "error");
            return;
        }
        if (states === "" || states === null || states === undefined) {
            handleSnackOpen("state is Empty", "error");
            return;
        }
        if ((ptitle === "" || ptitle === null || ptitle === undefined) && offerId === 10) {
            handleSnackOpen("Project Title is Empty", "error");
            return;
        }
        if (custEmail !== "" && !validateEmail(custEmail)) {
            handleSnackOpen("Customer Email is Invalid", "error");
            return;
        }
        if (custPhone !== "" && !validatePhNor(custPhone)) {
            handleSnackOpen("Customer Phone Number is Invalid", "error");
            return;
        }


        setIsLoading(true);
        let formData = {

            // PrepareOrderId: offerId,
            OfferId: offerId,
            YearId: yearIdSelected,
            OfferReferenceNumberId: refId,
            OfferReferenceNumberName: ref,
            CustomerId: customer,
            CustomerObject: null,
            CustomerName: customerText,
            EstimateDate: selectedDate,
            ValidityDate: valDate,
            PlaceOfSupplyId: supply,
            PlaceOfSupply: null,
            StatusId: status,
            Status: null,
            StateId: states,
            State: null,
            Amount: cal,
            Discount: amount,
            Sgst: sgst,
            Cgst: cgst,
            Igst: igst,
            OtherCharges: others,
            Adjustment: adj,
            DiscountPercentage: disc,
            Total: tott,
            CustomerContactName: custName,
            CustomerContactEmail: custEmail,
            CustomerContactPhone: custPhone,
            ProjectTitle: ptitle,
            CustomerContactAddress: custAdd,
            IsActive: true,
            CreatedBy: userDetails.userId,


            TermsNconditions: termsnConditionStringData,//dd-->termsnConditionStringData
            Notes: notesStringData,//ddnotes--->notesStringData
            SelectedItemsVM: {
                Mlfb: checkItem,
                Description: checkDesc,
                Qty: checkQty,
                Rate: checkUnitLP,
                DiscountPercentage: checkDiscount,
                DiscountInAmount: checkDiscA,
                TaxRate: checkTaxRate,
                NetValue: checkTotal,
                NetTaxValue: checkTotalTax,
                TotalDiscount: checkTotalDisc,
                UnitPriceKp: checkUnitPrice,
                OriginalTotal: checkDiscQty
            }

        }

        SaveOfferGrid(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                if (response.response) {
                    clearVal()

                    handleSnackOpen(response.responseMsg, "success");
                    setTermsnConditionStringData(response.objResult.termsNconditions);
                    setNotesStringData(response.objResult.notes);
                    // window.location.reload();

                    dynamicReport(ref)
                    setIsLoading(false);
                    setPrevFlag(true)

                } else {


                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                    setPrevFlag(false)
                }
            })
            .catch((error) => {


                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
                setPrevFlag(false)
            });

    }
    const [reviseOffer, setReviseOffer] = useState(false)
    const handleRevise = () => {

        setIsLoading(true);
        setReviseOffer(true);
        let formData = {

            PrepareOrderId: prepID,
            // OfferId: offerId,
            reviseOffer: true,
            OfferReferenceNumberId: refId,
            OfferReferenceNumberName: ref,
            CustomerId: customer,
            CustomerObject: null,
            CustomerName: customerText,
            EstimateDate: selectedDate,
            ValidityDate: valDate,
            PlaceOfSupplyId: supply,
            PlaceOfSupply: null,
            StatusId: status,
            Status: null,
            StateId: states,
            State: null,
            Amount: cal,
            Discount: amount,
            Sgst: sgst,
            Cgst: cgst,
            Igst: igst,
            OtherCharges: others,
            Adjustment: adj,
            DiscountPercentage: disc,
            Total: tott,
            CustomerContactName: custName,
            CustomerContactEmail: custEmail,
            CustomerContactPhone: custPhone,
            ProjectTitle: ptitle,
            CustomerContactAddress: custAdd,
            IsActive: true,
            CreatedBy: userDetails.userId,
            ModifiedBy: userDetails.userId,

            TermsNconditions: termsnConditionStringData,//dd-->termsnConditionStringData
            Notes: notesStringData,//ddnotes--->notesStringData
            SelectedItemsVM: {
                Mlfb: checkItem,
                Description: checkDesc,
                Qty: checkQty,
                Rate: checkUnitLP,
                DiscountPercentage: checkDiscount,
                DiscountInAmount: checkDiscA,
                TaxRate: checkTaxRate,
                NetValue: checkTotal,
                NetTaxValue: checkTotalTax,
                TotalDiscount: checkTotalDisc,
                UnitPriceKp: checkUnitPrice,
                DiscountPerQty: checkDiscQty
            }

        }
        console.log("revise", formData)
        updateGrid(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                if (response.response) {
                    clearVal()

                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoading(false);
                    window.location.reload();

                } else {


                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                }
            })
            .catch((error) => {


                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });

    }

    const handleEditOffer1 = () => {

        setIsLoading(true);
        let formData = {

            PrepareOrderId: prepID,
            // OfferId: offerId,
            reviseOffer: false,
            OfferReferenceNumberId: refId,
            OfferReferenceNumberName: ref,
            CustomerId: customer,
            CustomerObject: null,
            CustomerName: customerText,
            EstimateDate: selectedDate,
            ValidityDate: valDate,
            PlaceOfSupplyId: supply,
            PlaceOfSupply: null,
            StatusId: status,
            Status: null,
            StateId: states,
            State: null,
            Amount: cal,
            Discount: amount,
            Sgst: sgst,
            Cgst: cgst,
            Igst: igst,
            OtherCharges: others,
            Adjustment: adj,
            DiscountPercentage: disc,
            Total: tott,
            IsActive: true,
            CreatedBy: userDetails.userId,
            ModifiedBy: userDetails.userId,
            TermsNconditions: termsnConditionStringData,//dd-->termsnConditionStringData
            Notes: notesStringData,//ddnotes--->notesStringData

        }
        console.log("edit", formData)
        updateGrid(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                if (response.response) {
                    clearVal()
                    setTermsnConditionStringData(response.objResult.termsNconditions);
                    //setTermsnConditionStringData()
                    handleSnackOpen(response.responseMsg, "success");
                    // window.location.reload();
                    setIsLoading(false);


                } else {


                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                }
            })
            .catch((error) => {


                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });

    }

    const handleEditOffer = () => {

        setIsLoading(true);
        let formData = {

            PrepareOrderId: prepID,
            // OfferId: offerId,
            reviseOffer: false,
            OfferReferenceNumberId: refId,
            OfferReferenceNumberName: ref,
            CustomerId: customer,
            CustomerObject: null,
            CustomerName: customerText,
            EstimateDate: selectedDate,
            ValidityDate: valDate,
            PlaceOfSupplyId: supply,
            PlaceOfSupply: null,
            StatusId: status,
            Status: null,
            StateId: states,
            State: null,
            Amount: cal,
            Discount: amount,
            Sgst: sgst,
            Cgst: cgst,
            Igst: igst,
            OtherCharges: others,
            Adjustment: adj,
            DiscountPercentage: disc,
            CustomerContactName: custName,
            CustomerContactEmail: custEmail,
            CustomerContactPhone: custPhone,
            ProjectTitle: ptitle,
            CustomerContactAddress: custAdd,
            Total: tott,
            IsActive: true,
            CreatedBy: userDetails.userId,
            ModifiedBy: userDetails.userId,
            TermsNconditions: termsnConditionStringData,//dd-->termsnConditionStringData
            Notes: notesStringData,//ddnotes--->notesStringData
            SelectedItemsVM: {
                Mlfb: checkItem,
                Description: checkDesc,
                Qty: checkQty,
                Rate: checkUnitLP,
                DiscountPercentage: checkDiscount,
                DiscountInAmount: checkDiscA,
                TaxRate: checkTaxRate,
                NetValue: checkTotal,
                NetTaxValue: checkTotalTax,
                TotalDiscount: checkTotalDisc,
                UnitPriceKp: checkUnitPrice,
                DiscountPerQty: checkDiscQty
            }

        }
        console.log("edit", formData)
        updateGrid(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                if (response.response) {
                    clearVal()
                    setTermsnConditionStringData(response.objResult.termsNconditions);
                    //setTermsnConditionStringData()
                    handleSnackOpen(response.responseMsg, "success");
                    window.location.reload();
                    setIsLoading(false);


                } else {


                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                }
            })
            .catch((error) => {


                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });

    }
    const handleSaveForm = () => {
        setFormDialogTitle("Save");

        if (!descEdit) {
            handleSnackOpen("Description is Empty", "error");
            return;
        }
        if (!qty) {
            handleSnackOpen("Quantity is Empty", "error");
            return;
        }

        if (offerId === 2 && item === "" || item === null || item === undefined) {
            handleSnackOpen("item is Empty", "error");
            return;
        }


        if (tax === "" || tax === null || tax === undefined) {
            handleSnackOpen("tax is Empty", "error");
            return;
        }
        if (qtyHelper != "") {
            handleSnackOpen("check qty", "error");
            return;
        }
        if (discHelper != "") {
            handleSnackOpen("check Discount(%)", "error");
            return;
        }

        //if (discTotal === "" || discTotal === null || discTotal === undefined) {
        //    handleSnackOpen("Discount is Empty", "error");
        //    return;
        //}
        setIsLoading(true);
        let formData = {

            //PrepareOrderId: offerId,
            // ItemDetailsId: item,

            Mlfb: itemText,
            MlfbmasterId: item,
            OfferReferenceNumberName: ref,
            OfferReferenceNumberId: refId,
            Discount: null,
            DiscountPercentage: discTotal,
            ItemObject: null,
            Description: descText,
            Qty: qty,
            Rate: rate,
            DiscountInAmount: discTotalItemAmt,
            DiscountPerQty: discItemTot,
            UnitPriceKp: unitkp,
            TotalDiscount: totdisc,
            OfferLineItemDescription: descEdit,
            // Account: account,
            TaxRate: null,
            TaxRateId: tax,
            NetValue: ItemTotal,
            NetTaxValue: ItemTotalTax,
            IsActive: true,
            CreatedBy: userDetails.userId,


        }

        SaveItemGrid(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                if (response.response) {

                    handleCloseForm()
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoading(false);
                    getItemGrid(refId)

                } else {

                    handleCloseForm()
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                handleCloseForm()

                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });

    }
    const handleUpdateForm = () => {
        //if (yearId === "" || yearId === null || yearId === undefined) {
        //    handleSnackOpen("year is Empty", "error");
        //    return;
        //}
        if (!descEdit) {
            handleSnackOpen("Description is Empty", "error");
            return;
        }
        if (!qty) {
            handleSnackOpen("Quantity is Empty", "error");
            return;
        }
        if (offerId === 2 && item === "" || item === null || item === undefined) {
            handleSnackOpen("item is Empty", "error");
            return;
        }
        if (tax === "" || tax === null || tax === undefined) {
            handleSnackOpen("tax is Empty", "error");
            return;
        }
        if (qtyHelper != "") {
            handleSnackOpen("check qty", "error");
            return;
        }
        if (discHelper != "") {
            handleSnackOpen("check Discount(%)", "error");
            return;
        }

        setIsLoading(true);
        let formData = {

            //PrepareOrderId: offerId,
            ItemDetailsId: id,
            OfferReferenceNumberName: ref,
            OfferReferenceNumberId: refId,
            Mlfb: itemText,
            MlfbmasterId: item,
            ItemObject: null,
            Discount: null,
            DiscountPercentage: discTotal,
            Description: descText,
            OfferLineItemDescription: descEdit,
            Qty: qty,
            Rate: rate,
            DiscountInAmount: discTotalItemAmt,
            DiscountPerQty: discItemTot,
            UnitPriceKp: unitkp,
            TotalDiscount: totdisc,
            //Account: account,
            TaxRate: null,
            TaxRateId: tax,
            NetValue: ItemTotal,
            NetTaxValue: ItemTotalTax,
            IsActive: true,
            CreatedBy: userDetails.userId,
            ModifiedBy: userDetails.userId,

        }

        updateItemGrid(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                console.log("update,response");
                if (response.response) {


                    handleCloseForm()
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoading(false);
                    // GridData();
                    getItemGrid(refId)
                } else {

                    handleCloseForm()
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                }
            })
            .catch((error) => {

                handleCloseForm()
                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });
    }
    const [qtyHelper, setQtyHelper] = useState("");
    const [rateHelper, setRateHelper] = useState("");
    const [discHelper, setDiscHelper] = useState("");
    const handleChange = e => {
        let valid;


        switch (e.target.id) {
            case "qty":
                setQty(e.target.value)
                valid = /^[0-9]*$/.test(e.target.value);
                if (!valid) {
                    setQtyHelper("please enter numbers only");
                    /*console.log("so",rollHelper)*/
                }
                //else if (e.target.value < 0)
                //{
                //    setQtyHelper("please enter only positive numbers");
                //}
                else {
                    setQtyHelper("");

                }
                break;
            case "disc":
                setDiscTotal(e.target.value);
                setDiscTotalItemAmt(null);
                valid = /^[0-9]*$/.test(e.target.value);
                if (!valid) {
                    setDiscHelper("please enter numbers only");
                    /*console.log("so",rollHelper)*/
                }
                else {
                    setDiscHelper("");

                }
                break;
            case "rate":
                setRate(e.target.value);
                valid = /^[0-9]*$/.test(e.target.value);
                if (!valid) {
                    setRateHelper("please enter numbers only");
                }
                else {
                    setRateHelper("");

                }
                break;
            default:
                break;
        }

    }
    async function setUpdate(rowData) {

        console.log("rowdata", rowData);

        // setCustomerObj(rowData.customerObject[0]);
        //setCustomer(rowData.customerObject[0] ? rowData.customerObject[0].epCustomerId : 0);
        // setCodeObj(rowData.customerObject[0]);
        //setCode(rowData.customerObject[0] ? rowData.customerObject[0].epCustomerId : 0);
        // setCustomerText(rowData.customerName);
        setItemObj(rowData.itemObject)
        setItem(rowData.itemObject ? rowData.itemObject.mlfbmasterId : 0)
        setItemText(rowData.mlfb)
        setItem(0);
        setDescObj(rowData.itemObject)
        setDescription(rowData.itemObject ? rowData.itemObject.mlfbmasterId : 0)

        setDescText(rowData.description)
        setDescEdit(rowData.description);
        //setCodeText(rowData.code);
        //setEPPoentryId(rowData.Id);
        setId(rowData.itemDetailsId);
        console.log("itemText", itemText)

        setRate(rowData.rate);
        setAccount(rowData.account);
        setTax(rowData.taxRateId);
        setTot(rowData.netValue);
        setQty(rowData.qty)
        setDiscTotal(rowData.discountPercentage)
        setDiscTotalItemAmt(rowData.discountInAmount)


        setDialogSubmitBtn("Update");
        // setFormDialogTitle("Update");
        setOpenDialog(true);
    }
    function Del() {
        setIsLoading(true);
        var data = {
            ItemDetailsId: deleteRowData.itemDetailsId,
            ModifiedBy: userDetails.userId,

        }
        DelItemGrid(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, data)
            .then((response) => {
                console.log("del", response)
                //setRef(response);
                handleCloseDeleteDialog()
                handleSnackOpen(
                    response.responseMsg,

                    "success"
                );
                getItemGrid(refId)

                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                //handleSnackOpen(error, "error");
                handleSnackOpen("Error in hitting Api", "error");

            })


    }
    const [discTotal, setDiscTotal] = useState(null)
    const [discTotalItemAmt, setDiscTotalItemAmt] = useState(null)
    //const [discItemTot, setDiscItemTot] = useState(null)
    function handleChangeDiscountTotal(e) {
        setDiscTotal(e.target.value);

    }
    function handleChangeDiscountTotalItemAmt(e) {
        setDiscTotalItemAmt(e.target.value);
        setDiscTotal(null);

    }

    const col = [
        {
            title: "Search Description", field: "description"
        },
        {
            title: "Description", field: "offerLineItemDescription"
        },
        { title: "Item", field: "mlfb" },
        { title: "Qty", field: "qty" },
        { title: "Unit LP", field: "rate" },

        { title: "Tax Rate", field: "taxRate" },
        { title: "Discount(%)", field: "discountPercentage" },
        { title: "Total", field: "netValue" },

    ]
    const d =
        [
            {
                name: "8/16/2023",
                iname: "Sw0000908",
                date: "",
                status: "Asian PVT LMT",
                action: "Sent",
                pend: "900000"
            },
            {
                name: "10/16/2023",
                iname: "Aw0000908",
                date: "",
                status: "Anasol",
                action: "Sent",
                pend: "1000000"
            },


        ]
    function GetEditButton() {
        return (
            <Tooltip title="Edit" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    const [openDialog, setOpenDialog] = useState(false);
    const [openRemarks, setOpenRemarks] = useState(false);
    const [openRejectRemarks, setOpenRejectRemarks] = useState(false);
    const [dialogSubmitBtn, setDialogSubmitBtn] = useState("Save");
    const [dialogSubmit, setDialogSubmit] = useState("Save");
    const handleCloseForm = () => {
        clear();
        setOpenDialog(false);

    };
    const handleCloseRemarks = () => {

        setOpenRemarks(false);

    };
    const handleCloseRejectRemarks = () => {

        setOpenRejectRemarks(false);

    };
    // calc

    const [disc, setDisc] = useState(0)
    const [discA, setDiscA] = useState(0)
    const [adj, setAdj] = useState(null)
    const [others, setOthers] = useState(null)
    const [discAfter, setDiscAfter] = useState(null)
    let l = 0;
    l = cal
    l = Math.round(l * 100 / 100)
    //if (l != 0|| l!=null) {
    //    l = parseFloat(l.toFixed(2));
    //}
    //else {
    //    l=0
    //}
    let sgst = 0
    sgst = ((l) * (9) / 100)
    sgst = parseFloat(sgst.toFixed(2));
    let cgst = 0
    cgst = ((l) * (9) / 100)
    cgst = parseFloat(cgst.toFixed(2));
    let igst = 0
    igst = ((l) * (18) / 100)
    igst = parseFloat(igst.toFixed(2));
    let sub = 0
    sub = l + Math.trunc((adj)) + Math.trunc(others);
    sub = parseFloat(sub.toFixed(2));

    let tott = 0
    if (states == 14) {
        tott = l + (sgst) + cgst + Math.trunc((adj)) + Math.trunc(others)
    }
    else {
        tott = l + (igst) + Math.trunc((adj)) + Math.trunc(others)
    }
    tott = parseFloat(tott.toFixed(2));

    let amount = 0
    amount = ((disc) * (cal) / 100)
    amount = parseFloat(amount.toFixed(2));
    //Item grid cal
    let ItemTot = 0;

    ItemTot = rate - ((discTotal) * (rate) / 100) - ((discTotalItemAmt))
    ItemTot = parseFloat(ItemTot.toFixed(2));

    let discItemTot = 0
    discItemTot = ((discTotal) * (rate) / 100)
    discItemTot = parseFloat(discItemTot.toFixed(2));

    let unitkp = 0
    unitkp = rate - discItemTot
    unitkp = parseFloat(unitkp.toFixed(2));
    //ItemTot=qty*ItemTot
    let ItemTotal = 0;
    ItemTotal = (qty * unitkp);
    ItemTotal = parseFloat(ItemTotal.toFixed(2));


    let totdisc = 0
    if (discTotalItemAmt === 0 || discTotalItemAmt === null || discTotalItemAmt === "") {
        totdisc = qty * discItemTot
    }
    else {
        totdisc = discTotalItemAmt * qty
    }
    totdisc = parseFloat(totdisc.toFixed(2));

    //ItemTotal = ItemTotal - totdisc;
    //ItemTotal = parseFloat(ItemTotal.toFixed(2));

    let ItemTotalTax = 0
    ItemTotalTax = ItemTotal + ((def) * (ItemTotal) / 100)
    ItemTotalTax = parseFloat(ItemTotalTax.toFixed(2));

    const handleChangeDisc = e => {


        setDisc(e.target.value)


    }


    const handleChangeDiscA = e => {
        setDiscA(e.target.value)

    }
    const handleChangeAdj = e => {
        setAdj(e.target.value)

    }
    const handleChangeOthers = e => {
        setOthers(e.target.value)

    }
    function backClick() {

        if (props === null) {
            history.goBack()
        }
        else {
            window.location.href = "./offer"
        }
    }

    const [inputFileValueItem, setInputFileValueItem] = useState(Date.now);
    const [selectedFileItem, setSelectedFileItem] = useState(null);
    const updateExcelHandlerItem = (event) => {
        console.log("event", event.target.files[0]);
        setSelectedFileItem(event.target.files[0]);
    };

    //ref-->refernumber
    function uploadUtemHandler() {
        const formData1 = new FormData();
        setIsLoader(true);
        formData1.append("file", selectedFileItem);
        formData1.append("UserId", userDetails.userId);
        formData1.append("OfferReferenceNumberId", refId);
        formData1.append("OfferId", offerId);
        //formData.append("productId", row.epPoentryPageId);
        //UploadData(formData);
        uploadItemChoosen(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData1)
            .then((response) => {
                console.log("hi");
                console.log(response);
                if (response.response) {
                    setIsLoader(false);
                    // GridData();
                    getItemGrid(refId);
                    handleDialogMessage(response.responseMsg);
                } else {
                    setIsLoader(false);
                    setSelectedFileItem(null);
                    setInputFileValueItem(Date.now);
                    console.log(response.objResult);
                    setErrorData(response.objResult);
                    getItemGrid(refId);
                    handleDialogMessage(
                        response.responseMsgs && response.responseMsgs.length > 0
                            ? response.responseMsgs
                            : response.responseMsg
                    );
                }
            })
            .catch((error) => {
                setIsLoader(false);
                setSelectedFileItem(null);
                setInputFileValueItem(Date.now);
                handleDialogMessage("Exception in upload");
            });
    }

    return (
        <>
            <div>
                <Grid container spacing={2}>
                    {/*<Grid*/}
                    {/*    container*/}
                    {/*    spacing={2}*/}
                    {/*    item*/}
                    {/*    xs={12}*/}
                    {/*    className="d-flex jc-space-bt"*/}
                    {/*>*/}
                    <Grid item xs={12} className="text-left ml-1">
                        <h2>{type} </h2>
                    </Grid>
                    {/*<Grid item xs={12} sm={6} md={4}>*/}
                    {/*    <FormControl fullWidth>*/}
                    {/*        <InputLabel shrink htmlFor="bootstrap-input" >*/}
                    {/*            Customer Name*/}
                    {/*        </InputLabel>*/}

                    {/*        <Select*/}

                    {/*            label="service"*/}
                    {/*            id=""*/}
                    {/*            name=""*/}
                    {/*            className=""*/}
                    {/*            fullWidth*/}
                    {/*           // value={serviceTypeVal}*/}
                    {/*           // onChange={serviceTypeChange}*/}
                    {/*            input={<BootstrapInput />}*/}
                    {/*        >*/}
                    {/*            <MenuItem value="">*/}
                    {/*                <em>None</em>*/}
                    {/*            </MenuItem>*/}
                    {/*            <MenuItem value={10}>Ten</MenuItem>*/}
                    {/*            <MenuItem value={20}>Twenty</MenuItem>*/}
                    {/*            <MenuItem value={30}>Thirty</MenuItem>*/}

                    {/*        </Select>*/}
                    {/*    </FormControl>*/}





                    {/*</Grid>*/}
                    <Grid item xs={12} sm={6} md={4} fullWidth className="d-flex Width-100 mt-1">
                        <Autocomplete
                            //freeSolo={options && options.length > 0 ? true : false}
                            PopperComponent={PopperCST}
                            className="lightInputFields hover-lightblue"
                            id="global-search"
                            fullWidth
                            //value={searchValue }
                            //style={{ width: 200 }}
                            disableClearable
                            value={customerObj}

                            inputValue={customerText}
                            options={itemsCustomer}
                            getOptionLabel={(option) => (option.customerName1 ? option.customerName1 : "")}
                            // getOptionLabel={(option) => (option.customerCodeWithName ? option.customerCodeWithName : "")}
                            //forcePopupIcon={false}
                            // noOptionsText={searchValue && searchValue.length > 0 ? "No Results Found" : "Type to search"}
                            onChange={handleChangeCustomer}
                            onInputChange={handleInputChange}
                            classes={{
                                option: classes.option,
                            }}
                            // filterOptions={(options,state)=>options }
                            filteselectedoptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Customer"
                                    // placeholder="Search"
                                    //value={searchValue}
                                    style={{ backgroundColor: "var(--light-sand)" }}
                                    variant="filled"
                                    inputProps={{
                                        ...params.inputProps,
                                        type: "search",
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    {/*<Grid item xs={12} sm={6} md={4}>*/}
                    {/*    <FormControl fullWidth>*/}
                    {/*        <InputLabel shrink htmlFor="bootstrap-input" >*/}
                    {/*            Salutation*/}
                    {/*        </InputLabel>*/}

                    {/*        <Select*/}

                    {/*            label="service"*/}
                    {/*            id=""*/}
                    {/*            name=""*/}
                    {/*            className=""*/}
                    {/*            fullWidth*/}
                    {/*            // value={serviceTypeVal}*/}
                    {/*            // onChange={serviceTypeChange}*/}
                    {/*            input={<BootstrapInput />}*/}
                    {/*        >*/}
                    {/*            <MenuItem value="">*/}
                    {/*                <em>None</em>*/}
                    {/*            </MenuItem>*/}
                    {/*            <MenuItem value={10}>Ten</MenuItem>*/}
                    {/*            <MenuItem value={20}>Twenty</MenuItem>*/}
                    {/*            <MenuItem value={30}>Thirty</MenuItem>*/}

                    {/*        </Select>*/}
                    {/*    </FormControl>*/}





                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={6} md={4}>*/}
                    {/*    <FormControl fullWidth>*/}
                    {/*        <InputLabel shrink htmlFor="bootstrap-input" >*/}
                    {/*            Complimentary Closing*/}
                    {/*        </InputLabel>*/}

                    {/*        <Select*/}

                    {/*            label="service"*/}
                    {/*            id=""*/}
                    {/*            name=""*/}
                    {/*            className=""*/}
                    {/*            fullWidth*/}
                    {/*            // value={serviceTypeVal}*/}
                    {/*            // onChange={serviceTypeChange}*/}
                    {/*            input={<BootstrapInput />}*/}
                    {/*        >*/}
                    {/*            <MenuItem value="">*/}
                    {/*                <em>None</em>*/}
                    {/*            </MenuItem>*/}
                    {/*            <MenuItem value={10}>Ten</MenuItem>*/}
                    {/*            <MenuItem value={20}>Twenty</MenuItem>*/}
                    {/*            <MenuItem value={30}>Thirty</MenuItem>*/}

                    {/*        </Select>*/}
                    {/*    </FormControl>*/}





                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sm={6} md={4}>*/}
                    {/*    <TextField*/}
                    {/*        id="filled-multiline-static"*/}
                    {/*        label="Address"*/}
                    {/*        multiline*/}
                    {/*        fullWidth*/}
                    {/*        rows={4}*/}
                    {/*        //defaultValue="Default Value"*/}
                    {/*        variant="filled"*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={6} md={4}>*/}
                    {/*    <TextField*/}
                    {/*        id="filled-multiline-static"*/}
                    {/*        label="Annex"*/}
                    {/*        multiline*/}
                    {/*        fullWidth*/}
                    {/*        rows={4}*/}
                    {/*        //defaultValue="Default Value"*/}
                    {/*        variant="filled"*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12} sm={6} md={4}>*/}
                    {/*    <TextField*/}
                    {/*        id="filled-multiline-static"*/}
                    {/*        label="Subject"*/}
                    {/*        multiline*/}
                    {/*        fullWidth*/}
                    {/*        rows={4}*/}
                    {/*        //defaultValue="Default Value"*/}
                    {/*        variant="filled"*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={6} md={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                // margin="normal"
                                id="date-picker-dialog"
                                label="Estimate Date"
                                format="MM/dd/yyyy"
                                placeholder="MM/dd/yyyy"
                                InputProps={{ readOnly: true }}
                                fullWidth
                                // maxDate={new Date()}
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                // margin="normal"
                                id="date-picker-dialog"
                                label="Validity Date"
                                format="MM/dd/yyyy"
                                placeholder="MM/dd/yyyy"
                                InputProps={{ readOnly: true }}
                                fullWidth
                                minDate={new Date(selectedDate)}
                                // maxDate={new Date()}
                                value={valDate}
                                onChange={handleValChange}
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            className=""
                            fullWidth
                            name="poNumber"
                            label="Estimate #"
                            variant="filled"
                            value={ref}
                            disabled={true}
                            // helperText={emailHelperText}
                            // onChange={poNumberValChange}
                            id="poNumber"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input" >
                                Place Of Supply
                            </InputLabel>

                            <Select

                                label="service"
                                id=""
                                name=""
                                className=""
                                fullWidth
                                value={supply}
                                onChange={handleChangeSupply}
                                input={<BootstrapInput />}
                            >
                                {supplyId.map((item) => (
                                    <MenuItem key={item.placeOfSupplyId} value={item.placeOfSupplyId}>
                                        {item.placeOfSupplyName}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input" >
                                State
                            </InputLabel>

                            <Select

                                label="service"
                                id=""
                                name="satesId"
                                className=""
                                fullWidth
                                value={states}
                                onChange={handleChangeState}
                                input={<BootstrapInput />}
                            >
                                {statesId.map((item) => (
                                    <MenuItem key={item.stateId} value={item.stateId}>
                                        {item.stateName}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input" >
                                Status
                            </InputLabel>

                            <Select

                                label="service"
                                id=""
                                name=""
                                className=""
                                fullWidth
                                value={status}
                                onChange={handleChangeStatus}
                                input={<BootstrapInput />}
                            >
                                {statussId.map((item) => (
                                    <MenuItem key={item.statusId} value={item.statusId}>
                                        {item.statusName}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Grid>
                    {offerId === 10 ?
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                className=""
                                fullWidth
                                name="pTitle"
                                label="Project title"
                                variant="filled"
                                value={ptitle}
                                onChange={handleChangePtitle}
                                id="pTitle"
                            />

                        </Grid> : <></>
                    }
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} className="text-left ml-1">
                        <h4>Customer Contact Details </h4>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            className=""
                            fullWidth
                            name="poNumber"
                            label="Customer Name"
                            variant="filled"
                            value={custName}
                            // helperText={emailHelperText}
                            onChange={handleChangeCustName}
                            id="qty"
                        // helperText={qtyHelper}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            className=""
                            fullWidth
                            name="poNumber"
                            label="Customer Phone Number"
                            variant="filled"
                            value={custPhone}
                            helperText={phNorHelperText}
                            onChange={handleChangeCustPhone}
                            id="qty"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            className=""
                            fullWidth
                            name="poNumber"
                            label="Customer Email"
                            variant="filled"
                            value={custEmail}
                            helperText={emailHelperText}
                            onChange={handleChangeCustEmail}
                            id="qty"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            className=""
                            fullWidth
                            name="poNumber"
                            label="Customer Address"
                            variant="filled"
                            value={custAdd}
                            onChange={handleChangeCustAdd}
                            id="qty"
                        />
                    </Grid>
                </Grid>


                <Grid className="upload-section">
                    <Grid container spacing={2} className="upload-section">
                        <Grid
                            container
                            spacing={2}
                            item
                            xs={12}
                            className="d-flex jc-space-bt"
                        >
                            <Grid item xs={12} className="text-left ml-1">
                                <h2>Offer Documents Upload</h2>
                            </Grid>
                            <Grid container spacing={2} xs={12}>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        id="MultipleUpload"
                                        variant="filled"
                                        disabled={props?.editData?.isRevised}
                                        className="bulk-upload"
                                        type="file"
                                        name="MultipleFileUpload"
                                        key={inputMultipleItem}
                                        onChange={UploadMultipleFiles}
                                        inputProps={{
                                            multiple: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button
                                        className="pt-button--primary"
                                        type="submit"
                                        // disabled={selectedFile ? false : true}
                                        onClick={UploadFilesOffer}
                                        disabled={!selectedMultipleItem}
                                    >
                                        Upload
                                    </Button>
                                </Grid>



                            </Grid>
                        </Grid>



                        {/*<div className="marginTop">*/}
                        {/*    <Grid item xs={12}>*/}
                        {/*        <MaterialTable*/}
                        {/*            title="Uploaded Data"*/}
                        {/*            columns={columns}*/}
                        {/*            data={data}*/}
                        {/*            icons={tableIconsInputScreen}*/}
                        {/*            //isLoading={isLoader}*/}
                        {/*            options={{ headerStyle: { background: '#ADD8E6' }, tableOptionsUser, exportButton: { csv: true } }}*/}
                        {/*        />*/}
                        {/*    </Grid>*/}
                        {/*</div>*/}
                    </Grid>

                </Grid>
                <Grid xs={12} alignItems="center">
                    {fileMultipleAttachments.length > 0 ? <MaterialTable
                        title="Attachments"
                        fullWidth
                        columns={attachMultipleColumns}
                        data={fileMultipleAttachments}
                        icons={tableIconsInputScreen}
                        options={{
                            search: false,
                            pageSize: 5,
                            pageSizeOptions: [5],

                        }}
                    /> : <></>}
                </Grid>
                <Grid className="upload-section">
                    <Grid container spacing={2} className="upload-section">
                        <Grid
                            container
                            spacing={2}
                            item
                            xs={12}
                            className="d-flex jc-space-bt"
                        >
                            <Grid item xs={12} className="text-left ml-1">
                                <h2>Items Upload Template</h2>
                            </Grid>
                            <Grid container spacing={2} xs={12}>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        id="EPQUploadFile"
                                        variant="filled"
                                        className="bulk-upload"
                                        type="file"
                                        disabled={props?.editData?.isRevised}
                                        name="bulkUpload"
                                        key={inputFileValueItem}
                                        onChange={updateExcelHandlerItem}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button
                                        className="pt-button--primary"
                                        type="submit"
                                        disabled={selectedFile ? false : true}
                                        onClick={uploadUtemHandler}
                                        disabled={!selectedFileItem}
                                    >
                                        Upload
                                    </Button>
                                </Grid>


                                <Grid item xs={12} md={3}>
                                    {offerId === 10 ? <a
                                        href={ItemsUploadTemplateED}
                                        target="_blank"
                                    >
                                        <Button onClick={reportDownload} className="pt-button--primary bulk-upload-submit-btn">
                                            Download Template
                                        </Button>
                                    </a> : <a
                                        href={Items_Upload_Template}
                                        target="_blank"
                                    >
                                        <Button onClick={reportDownload} className="pt-button--primary bulk-upload-submit-btn">
                                            Download Template
                                        </Button>
                                    </a>}

                                </Grid>



                            </Grid>
                        </Grid>



                        {/*<div className="marginTop">*/}
                        {/*    <Grid item xs={12}>*/}
                        {/*        <MaterialTable*/}
                        {/*            title="Uploaded Data"*/}
                        {/*            columns={columns}*/}
                        {/*            data={data}*/}
                        {/*            icons={tableIconsInputScreen}*/}
                        {/*            //isLoading={isLoader}*/}
                        {/*            options={{ headerStyle: { background: '#ADD8E6' }, tableOptionsUser, exportButton: { csv: true } }}*/}
                        {/*        />*/}
                        {/*    </Grid>*/}
                        {/*</div>*/}
                    </Grid>

                </Grid>
                <Grid container spacing={2} >
                    <Grid xs={12}>
                        <MaterialTable
                            title=""
                            columns={col}
                            data={gridData}
                            icons={tableIconsInputScreen}
                            //isLoading={isLoader}

                            options={{
                                /*rowStyle: rowData => new Date(rowData.povalidity).getTime() > new Date(d1).getTime() ? ({ backgroundColor: "rgba(255,84,84,0.5019607843137255)" }) : ({ backgroundColor: "#FFFFFF", }),*/
                                exportButton: { csv: true }, exportAllData: true,
                                //exportCsv: (columns, data) => {
                                //    console.log("Report Icon Clicked");
                                //    //getQuotationListReport();
                                //},
                                headerStyle: { background: '#d3d3d3' }, tableOptionsUser, maxBodyHeight: "300px", searchFieldAlignment: "left",
                                toolbarButtonAlignment: "left",
                            }}
                            actions={[
                                {
                                    icon: GetAddButton,
                                    disabled: isRevise,
                                    isFreeAction: true,
                                    onClick: () => {
                                        setDialogSubmitBtn("Save");
                                        setCustDialogTitle("Add an Item");
                                        setTax(4)
                                        console.log("check", checkDesc);
                                        setOpenDialog(true);
                                    },
                                },
                                {
                                    icon: GetEditButton,
                                    tisFreeAction: true,
                                    onClick: (e, rowData) => {
                                        setUpdate(rowData)

                                        // setOpenDialog(true);
                                        setCustDialogTitle("Update Item");

                                        //    //object(rowData.epPoentryPageId);
                                        //    //getCustDetailsMaster(rowData.customerName)
                                        //    //handleInputChange(e, rowData.customerName)
                                        //    //getCustDetailsMaster(rowData.customerName)
                                        //dialogDraftValuesFn(rowData);
                                    },
                                },
                                {
                                    icon: GetDeleteButton,
                                    tisFreeAction: false,
                                    onClick: (e, rowData) => {
                                        console.log("Row Data", rowData);
                                        //deletePoEntryRow(rowData);
                                        handleDeleteIconClick(rowData);
                                    },


                                },



                            ]}

                        />
                    </Grid>
                </Grid>


                {/*    */}{/*<Grid item xs={12} sm={6} md={4}>*/}
                {/*    */}{/*    <FormControlLabel*/}
                {/*    */}{/*        control={*/}
                {/*    */}{/*            <Checkbox*/}
                {/*    */}{/*               // checked={state.checkedB}*/}
                {/*    */}{/*                //onChange={handleChange}*/}
                {/*    */}{/*                name="checkedB"*/}
                {/*    */}{/*                color="primary"*/}
                {/*    */}{/*            />*/}
                {/*    */}{/*        }*/}
                {/*    */}{/*        label="with Logo"*/}
                {/*    */}{/*    />*/}
                {/*    */}{/*</Grid>*/}
                {/*    */}{/*<Grid item xs={12} sm={6} md={4}>*/}
                {/*    */}{/*    <FormControlLabel*/}
                {/*    */}{/*        control={*/}
                {/*    */}{/*            <Checkbox*/}
                {/*    */}{/*                // checked={state.checkedB}*/}
                {/*    */}{/*                //onChange={handleChange}*/}
                {/*    */}{/*                name="checkedB"*/}
                {/*    */}{/*                color="primary"*/}
                {/*    */}{/*            />*/}
                {/*    */}{/*        }*/}
                {/*    */}{/*        label="with company line before signature"*/}
                {/*    */}{/*    />*/}
                {/*    */}{/*</Grid>*/}
                {/*    */}{/*<Grid item xs={12} sm={6} md={4}>*/}
                {/*    */}{/*    <FormControlLabel*/}
                {/*    */}{/*        control={*/}
                {/*    */}{/*            <Checkbox*/}
                {/*    */}{/*                // checked={state.checkedB}*/}
                {/*    */}{/*                //onChange={handleChange}*/}
                {/*    */}{/*                name="checkedB"*/}
                {/*    */}{/*                color="primary"*/}
                {/*    */}{/*            />*/}
                {/*    */}{/*        }*/}
                {/*    */}{/*        label="Insert Compliance notice"*/}
                {/*    */}{/*    />*/}
                {/*    */}{/*</Grid>*/}
                {/*</Grid>*/}

                <Grid container spacing={2} >
                    <Grid item xs={3}>
                        <Grid item xs={12} >
                            <TextField
                                className=""
                                fullWidth
                                name="poNumber"
                                label="Total"
                                variant="filled"
                                value={cal}
                                disabled={true}
                                // helperText={emailHelperText}
                                //onChange={handleChangeDiscA}
                                id="poNumber"
                            />

                        </Grid>
                        <Grid item xs={12}>


                            <Grid item xs={12} >
                                <TextField
                                    className=""
                                    fullWidth
                                    name="poNumber"
                                    label="Discount(%)"
                                    variant="filled"
                                    value={disc}
                                    disabled={true}
                                    // helperText={emailHelperText}
                                    // onChange={handleChangeDisc}
                                    id="poNumber"
                                />
                            </Grid>
                            {/*     <Grid item xs={6} >*/}
                            {/*     <TextField*/}
                            {/*         className="mt-1"*/}
                            {/*         fullWidth*/}
                            {/*         name="poNumber"*/}
                            {/*         //label="Discount(%)"*/}
                            {/*         variant="filled"*/}
                            {/*         value={l}*/}
                            {/*         // helperText={emailHelperText}*/}
                            {/*         // onChange={poNumberValChange}*/}
                            {/*         id="poNumber"*/}
                            {/*     />*/}
                            {/*</Grid>*/}





                            <Grid item xs={12} >
                                <TextField
                                    className="mt-1"
                                    fullWidth
                                    name="poNumber"
                                    label="Discount(in Amount)"
                                    variant="filled"
                                    value={discA}
                                    disabled={true}
                                    // helperText={emailHelperText}
                                    // onChange={handleChangeDiscA}
                                    id="poNumber"
                                />

                            </Grid>
                            {/*<Grid item xs={6} >*/}
                            {/*    <TextField*/}
                            {/*        className="mt-1"*/}
                            {/*        fullWidth*/}
                            {/*        name="poNumber"*/}
                            {/*        // label="Discount(in Amount)"*/}
                            {/*        variant="filled"*/}
                            {/*        value={l}*/}
                            {/*        // helperText={emailHelperText}*/}
                            {/*        // onChange={poNumberValChange}*/}
                            {/*        id="poNumber"*/}
                            {/*    />*/}

                            {/*</Grid>*/}



                        </Grid>
                    </Grid>


                    <Grid item xs={3}>
                        {states == 14 ? (
                            <Grid item xs={12} >
                                <TextField
                                    className="mt-1"
                                    fullWidth
                                    name="poNumber"
                                    label="SGST[9%]"
                                    variant="filled"
                                    value={sgst}
                                    disabled={true}
                                    // helperText={emailHelperText}
                                    // onChange={poNumberValChange}
                                    id="poNumber"
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}

                        {states == 14 ? (
                            <Grid item xs={12} >
                                <TextField
                                    className=""
                                    fullWidth
                                    name="poNumber"
                                    label=" CGST[9%]"
                                    variant="filled"
                                    value={cgst}
                                    disabled={true}
                                    // helperText={emailHelperText}
                                    // onChange={poNumberValChange}
                                    id="poNumber"
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}

                        {states != 14 ? (
                            <Grid item xs={12} >
                                <TextField
                                    className=""
                                    fullWidth
                                    name="poNumber"
                                    label=" IGST[18%]"
                                    variant="filled"
                                    value={igst}
                                    // helperText={emailHelperText}
                                    // onChange={poNumberValChange}
                                    disabled={true}
                                    id="poNumber"
                                />
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={1} >

                                <Grid item xs={12} >
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Adjustments"
                                        variant="filled"
                                        value={adj}
                                        // helperText={emailHelperText}
                                        onChange={handleChangeAdj}
                                        id="poNumber"
                                    />
                                </Grid>
                                {/*<Grid item xs={6} >*/}
                                {/*    <TextField*/}
                                {/*        className=""*/}
                                {/*        fullWidth*/}
                                {/*        name="poNumber"*/}
                                {/*        //  label="Adjustments"*/}
                                {/*        variant="filled"*/}
                                {/*        value={adjust}*/}
                                {/*        // helperText={emailHelperText}*/}
                                {/*        // onChange={poNumberValChange}*/}
                                {/*        id="poNumber"*/}
                                {/*    />*/}
                                {/*</Grid>*/}



                            </Grid>
                            <Grid container spacing={1} >
                                <Grid item xs={12} >
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Other Charges"
                                        variant="filled"
                                        value={others}
                                        // helperText={emailHelperText}
                                        onChange={handleChangeOthers}
                                        id="poNumber"
                                    />
                                </Grid>
                                {/*<Grid item xs={6} >*/}
                                {/*    <TextField*/}
                                {/*        className=""*/}
                                {/*        fullWidth*/}
                                {/*        name="poNumber"*/}
                                {/*        // label="Other Charges"*/}
                                {/*        variant="filled"*/}
                                {/*        value={otherval}*/}
                                {/*        // helperText={emailHelperText}*/}
                                {/*        // onChange={poNumberValChange}*/}
                                {/*        id="poNumber"*/}
                                {/*    />*/}
                                {/*</Grid>*/}



                            </Grid>

                        </Grid>
                    </Grid>





                    <Grid item xs={3}>


                        <Grid item xs={12}>

                            <TextField
                                className="mt-1"
                                fullWidth
                                name="poNumber"
                                label="Subtotal"
                                variant="filled"
                                value={sub}
                                disabled={true}
                                // helperText={emailHelperText}
                                onChange={handleChangeSubtotal}
                                id="poNumber"
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                className=""
                                fullWidth
                                name="poNumber"
                                label="Total"
                                variant="filled"
                                value={tott}
                                disabled={true}
                                // helperText={emailHelperText}
                                // onChange={poNumberValChange}
                                id="poNumber"
                            />
                        </Grid>
                    </Grid>

















                </Grid>
                <Grid>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkDesc}
                                onChange={handleChangeCheckDesc}
                                name="checkedB"

                            />
                        }
                        label="Description"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkItem}
                                onChange={handleChangeCheckItem}
                                name="checkedB"

                            />
                        }
                        label="Mlfb"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkQty}
                                onChange={handleChangeCheckQty}
                                name="checkedB"

                            />
                        }
                        label="Qty"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkUnitLP}
                                onChange={handleChangeCheckUnit}
                                name="checkedB"

                            />
                        }
                        label="Unit LP"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkTaxRate}
                                onChange={handleChangeCheckTaxRate}
                                name="checkedB"

                            />
                        }
                        label="TaxRate"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkDiscount}
                                onChange={handleChangeCheckDiscount}
                                name="checkedB"

                            />
                        }
                        label="Discount(%)"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkDiscA}
                                onChange={handleChangeCheckDiscA}
                                name="checkedB"

                            />
                        }
                        label="Discount(in Amount)"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkTotalDisc}
                                onChange={handleChangeCheckTotalDisc}
                                name="checkedB"

                            />
                        }
                        label="Total Discount"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkDiscQty}
                                onChange={handleChangeCheckDiscQty}
                                name="checkedB"

                            />
                        }
                        label="Discount(per qty)"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkUnitPrice}
                                onChange={handleChangeCheckUnitPrice}
                                name="checkedB"

                            />
                        }
                        label="UnitPriceKp"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkTotal}
                                onChange={handleChangeCheckTotal}
                                name="checkedB"

                            />
                        }
                        label="Total"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkTotalTax}
                                onChange={handleChangeCheckTotalTax}
                                name="checkedB"

                            />
                        }
                        label="Total(Incl Tax)"
                    />


                </Grid>

                {/*<Grid style={{ marginTop:'10px' }}>*/}
                {/*    <Grid className="add-item">*/}
                {/*        <Button*/}
                {/*            type="submit"*/}
                {/*            className="pt-button--primary "*/}
                {/*            id="SupplierLoginBtn"*/}
                {/*            onClick={handleTNC}*/}
                {/*        >*/}
                {/*            Add Terms and Conditions*/}

                {/*        </Button>*/}
                {/*        <Button*/}
                {/*            type="submit"*/}
                {/*            className="pt-button--primary "*/}
                {/*            id="SupplierLoginBtn"*/}
                {/*            onClick={handleNOTES}*/}
                {/*        >*/}
                {/*            Add Notes*/}

                {/*        </Button>*/}


                {/*    </Grid>*/}


                {/*</Grid>*/}

                <Grid item xs={12} className="warningTextCont" style={{ display: 'flex', justifyContent: 'center' }}>
                    <span>
                        Note: If you are using Add Terms and Conditions/Add Notes button to do changes then that change will appear in all upcoming offers and it will be considered as master data.<br />

                        If you want to do any change to this particular offer , kindly use the given dialog boxes to Add, delete or update your terms and condition/notes.
                    </span>
                </Grid>

                <Grid style={{ display: 'flex', width: '100%', marginTop: '10px' }}>

                    <Grid style={{ width: '50%' }}>
                        <Grid>

                            <Button
                                type="submit"
                                className="pt-button--primary "
                                disabled={props?.editData?.isRevised}
                                id="SupplierLoginBtn"
                                onClick={handleTNC}
                            >
                                Add Terms and Conditions

                            </Button>
                        </Grid>

                        < Grid container className="view-blockterms">
                            <Grid item xs={12} sm={3} md={6}>
                                <h3>Terms and conditions</h3>
                            </Grid>
                            <Grid item xs={12} sm={9} md={7}>
                                <TextField
                                    name="Remarks"
                                    multiline
                                    onChange={tncEdit}
                                    variant="outlined"
                                    // value={props?.editData?.termsNconditions ? ddEdit : dd}
                                    //value={dd }
                                    value={termsnConditionStringData}
                                    fullWidth
                                    id="Remarks"

                                >


                                </TextField>


                            </Grid>
                            {/*<Grid xs={12} sm={3} md={6} style={{ display: 'flex', justifyContent:'center' }}>*/}
                            {/*    <Button onClick={handleEditOffer1} className="pt-button--primary">*/}
                            {/*        Update Terms and conditions*/}
                            {/*    </Button>*/}

                            {/*</Grid>*/}
                        </Grid>

                    </Grid>

                    < Grid style={{ width: '50%' }} >
                        <Grid>
                            <Button
                                type="submit"
                                className="pt-button--primary "
                                id="SupplierLoginBtn"
                                disabled={props?.editData?.isRevised}
                                onClick={handleNOTES}
                            >
                                Add Notes

                            </Button>

                        </Grid>

                        < Grid container className="view-blockterms">
                            <Grid item xs={12} sm={3} md={6}>
                                <h3>Notes</h3>
                            </Grid>
                            <Grid item xs={12} sm={9} md={7}>
                                <TextField
                                    name="Remarks"
                                    multiline
                                    onChange={notesEdit}
                                    variant="outlined"
                                    //value={props?.editData?.notes ? ddNOtesEdit : ddnotes}
                                    //value={ ddnotes}
                                    value={notesStringData}
                                    fullWidth
                                    id="Remarks"
                                />
                            </Grid>
                        </Grid>
                        {/*<Grid xs={12} sm={3} md={6} style={{ display: 'flex', justifyContent: 'center' }}>*/}
                        {/*    <Button onClick={handleEditOffer1} className="pt-button--primary">*/}
                        {/*        Update Notes */}
                        {/*    </Button>*/}

                        {/*</Grid>*/}


                    </Grid>

                </Grid>


                {/*const [acceptedRemarks, setAcceptedRemarks] = useState();*/}
                {/*const [rejectedRemarks, setRejectedRemarks] = useState();*/}
                {/*const [commercialAcceptedRemarks, setCommercialAcceptedRemarks] = useState();*/}
                {/*const [commercialRejectedRemarks, setCommercialRejectedRemarks] = useState();*/}



                {/*  {props.rowData?.acknowledgement ?*/}
                {acceptedRemarks !== null ?
                    < Grid container className="view-block">
                        <Grid item xs={12} sm={3} md={6}>
                            <h3>Acceptance Remarks by Technical approver</h3>
                        </Grid>
                        <Grid item xs={12} sm={9} md={7}>
                            <TextField
                                name="Remarks"
                                multiline
                                disabled
                                variant="outlined"
                                maxRows={3}
                                // value={props.rowData?.name ? null : props.rowData?.acknowledgement}
                                value={acceptedRemarks}
                                fullWidth
                                id="Remarks"
                            />
                        </Grid>
                    </Grid> : <></>}
                {rejectedRemarks !== null ? <Grid container className="view-block">
                    <Grid item xs={12} sm={3} md={6}>
                        <h3>Rejected Remarks by Technical approver</h3>
                    </Grid>
                    <Grid item xs={12} sm={9} md={7}>
                        <TextField
                            name="Remarks"
                            multiline
                            disabled
                            variant="outlined"
                            maxRows={3}
                            value={rejectedRemarks}
                            fullWidth
                            id="Remarks"
                        />
                    </Grid>
                </Grid> : <></>}
                {commercialAcceptedRemarks !== null ?
                    <Grid container className="view-block">
                        <Grid item xs={12} sm={3} md={6}>
                            <h3>Acceptance Remarks by Commercial approver</h3>
                        </Grid>
                        <Grid item xs={12} sm={9} md={7}>
                            <TextField
                                name="Remarks"
                                multiline
                                disabled
                                variant="outlined"
                                maxRows={3}
                                value={commercialAcceptedRemarks}
                                fullWidth
                                id="Remarks"
                            />
                        </Grid>
                    </Grid> : <></>}

                {commercialRejectedRemarks !== null ?
                    <Grid container className="view-block">
                        <Grid item xs={12} sm={3} md={6}>
                            <h3>Rejected Remarks by Commercial approver</h3>
                        </Grid>
                        <Grid item xs={12} sm={9} md={7}>
                            <TextField
                                name="Remarks"
                                multiline
                                disabled
                                variant="outlined"
                                maxRows={3}
                                //value={ }
                                value={commercialRejectedRemarks}
                                fullWidth
                                id="Remarks"
                            />
                        </Grid>
                    </Grid> : <></>}






                <Grid align="center" className="margonTop">
                    {(props?.editData?.commercialAcceptedRemarks === null && props?.editData?.acceptedRemarks === null) ?
                        (props?.editData?.rejectedRemarks !== null || props?.editData?.commercialRejectedRemarks !== null) ?
                            // rejectedRemarks !== null && commercialRejectedRemarks !== null ? 
                            <Button
                                type="submit"
                                className="pt-button--primary "
                                id="SupplierLoginBtn"
                                onClick={
                                    handleAccept
                                }
                            >
                                Accept
                            </Button> : (
                                <></>
                            )
                        : <></>
                    }
                    {(((approvalStatus != 1 && approvalStatusL2 != 1 && approvalStatusL3 != 1) || (approvalPending != userDetails.userId && approvalPendingL2 != userDetails.userId && approvalPendingL3 != userDetails.userId && techApproval != "Technical Approval Completed")) || ((comApprovalStatus != 1) && (comApproval != userDetails.userId && commercialApproval != "Commercial Approval Completed"))) || (techApproval === "Technical Approval Completed" && commercialApproval === "Commercial Approval Completed") ?
                        <Button
                            type="submit"
                            className="pt-button--primary "
                            id="SupplierLoginBtn"
                            //onClick={handleSAVE}
                            onClick={
                                dialogSubmit === "Save" ? handleSaveOffer : handleEditOffer
                            }
                            disabled={isRevise}
                        >
                            {dialogSubmit}

                        </Button> : (
                            <></>
                        )}
                    {((flag === 1 && ((approvalStatus != 1 && approvalStatusL2 != 1 && approvalStatusL3 != 1) || (approvalPending != userDetails.userId && approvalPendingL2 != userDetails.userId && approvalPendingL3 != userDetails.userId && techApproval != "Technical Approval Completed"))) || (flag === 1 && ((comApprovalStatus != 1) && (comApproval != userDetails.userId && commercialApproval != "Commercial Approval Completed")))) || (flag === 1 && techApproval === "Technical Approval Completed" && commercialApproval === "Commercial Approval Completed") ?
                        <Button
                            type="submit"
                            className="pt-button--primary "
                            id="SupplierLoginBtn"
                            onClick={handleRevise}
                            disabled={isRevise}
                        >
                            Revise
                        </Button>
                        : (
                            <></>
                        )}
                    {(((approvalStatus != 1 && approvalStatusL2 != 1 && approvalStatusL3 != 1) || (approvalPending != userDetails.userId && approvalPendingL2 != userDetails.userId && approvalPendingL3 != userDetails.userId && techApproval != "Technical Approval Completed")) || ((comApprovalStatus != 1) && (comApproval != userDetails.userId && commercialApproval != "Commercial Approval Completed"))) || (techApproval === "Technical Approval Completed" && commercialApproval === "Commercial Approval Completed") ?
                        <Button
                            type=""

                            className="pt-button--primary"
                            id="SupplierLoginBtn"
                            onClick={download}
                            //onClick={(e) => handlePreview(e, "ACB Spares", window.open("/PreviewPage", "_blank"))}

                            disabled={isRevise || !prevFlag}
                        // localStorage.setItem("routePath", path);
                        >

                            Preview
                        </Button> : (
                            <></>
                        )}



                    {
                        (
                            (
                                (approvalPending === userDetails.userId && approvalStatus === 1) ||
                                (approvalPendingL2 === userDetails.userId && approvalStatusL2 === 1) ||
                                (approvalPendingL2 === userDetails.userId && approvalStatusL2 === 3) ||
                                (approvalPendingL3 === userDetails.userId && approvalStatusL3 === 1)

                            ) ||
                            (
                                comApproval === userDetails.userId &&
                                comApprovalStatus === 1 &&
                                techApproval === "Technical Approval Completed"
                            ) ||
                            (
                                comApproval === userDetails.userId &&
                                comApprovalStatus === 3 &&
                                techApproval === "Technical Approval Completed"
                            )
                        ) ? (
                            <Button
                                type="submit"
                                className="pt-button--primary"
                                id="SupplierLoginBtn"
                                onClick={handleAccept}
                                disabled={isRevise}
                            >
                                Accept
                            </Button>
                        ) : <></>
                    }

                    {(approvalPending === userDetails.userId || approvalPendingL2 === userDetails.userId || approvalPendingL3 === userDetails.userId) && (approvalStatus === 1 || approvalStatusL2 === 1 || approvalStatusL3 === 1) ||
                        (comApproval === userDetails.userId) && (comApprovalStatus === 1 && techApproval === "Technical Approval Completed") ?
                        <Button
                            type="submit"
                            className="pt-button--primary "
                            id="SupplierLoginBtn"
                            onClick={handleReject}
                            disabled={isRevise}
                        >
                            Reject

                        </Button> : (
                            <></>
                        )}
                    {!props?.editData ? <Button
                        type="submit"
                        className="pt-button--primary "
                        id="SupplierLoginBtn"
                        onClick={backClick}
                    >
                        Back

                    </Button> : <></>}

                    {props?.editData ? <Button
                        type="submit"
                        className="pt-button--primary "
                        id="SupplierLoginBtn"
                        onClick={backClick}
                    >
                        Close

                    </Button> : <></>}


                </Grid>

                <Dialog
                    onClose={(event, reason) => {
                        if (reason !== "backdropClick") {
                            handleCloseForm();
                        }
                    }}
                    aria-labelledby="litigation-form--registration"
                    open={openDialog}
                    maxWidth={"md"}
                    disableEscapeKeyDown
                >
                    <DialogTitle id="dialogTitleTxt">{custDialogTitle}</DialogTitle>
                    <div style={{ position: "absolute", right: "1%" }}>
                        <Tooltip title="Close">
                            <IconButton onClick={handleCloseForm}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">

                            <Grid container spacing={2}>
                                {offerId === 10 ?
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            className=""
                                            fullWidth
                                            name="Ptitle"
                                            label="Search Description"
                                            variant="filled"
                                            value={descText}
                                            // helperText={emailHelperText}
                                            onChange={handleChangeSearchDescEDEP}
                                            id="ptitle"
                                        // helperText={qtyHelper}
                                        />
                                    </Grid> :
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Autocomplete PopperComponent={flag === 1 ? PopperEdit : fileMultipleAttachments.length > 0 ? PopperNewAdd : PopperAdd}
                                            className="lightInputFields hover-lightblue"
                                            id="combo-box-demo"
                                            fullWidth
                                            disableClearable
                                            value={descObj}
                                            //value={code }
                                            inputValue={descText}
                                            options={Desc}
                                            getOptionLabel={(option) => option.description ? option.description : ""}
                                            onChange={handleChangeDesc}
                                            onInputChange={handleChangeDescInput}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Search Description"
                                                    variant="filled"
                                                    // required
                                                    error={errorCustomer}
                                                    helperText={errorTextCustomer}
                                                />
                                            )}
                                        />
                                    </Grid>
                                }
                                {offerId === 10 ?
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            className=""
                                            fullWidth
                                            name="Ptitle"
                                            label="Item"
                                            variant="filled"
                                            value={itemText}
                                            // helperText={emailHelperText}
                                            onChange={handleChangeItemTextEDEP}
                                            id="ptitle"
                                        // helperText={qtyHelper}
                                        />
                                    </Grid> :
                                    <Grid item xs={12} sm={6} md={4} fullWidth className="d-flex Width-100 mt-1">
                                        <Autocomplete PopperComponent={flag === 1 ? PopperEdit : fileMultipleAttachments.length > 0 ? PopperNewAdd : PopperAdd}
                                            className="lightInputFields hover-lightblue"
                                            id="combo-box-demo"
                                            fullWidth
                                            disableClearable
                                            value={itemObj}
                                            //value={code }
                                            inputValue={itemText}
                                            options={items}
                                            getOptionLabel={(option) => (option.mlfb ? option.mlfb : "")}
                                            onChange={handleChangeItem}
                                            onInputChange={handleChangeItemInput}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Item"
                                                    variant="filled"
                                                    // required
                                                    error={errorCustomer}
                                                    helperText={errorTextCustomer}
                                                />
                                            )}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Description"
                                        variant="filled"
                                        value={descEdit}
                                        // helperText={emailHelperText}
                                        onChange={handleChangeDescEdit}
                                        id="qty"
                                    // helperText={qtyHelper}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label={offerId === 10 ? "Per Manday" : "Qty"}
                                        variant="filled"
                                        value={qty}
                                        // helperText={emailHelperText}
                                        onChange={handleChange}
                                        id="qty"
                                        helperText={qtyHelper}
                                    />
                                </Grid>
                                {offerId === 10 ?
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            className=""
                                            fullWidth
                                            name="poNumber"
                                            label="Price/PerManday"
                                            variant="filled"
                                            value={rate}
                                            onChange={handleChange}
                                            id="rate"
                                            helperText={rateHelper}
                                        />
                                    </Grid> : <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            className=""
                                            fullWidth
                                            name="poNumber"
                                            label="Unitlp"
                                            variant="filled"
                                            value={rate}
                                            // helperText={emailHelperText}
                                            //onChange={handleChangeRate}
                                            id=""
                                        />
                                    </Grid>}
                                {/*<Grid item xs={12} sm={6} md={4}>*/}
                                {/*    <TextField*/}
                                {/*        className=""*/}
                                {/*        fullWidth*/}
                                {/*        name="poNumber"*/}
                                {/*        label="Account"*/}
                                {/*        variant="filled"*/}
                                {/*        value={account}*/}
                                {/*        // helperText={emailHelperText}*/}
                                {/*        onChange={handleChangeAccount}*/}
                                {/*        id="poNumber"*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                {/*    <Grid item xs={12} sm={6} md={4}>*/}
                                {/*        <TextField*/}
                                {/*            className=""*/}
                                {/*            fullWidth*/}
                                {/*            name="poNumber"*/}
                                {/*            label="Tax Rate"*/}
                                {/*            variant="filled"*/}
                                {/*            value={taxRate}*/}
                                {/*            // helperText={emailHelperText}*/}
                                {/*             onChange={handleChangeTax}*/}
                                {/*            id="poNumber"*/}
                                {/*        />*/}
                                {/*</Grid>*/}
                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Tax Rate
                                        </InputLabel>

                                        <Select

                                            label="service"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={tax}
                                            onChange={handleChangeTax}
                                            input={<BootstrapInput />}
                                        >
                                            {taxId.map((item) => (
                                                <MenuItem key={item.taxRateId} value={item.taxRateId}>
                                                    {item.taxRate}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className="work"
                                        fullWidth
                                        name="poNumber"
                                        label="Discount(%)"
                                        variant="filled"
                                        value={discTotal}
                                        disabled={discTotalItemAmt}
                                        // helperText={emailHelperText}
                                        onChange={handleChange}
                                        id="disc"
                                        helperText={discHelper}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Discount(Per Qty)"
                                        variant="filled"
                                        value={discItemTot}
                                        disabled={discTotalItemAmt}
                                        // helperText={emailHelperText}
                                        // onChange={handleChangeDiscountItemTotal}
                                        id="poNumber"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} style={{ marginTop: '-10px' }}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Discount(in Amount)"
                                        variant="filled"
                                        value={discTotalItemAmt}
                                        disabled={discTotal}
                                        // helperText={emailHelperText}
                                        onChange={handleChangeDiscountTotalItemAmt}
                                        id="poNumber"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Total Discount"
                                        variant="filled"
                                        value={totdisc}
                                        // disabled={discTotal}
                                        // helperText={emailHelperText}
                                        // onChange={handleChangeDiscountTotalItemAmt}
                                        id="poNumber"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Unit Price KP"
                                        variant="filled"
                                        value={unitkp}
                                        //disabled={discTotal}
                                        // helperText={emailHelperText}
                                        //onChange={handleChangeDiscountTotalItemAmt}
                                        id="poNumber"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Net Value"
                                        variant="filled"
                                        value={ItemTotal}
                                        // helperText={emailHelperText}
                                        // onChange={handleChangeTot}
                                        id="poNumber"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="Net Value(Incl.Tax)"
                                        variant="filled"
                                        value={ItemTotalTax}
                                        // helperText={emailHelperText}
                                        // onChange={handleChangeTot}
                                        id="poNumber"
                                    />
                                </Grid>

                            </Grid>



                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            className="pt-button--secondary"
                            id="addUserSave"
                            onClick={
                                dialogSubmitBtn === "Save" ? handleSaveForm : handleUpdateForm
                            }
                        >
                            {dialogSubmitBtn}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="dialogTitleTxt">Delete Line Item</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure to Delete this Line Item?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={Del}
                            className="pt-button--primary"
                        >
                            Ok
                        </Button>
                        <Button
                            onClick={handleCloseDeleteDialog}
                            className="pt-button--secondary"
                            autoFocus
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                {/*<Dialog*/}
                {/*    onClose={(event, reason) => {*/}
                {/*        if (reason !== "backdropClick") {*/}
                {/*            handleCloseRemarks();*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    aria-labelledby="litigation-form--registration"*/}
                {/*    open={openRemarks}*/}
                {/*    fullwidth*/}
                {/*    // maxWidth={"md"}*/}
                {/*    disableEscapeKeyDown*/}
                {/*>*/}
                {/*    <DialogTitle id="dialogTitleTxt">Remarks1</DialogTitle>*/}
                {/*    <div style={{ position: "absolute", right: "1%" }}>*/}
                {/*        <Tooltip title="Close">*/}
                {/*            <IconButton onClick={handleCloseRemarks}>*/}
                {/*                <CloseIcon />*/}
                {/*            </IconButton>*/}
                {/*        </Tooltip>*/}
                {/*    </div>*/}
                {/*    <DialogContent>*/}
                {/*        <DialogContentText id="alert-dialog-slide-description">*/}

                {/*            <Grid container spacing={2}>*/}


                {/*                <Grid item xs={12} sm={6} md={4}>*/}
                {/*                    <TextareaAutosize aria-label="minimum height"*/}
                {/*                        minRows={3}*/}
                {/*                        placeholder="Minimum 3 rows"*/}
                {/*                        value={textauto}*/}
                {/*                        onChange={handleChangeTextAuto}*/}
                {/*                        style={{ width: "100%" }}*/}
                {/*                    />;*/}

                {/*                </Grid>*/}

                {/*            </Grid>*/}



                {/*        </DialogContentText>*/}
                {/*    </DialogContent>*/}
                {/*    <DialogActions>*/}
                {/*        <Button*/}
                {/*            type="submit"*/}
                {/*            className="pt-button--secondary"*/}
                {/*            id="addUserSave"*/}
                {/*            onClick={handleAcceptRemarks}*/}
                {/*        >*/}
                {/*            Update*/}
                {/*        </Button>*/}
                {/*    </DialogActions>*/}
                {/*</Dialog>*/}

                <Dialog fullWidth open={openRemarks} onClose={handleCloseRemarks} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Enter accept remark<span style={{ "color": "red" }}>**</span></DialogTitle>

                    <DialogContent>

                        <TextField
                            className="mt-1"
                            fullWidth
                            id="standard-multiline-flexible"
                            //placeholder=" Enter Remarks"
                            onChange={handleChangeTextAuto}
                            variant="outlined"
                            inputProps={{
                                style: {
                                    height: "70px",
                                },
                            }}
                            maxRows={5}
                            value={textauto}


                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseRemarks} className="pt-button--secondary">
                            Close
                        </Button>

                        <Button onClick={handleAcceptRemarks} disabled={textauto ? false : true} className="pt-button--primary">
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>


                {/*<Dialog fullWidth open={openRemarks} */}
                {/*    onClose={(event, reason) => {*/}
                {/*        if (reason !== "backdropClick") {*/}
                {/*            handleCloseRemarks();*/}
                {/*        }*/}
                {/*    }}*/}
                {/*      aria-labelledby="form-dialog-title">*/}
                {/*    <DialogTitle id="form-dialog-title">Remark</DialogTitle>*/}
                {/*      <h3 className="margin-d8">Enter accept remark</h3>*/}
                {/*    <DialogContent>*/}


                {/*           < TextField*/}
                {/*            className="mt-1"*/}
                {/*            id="standard-multiline-flexible"                          */}
                {/*            minRows={3}*/}
                {/*            placeholder="Minimum 3 rows"*/}
                {/*            value={textauto}*/}
                {/*            onChange={handleChangeTextAuto}*/}
                {/*            fullwidth*/}
                {/*            variant="outlined"*/}
                {/*            inputProps={{*/}
                {/*                style: {*/}
                {/*                    height: "70px",*/}
                {/*                    width:"100%",*/}
                {/*                },*/}
                {/*            }}*/}
                {/*           // maxRows={5}*/}
                {/*        />;*/}
                {/*    </DialogContent>*/}
                {/*    <DialogActions>*/}
                {/*        <Button onClick={handleCloseTNC} className="pt-button--secondary">*/}
                {/*            Close*/}
                {/*        </Button>*/}
                {/*        <Button onClick={handleAcceptRemarks} className="pt-button--primary">*/}
                {/*            UPDATE*/}

                {/*        </Button>*/}
                {/*    </DialogActions>*/}
                {/*</Dialog>*/}

                {/*earlier*/}
                {/*<Dialog*/}
                {/*    onClose={(event, reason) => {*/}
                {/*        if (reason !== "backdropClick") {*/}
                {/*            handleCloseRejectRemarks();*/}
                {/*        }*/}
                {/*    }}*/}
                {/*    aria-labelledby="litigation-form--registration"*/}
                {/*    open={openRejectRemarks}*/}
                {/*    // maxWidth={"md"}*/}
                {/*    fullWidth*/}
                {/*    disableEscapeKeyDown*/}
                {/*>*/}
                {/*    <DialogTitle id="dialogTitleTxt">Remarks</DialogTitle>*/}
                {/*    <div style={{ position: "absolute", right: "1%" }}>*/}
                {/*        <Tooltip title="Close">*/}
                {/*            <IconButton onClick={handleCloseRejectRemarks}>*/}
                {/*                <CloseIcon />*/}
                {/*            </IconButton>*/}
                {/*        </Tooltip>*/}
                {/*    </div>*/}
                {/*    <DialogContent>*/}

                {/*        <DialogContentText id="alert-dialog-slide-description">*/}

                {/*            <Grid container>*/}


                {/*                <Grid >*/}
                {/*                    <TextareaAutosize aria-label="minimum height"*/}
                {/*                        minRows={3}*/}
                {/*                        placeholder="Minimum 3 rows"*/}
                {/*                        value={textautoReject}*/}
                {/*                        onChange={handleChangeTextAutoReject}*/}
                {/*                        style={{width:'100%'}}*/}
                {/*                    />;*/}



                {/*                </Grid>*/}

                {/*            </Grid>*/}



                {/*        </DialogContentText>*/}
                {/*    </DialogContent>*/}
                {/*    <DialogActions>*/}
                {/*        <Button*/}
                {/*            type="submit"*/}
                {/*            className="pt-button--secondary"*/}
                {/*            id="addUserSave"*/}
                {/*            onClick={handleRejectRemarks}*/}
                {/*        >*/}
                {/*            Update*/}
                {/*        </Button>*/}
                {/*    </DialogActions>*/}
                {/*</Dialog>*/}

                <Dialog fullWidth open={openRejectRemarks} onClose={handleCloseRejectRemarks} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Enter reject remark<span style={{ "color": "red" }}>**</span></DialogTitle>

                    <DialogContent>

                        <TextField
                            className="mt-1"
                            fullWidth
                            id="standard-multiline-flexible"
                            //placeholder=" Enter Remarks"
                            onChange={handleChangeTextAutoReject}
                            variant="outlined"
                            inputProps={{
                                style: {
                                    height: "70px",
                                },
                            }}
                            maxRows={5}
                            value={textautoReject}


                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseRejectRemarks} className="pt-button--secondary">
                            Close
                        </Button>

                        <Button onClick={handleRejectRemarks} disabled={textautoReject ? false : true} className="pt-button--primary">
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>




                {/*<Dialog fullWidth open={openRejectRemarks} */}
                {/*    onClose={(event, reason) => {*/}
                {/*        if (reason !== "backdropClick") {*/}
                {/*            handleCloseRejectRemarks();*/}
                {/*        }*/}
                {/*    }}*/}
                {/*      aria-labelledby="form-dialog-title">*/}
                {/*    <DialogTitle id="form-dialog-title">Remark</DialogTitle>*/}
                {/*    */}{/*  <h3 className="margin-d8">Enter Terms and Conditions</h3>*/}
                {/*    <DialogContent>*/}

                {/*        <TextareaAutosize aria-label="minimum height"*/}
                {/*            minRows={3}*/}
                {/*            placeholder="Minimum 3 rows"*/}
                {/*            value={textautoReject}*/}
                {/*            onChange={handleChangeTextAutoReject}*/}
                {/*            fullwidth*/}
                {/*        />;*/}
                {/*    </DialogContent>*/}
                {/*    <DialogActions>*/}
                {/*        */}{/*<Button onClick={handleCloseTNC} className="pt-button--secondary">*/}
                {/*        */}{/*    Close*/}
                {/*        */}{/*</Button>*/}
                {/*        <Button onClick={handleRejectRemarks} className="pt-button--primary">*/}
                {/*            UPDATE*/}

                {/*        </Button>*/}
                {/*    </DialogActions>*/}
                {/*</Dialog>*/}


                <Dialog fullWidth open={termsConditions} onClose={handleCloseTNC} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Enter Terms and Conditions</DialogTitle>

                    <DialogContent>

                        <TextField
                            className="mt-1"
                            fullWidth
                            id="standard-multiline-flexible"
                            //placeholder=" Enter Remarks"
                            onChange={handleTNCcomment}
                            variant="outlined"
                            inputProps={{
                                style: {
                                    height: "70px",
                                },
                            }}
                            maxRows={5}
                            value={tncComment}


                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseTNC} className="pt-button--secondary">
                            Close
                        </Button>
                        <Button onClick={termNconditions} className="pt-button--primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>



                <Dialog fullWidth open={notes} onClose={handleCloseNotes} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Enter Notes</DialogTitle>

                    <DialogContent>

                        <TextField
                            className="mt-1"
                            fullWidth
                            id="standard-multiline-flexible"
                            //placeholder=" Enter Remarks"
                            onChange={handleNotesComments}
                            variant="outlined"
                            inputProps={{
                                style: {
                                    height: "70px",
                                },
                            }}
                            maxRows={5}
                            value={notesComment}


                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseNotes} className="pt-button--secondary">
                            Close
                        </Button>
                        <Button onClick={notesPostSubmit} className="pt-button--primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>



                <div>
                    <Dialog
                        open={dialogOpen}
                        onClose={handleCloseData}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {" Data Upload Alert"}
                        </DialogTitle>
                        <DialogContent>
                            {dialogContent && typeof dialogContent === "object" ? (
                                <DialogContentText id="alert-dialog-description">
                                    <h5>
                                        {dialogContent
                                            ?.filter((x, index) => index === 0)
                                            ?.map((c) => (
                                                <>&emsp;&emsp;{c.join(" ")}</>
                                            ))}
                                    </h5>
                                    <ol className="ol-bulkUpload-Error">
                                        {dialogContent
                                            ?.filter((x, index) => index !== 0)
                                            ?.map((c) => (
                                                <li>
                                                    {c.map((x) =>
                                                        x.includes("--") && x.includes(":") ? (
                                                            x
                                                        ) : (
                                                            <ul className="ul-bulkUpload-Error">
                                                                <li>{x}</li>
                                                            </ul>
                                                        )
                                                    )}
                                                </li>
                                            ))}
                                    </ol>
                                </DialogContentText>
                            ) : (
                                <DialogContentText
                                    id="alert-dialog-description "
                                    className="dialog-center"
                                >
                                    {dialogContent?.includes("successfully.") ? (
                                        <>
                                            {dialogContent?.split("successfully.")[0] +
                                                " successfully."}
                                            {dialogContent?.split("successfully.")?.length > 0 ? (
                                                <>
                                                    <br />
                                                    {dialogContent?.split("successfully.")[1]}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    ) : (
                                        dialogContent
                                    )}
                                </DialogContentText>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleCloseData}
                                className="pt-button--secondary"
                                title="Close"
                            >
                                Close
                            </Button>
                            {errorData && errorData.length > 0 ? (
                                <Button
                                    onClick={exportErrorData}
                                    className="pt-button--secondary"
                                    title={
                                        "Export   upload error data to excel"
                                    }
                                >
                                    Export Error Data
                                </Button>
                            ) : (
                                <></>
                            )}
                        </DialogActions>
                    </Dialog>
                </div>



                {isLoader ? <Loader loaderText="Loading...!" /> : <></>}
                {isLoading ? <Loader loaderText="Loading...!" /> : <></>}


            </div >

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
        </>
    );
} 