import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    TextField,
    Button,
    Grid,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";
import MaterialTable from "material-table";
import Loader from "../../Components/Loader/Loader";
import { blue } from "@material-ui/core/colors";
import EPQuotation_Template from "../../Common/EPQuotation Template.xlsx";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../Themes/LightTheme";
import { useSelector, useDispatch } from "react-redux";
import "./Create.scss";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { QuotationDataUpload, GetAll } from "../../APIs/api_Quotation";
import * as XLSX from "xlsx";

export default function CreateQuotation() {
    //const classes = useStyles();
    const [fileValue, setfileValue] = useState("");
    let [errorData, setErrorData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const token = useSelector((state) => state.saveTokenReducer.token);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);

    const UID = useSelector((state) => state.saveUserIdReducer);
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const [data, setData] = useState([]);
    const api = axios.create({
        baseURL: "api/Quotation",
    });

    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    useEffect(() => {
        GridData();
    }, []);

    const [uploadRes, setUploadRes] = useState({});
    const [isLoading, setIsLoading] = useState(false);



    //for snackBar
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
   

    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    //const handleClose = (e) => {
    //    setOpen(false);
    //};

    const updateExcelHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState("");
    const [isLoader, setIsLoader] = useState(false);
    const handleDialogMessage = (text) => {
        setDialogContent(text);
        setDialogOpen(true);
    };
    const handleClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        if (
            typeof dialogContent === "string" &&
            dialogContent.toLowerCase().includes("success")
        ) {
            setInputFileValue(Date.now);
            setSelectedFile(null);
        }
        setDialogOpen(false);
        setInputFileValue(Date.now);
        setErrorData([]);
    };
    const exportErrorData = () => {
      
      ExportCustomerErrorDataToExcel();
                
       
    };
    function ExportCustomerErrorDataToExcel() {
        const dataToDownload = errorData.map((row) => {
            return {

                quotationId: row.quotationId,
                cutomerPo: row.customerPo,
                ourRef: row.ourRef,

                idNo: row.idNo,

                remarks: row.remarks?.replace(".", ".\n "),

            };
        });
        let headers = ["sl_no", "cutomer_po", "our_ref", "id_no",
            "Remarks",

        ];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
            origin: "A2",
            skipHeader: true,
            cellStyles: true,
        });
        ws["!cols"] = cellAdjustToContentsCustomer(dataToDownload);
        XLSX.utils.book_append_sheet(wb, ws, "ErrorData");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(wb, "ExcelUploadErrors.xlsx");
    }
    function cellAdjustToContentsCustomer(data) {
        return [
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.quotationId ? c.quotationId?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.customerPo ? c.customerPo?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.idNo ? c.idNo?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.remarks ? c.remarks?.toString()?.length : 0))
                ),
            },
        ];
    }


    const columns = [
        { title: "sl_no", field: "quotationId" },
        {
            title: "customer_po", field: "cutomerPo"
        },
        { title: "our_ref", field: "ourRef" },
        { title: "date", field: "date" },
        { title: "service", field: "service" },
        { title: "customer", field: "customer" },
        { title: "location", field: "location" },
        { title: "contact_person", field: "contactPerson" },
        { title: "mobile", field: "mobile" },
        { title: "e_mail", field: "eMail" },
        { title: "panel", field: "panel" },
        { title: "feeder_name", field: "feederName" },
        { title: "model", field: "model" },
        { title: "mlfb", field: "mlfb" },
        { title: "z_options", field: "zOptions" },
        { title: "id_no", field: "idNo" },
        { title: "breaker_rating", field: "breakerRating" },
        { title: "size", field: "size" },
        { title: "pole", field: "pole" },
        { title: "breaker", field: "breaker" },
        { title: "etu", field: "etu" },
        { title: "etu_serial_no", field: "etuSerialNo" },
        { title: "last_serviced_on", field: "lastServicedOn" },
        { title: "in", field: "in" },
        { title: "l_tripping_ir", field: "lTrippingIr" },
        { title: "long_time_current", field: "longTimeCurrent" },
        { title: "l_time_lag_tr", field: "lTimeLagTr" },
        { title: "memory", field: "memory" },
        { title: "short_time_isd", field: "shortTimeIsd" },
        { title: "short_time_current_isd", field: "shortTimeCurrentIsd" },
        { title: "short_time_delay_tsd", field: "shortTimeDelayTsd" },
        { title: "i_tripping_ii", field: "iTrippingIi" },
        { title: "i_tripping_current_ii", field: "iTrippingCurrentIi" },
        { title: "n_tripping_in", field: "nTrippingIn" },
        { title: "i_n", field: "in" },
        { title: "g_ct", field: "gct" },
        { title: "g_tripping_ig", field: "gTrippingIg" },
        { title: "g_alarm_ig", field: "gAlarmIg" },
        { title: "time_delay_tg_trip", field: "timeDelayTgTrip" },
        { title: "etu_status", field: "etuStatus" },
        { title: "ct_test", field: "ctTest" },
        { title: "mechanical_reclosing_lockout", field: "mechanicalReclosingLockout" },
        { title: "trip_unit", field: "tripUnit" },
        { title: "mechanical_interlock", field: "mechanicalInterlock" },
        { title: "racking_handle", field: "rackingHandle" },
        { title: "racking_mechanism", field: "rackingMechanism" },
        { title: "contact_erosion_indicator", field: "contactErosionIndicator" },
        { title: "arc_chutes", field: "arcChutes" },
        { title: "shutter", field: "shutter" },
        { title: "lamination_contacts", field: "laminationContacts" },
        { title: "guide_frame_terminals", field: "guideFrameTerminals" },
        { title: "contact_pressure", field: "contactPressure" },
        { title: "pole_set", field: "poleSet" },
        { title: "lubrication", field: "lubrication" },
        { title: "auxiliary_contact_block", field: "auxiliaryContactBlock" },
        { title: "spring_charging_manual", field: "springChargingManual" },
        { title: "spring_charging_motor", field: "springChargingMotor" },
        { title: "undervoltage", field: "undervoltage" },
        { title: "closing_coil", field: "closingCoil" },
        { title: "shunt_coil", field: "shuntCoil" },
        { title: "ready_to_close_interlock", field: "readyToCloseInterlock" },
        { title: "breaker_operations_manual", field: "breakerOperationsManual" },
        { title: "breaker_operations_electrical", field: "breakerOperationsElectrical" },
        { title: "mandatory_spares", field: "mandatorySpares" },
        { title: "recommended_spares", field: "recommendedSpares" },
        { title: "comments", field: "comments" },
        { title: "open_points", field: "openPoints" },
        { title: "overall_breaker_healthiness", field: "overallBreakerHealthiness" },
        { title: "tested_by", field: "testedBy" },
        {
            title: "contact_no_1", field: "contactNo1"
        },
        { title: "reviews_by", field: "reviewedBy" },
        { title: "contact_no_2", field: "contactNo2" },




    ];

    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const handleAlertClose = () => {
        setSelectedFile(null);
        setOpenAlertDialog(false);
        setIsLoading(false);
    };
    const [alertMessage, setAlertMessage] = useState("");
    const handleAlertOpen = (message) => {
        setOpenAlertDialog(true);
        setAlertMessage(message);
    };
    const handleErrorClose = () => {
        setOpenErrorDialog(false);
    };

    const sample1 = [
        { macNo: 1256, place: "Chennai" },
        { macNo: 12567, place: "Delhi" },
    ];
    function submitValueDetails() {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("UserId", userDetails.userId);
        //UploadData(formData);
        QuotationDataUpload(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                console.log("hi");
                console.log(response);
                if (response.response) {
                    setIsLoader(false);
                    GridData();
                    handleDialogMessage(response.responseMsg);
                } else {
                    setIsLoader(false);
                    setSelectedFile(null);
                    setInputFileValue(Date.now);
                    setErrorData(response.objResult);
                    handleDialogMessage(
                        response.responseMsgs && response.responseMsgs.length > 0
                            ? response.responseMsgs
                            : response.responseMsg
                    );
                }
            })
            .catch((error) => {
                setIsLoader(false);
                setSelectedFile(null);
                setInputFileValue(Date.now);
                handleDialogMessage("Exception in upload");
            });
    }
    function GridData() {
        GetAll(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("hi");
                console.log(response);
                setData(response);
            })
            .catch((error) => {
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
            });
    }
    return (
        <>
            <div>
                <Grid container spacing={2}>
                    <Grid
                        container
                        spacing={2}
                        item
                        xs={12}
                        className="d-flex jc-space-bt"
                    >
                        <Grid item xs={12} className="text-left ml-1">
                            <h2>Upload Quotation Data</h2>
                        </Grid>
                        <Grid container spacing={2} xs={12}>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    id="EPQUploadFile"
                                    variant="filled"
                                    className="bulk-upload"
                                    type="file"
                                    name="bulkUpload"
                                    key={inputFileValue}
                                    onChange={updateExcelHandler}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button
                                    className="pt-button--primary bulk-upload-submit-btn"
                                    type="submit"
                                    disabled={selectedFile ? false : true}
                                    onClick={submitValueDetails}
                                >
                                    Upload
                                </Button>
                            </Grid>

                            <Grid container xs={12}>
                                <Grid item xs={12} md={3}>
                                    <a
                                        href={EPQuotation_Template}
                                        download="EPQuotation_Template"
                                        target="_blank"
                                    >
                                        <Button className="pt-button--primary bulk-upload-submit-btn">
                                            Download Template
                                        </Button>
                                    </a>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                xs={12}
                            //   className="d-flex jc-space-bt"
                            >
                                <Grid item xs={12} md={2}>
                                    <a
                                        href={""}
                                        onClick={(event) => (window.location.href = "")}
                                        target="_blank"
                                    >
                                        <Button className="pt-button--primary bulk-upload-submit-btn">
                                            View Quotation Data
                                        </Button>
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/*<Grid item xs={12}>*/}
                    {/*    <h4>*/}
                    {/*        Inserted/Updated successfully :{" "}*/}
                    {/*        {uploadRes.successCnt ? uploadRes.successCnt : "0"}{" "}*/}
                    {/*    </h4>*/}
                    {/*    <h4>*/}
                    {/*        Failed Records : {uploadRes.failedCnt ? uploadRes.failedCnt : "0"}*/}
                    {/*    </h4>*/}
                    {/*</Grid>*/}

                    <div className="marginTop">
                        <Grid item xs={12}>
                            <MaterialTable
                                title="Uploaded Data"
                                columns={columns}
                                data={data}
                                icons={tableIconsInputScreen}
                                //isLoading={isLoader}
                                options={{ headerStyle: { background: '#ADD8E6' }, tableOptionsUser, exportButton: { csv: true } }}
                            />
                        </Grid>
                    </div>
                </Grid>
                <div>
                    <Dialog
                        open={dialogOpen}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {" Data Upload Alert"}
                        </DialogTitle>
                        <DialogContent>
                            {dialogContent && typeof dialogContent === "object" ? (
                                <DialogContentText id="alert-dialog-description">
                                    <h5>
                                        {dialogContent
                                            ?.filter((x, index) => index === 0)
                                            ?.map((c) => (
                                                <>&emsp;&emsp;{c.join(" ")}</>
                                            ))}
                                    </h5>
                                    <ol className="ol-bulkUpload-Error">
                                        {dialogContent
                                            ?.filter((x, index) => index !== 0)
                                            ?.map((c) => (
                                                <li>
                                                    {c.map((x) =>
                                                        x.includes("--") && x.includes(":") ? (
                                                            x
                                                        ) : (
                                                            <ul className="ul-bulkUpload-Error">
                                                                <li>{x}</li>
                                                            </ul>
                                                        )
                                                    )}
                                                </li>
                                            ))}
                                    </ol>
                                </DialogContentText>
                            ) : (
                                <DialogContentText
                                    id="alert-dialog-description "
                                    className="dialog-center"
                                >
                                    {dialogContent?.includes("successfully.") ? (
                                        <>
                                            {dialogContent?.split("successfully.")[0] +
                                                " successfully."}
                                            {dialogContent?.split("successfully.")?.length > 0 ? (
                                                <>
                                                    <br />
                                                    {dialogContent?.split("successfully.")[1]}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    ) : (
                                        dialogContent
                                    )}
                                </DialogContentText>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                className="pt-button--secondary"
                                title="Close"
                            >
                                Close
                            </Button>
                            {errorData && errorData.length > 0 ? (
                                <Button
                                    onClick={exportErrorData}
                                    className="pt-button--secondary"
                                    title={
                                        "Export   upload error data to excel"
                                    }
                                >
                                    Export Error Data
                                </Button>
                            ) : (
                                <></>
                            )}
                        </DialogActions>
                    </Dialog>
                </div>
                {isLoading ? <Loader loaderText="Loading...!" /> : <></>}

                {/*<Dialog*/}
                {/*    onClose={handleAlertClose}*/}
                {/*    disableBackdropClick*/}
                {/*    aria-labelledby="epq-form--error"*/}
                {/*    open={openAlertDialog}*/}
                {/*    maxWidth={"lg"}*/}
                {/*>*/}
                {/*    <DialogTitle id="epq-form--error" onClose={handleErrorClose}>*/}
                {/*        Alert:-*/}
                {/*    </DialogTitle>*/}
                {/*    <DialogContent>*/}

                {/*    </DialogContent>*/}
                {/*    <DialogActions>*/}
                {/*        <Button*/}
                {/*            type="submit"*/}
                {/*            fullWidth*/}
                {/*            className="pt-button--secondary"*/}
                {/*            id="submitbtn"*/}
                {/*            onClick={handleAlertClose}*/}
                {/*        >*/}
                {/*            Close*/}
                {/*        </Button>*/}

                {/*        <Button*/}
                {/*            type="submit"*/}
                {/*            fullWidth*/}
                {/*            className="pt-button--primary"*/}
                {/*            id="submitbtn"*/}
                {/*        // onClick={submitValueDetails}*/}
                {/*        >*/}
                {/*            Submit*/}
                {/*        </Button>*/}

                {/*    </DialogActions>*/}
                {/*</Dialog>*/}
            </div>

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
        </>
    );
}
